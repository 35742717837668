import React from "react";

function Product35() {
  return (
    <div>
      <img src="/Untitled10.jpg" /> <img src="/Untitled11.jpg" />
    </div>
  );
}

export default Product35;
