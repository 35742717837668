import React from "react";

function Product34() {
  return (
    <div>
      <img src="/Untitled20.png" />{" "}
    </div>
  );
}

export default Product34;
