import React from "react";
import {
  tableData10_6,
  tableData10_7,
  tableData10_8,
  tableData10_9,
} from "./tableDatas";

function Product19() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/53.jpg" className="max-w-[200px]" />
      <span className="font-semibold mt-3">1. Хамрах хүрээ</span>
      <span>
        {" "}
        DTSD27 электрон 3-фазын 4-утастай тоолуур нь 50Hz давтамж бүхий, 230
        вольтийн AC актив энергийг хэмжихэд зориулагдсан. Мөн энэ загвар нь
        өргөн сонголт бүхий вольтийн хүрээнд ажиллаж, 70% Un вольт болон 3 фазын
        2 нь тасарсан үед ч найдвартай ажиллана. Дэлгэцийн LED гэрэл нь -40С ~
        +65C температурт хэвийн ажиллана.
      </span>

      <span className="font-semibold mt-3">2. Стандарт</span>
      <span> Уг тоолуур нь доорхи стандартуудад нийцсэн</span>
      <ul>
        <li>
          {" "}
          IEC62052-11 Цахилгааны хэмжих хэрэгсэл (AC) - Ерөнхий шаардлага, тест
          болон тестийн нөхцөл-11-р хэсэг: Хэмжих хэрэгсэл
        </li>
        <li>
          {" "}
          IEC62053-21 Цахилгааны хэмжих хэрэгсэл (AC) - Тодорхой шаардлага-21-р
          хэсэг: Актив энергийн статик тоолуур (нарийвчлал 1 болон 2)
        </li>
        <li>
          {" "}
          IEC62053-21 Цахилгааны хэмжих хэрэгсэл (AC) - Тодорхой шаардлага-23-р
          хэсэг: Реактив энергийн статик тоолуур (нарийвчлал 2 болон 3)
        </li>
      </ul>
      <span className="font-semibold mt-3">3. Товч үзүүлэлт</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col2}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col3}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col4}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col5}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_6[0].col6}{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col1}{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col2}{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col3}{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col4}{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col5}{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                {tableData10_6[1].col6}{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold">
        4. Eрөнхий техникийн үзүүлэлтүүд
      </span>

      <ol>
        <li className="flex flex-col">
          <span>4.1 Үндсэн алдаа</span>
          <div className="overflow-x-auto mt-3">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ачаалах гүйдэл{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Цахилгааны өгөгдөл{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Бодит алдаа{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData10_7.map((row, index) => (
                  <tr key={index}>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col1}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col2}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col3}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </li>
        <li className="flex flex-col">
          <span> 4.2.Цахилгааны хэрэглээ</span>
          <span> Вольтын хэлхээ тус бүр ≤ 1.0W 5VA</span>
          <span> Гүйдлийн хэлхээ тус бүр ≤ 1.0 VA</span>
        </li>
        <li> 4.3 Хэмжээ: 254мм * 204мм * 83мм</li>
        <li> 4.4 Жин: ≤ 2 кг</li>
        <li>
          {" "}
          4.5 Эхлэх гүйдэл: Актив энерги: 0.004Ib Реактив энерги: 0.005Ib
        </li>
        <li>
          {" "}
          4.6.Шилжүүлэгчийн гүйлт: Гүйдлийн хэлхээ нь 120% вольт тасрах
          тохиолдолд, чадалын гаргалгааны пулс нь 1-ээс бага байна.
        </li>
        <li className="flex flex-col">
          <span> 4.7. Амбиэнт температур: -40С ~70C</span>
          <span> Харьцангуй чийгшил: ≤97%</span>
        </li>
        <li className="flex flex-col">
          <span> 4.8.Вольтийн хүрээ</span>
          <span> Ажиллах хүрээ: 230V +20%-30%</span>
          <span> Ажиллах хүрээ: 230V +20%-30%</span>
        </li>
      </ol>
      <span className="mt-3 font-semibold">5. Харагдах байдал</span>
      <img src="/54.jpg" className="max-w-[610px]" />
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_8[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_8[0].col2}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_8[0].col3}{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData10_8.slice(1, tableData10_8.length).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold"> 6. Дэлгэцийн функцууд</span>
      <span> Харагдах энерги</span>
      <span> Дэлгэцийн хамгийн их утга ------99999,99kWh, 99999,99kvarh</span>
      <span> Дэлгэцийн хамгийн бага утга ------0.01kWh, 0.01kvarh</span>
      <span>
        {" "}
        LED дэлгэц нь ажиллагааны температурыг харуулах өргөн сонголттой. Дэлгэц
        нь уншихад хялбар юм. Дэлгэцийн параметрийн тайлбарыг харна уу.
      </span>
      <img src="/55.jpg" className="max-w-[447px]" />
      <span>1 ---- Мэдээллийн тэмдэглэгч (data mark) </span>
      <span>2 --- Мэдээлэл үзүүлэх хэсэг (data display zone)</span>
      <span>
        {" "}
        Холбогдсон үед, LED дэлгэц бүх үзүүлэлтүүдийг харуулна. Хэрэглэгч нь LED
        ажиллаж байгаа эсэхийг хараад мэдэх боломжтой.
      </span>
      <img src="/56.jpg" className="max-w-[383px]" />
      <span>
        {" "}
        Ингээд, метер хэвийнээр ажиллаж эхлэнэ. Дэлгэц нь утгуудыг 2 янзаар
        үзүүлнэ: автомат (автоматаар утгуудыг ээлжлэн харуулах) эсвэл гар арга
        (товчлуур дээр дарж утгуудыг харах). Автомат горимын үед дэлгэц 4
        төрлийн параметер үзүүлнэ. Харин товчлуур дээр дарж утгуудыг харах гар
        арга нь бүх утгуудыг харах боломжтой. Автомат арга сонгогдсон үед
        параметер бүр 10 секунд харагдаад солигдоно. Гар аргаар солих үед тухайн
        параметрийг 20 секунд харуулна.
      </span>
      <img src="/57.jpg" className="max-w-[680px]" />
      <span className="mt-3 font-semibold">
        {" "}
        7. Суурилуулалт болон хэрэглээ
      </span>
      <ol>
        <li className="flex flex-col">
          <span> 7.1.Суурилуулах хэмжээс</span>
          <img src="/58.jpg" className="max-w-[573px]" />
        </li>
        <li className="flex flex-col">
          <span> 7.2.Холболтын диаграм</span>
          <img src="/59.jpg" className="max-w-[477px]" />
        </li>
        <li className="flex flex-col">
          <span> 7.3 Импулсийн тест болон RS485 холбооны судал</span>
          <span>
            {" "}
            Тоолуур нь фото-электрик холболтын тест гаргалгаатай нүүрний
            хэсэгтэй. Энэ нь терминалын хаалтын доод хэсэгт байрласан. Дээд 2
            оролт нь RS485 холбооны судал, доод 2 оролт нь актив импулс
            гаргалгаатай.
          </span>
        </li>
        <li className="flex flex-col">
          <span> 7.4 Суурилуулах үеийн санамж</span>
          <ol>
            <li>
              {" "}
              7.4.1 Холбохоос өмнө фазын болон газардуулгын шугам нь холболтын
              терминалын хаалтан дээр байрлах холболтын диаграмын дагуу эсэхийг
              шалгах. Зэс утас болон зэс холбогч ашиглахыг санал болгож байна.
            </li>
            <li>
              {" "}
              7.4.2 Хэрэв кабель нь исэлдсэн байвал кабелийн төгсгөлийг тасалж,
              ойролцоогоор 13мм кабелийг ил гарган хөнгөн цагаан холболтын
              нүхэнд суурилуулна. Кабелийн төгсгөлийг хөнгөн цагаан холбоосоор
              чангалж боох. Холбоосыг кабелтай сайн бэхэлж холбосноор холболтын
              терминалд муу холболтоос үүсэх гэмтлээс урьдчилан сэргийлж болно.
            </li>
            <li>
              {" "}
              7.4.3 Холболт хийхдээ эхлээд утасны холбогчийн 2 шурагийг сулалж,
              дараа нь А-Фазын оролтын утсыг №1 нүхний холболтын төгсгөлд
              холбох, В-Фазын оролтын утсыг №3 нүхний холболтын төгсгөлд холбох,
              С-Фазын оролтын утсыг №5 нүхний холболтын төгсгөлд холбох,
              газардуулгын оролтын утсыг №7 нүхний холболтын төгсгөлд холбож тус
              тусын 2 утасны холболтын шурагийг чангална. Мөн энэ үед А-Фазын
              гаралтын утсыг №2 нүхний холболтын төгсгөлд холбох, В-Фазын
              гаралтын утсыг №4 нүхний холболтын төгсгөлд холбох, С-Фазын
              гаралтын утсыг №6 нүхний холболтын төгсгөлд холбох, газардуулгын
              гаралтын утсыг №8 нүхний холболтын төгсгөлд холбож тус тусын 2
              утасны холболтын шурагийг чангална. Холболт нь сайн хийгдсэн
              эсэхийг шалгах.
            </li>
            <li>
              {" "}
              7.4.4 Хэрэв холболт хийж дууссан бол терминалын хаалтыг хааж
              цахилгаан хариуцсан байгууллагын лацыг байршуулах.
            </li>
            <li>
              {" "}
              7.4.5 Цахилгааны холболтыг хийснээр тоолуур хэвийн ажиллаж байгаа
              эсэхийг шалгах. Хэрэв хэвийн ажиллаж байвал тоолуурын заалтыг
              тэмдэглэж авах. Хэвийн ажиллахгүй байвал суурилуулалт, холболт
              болон ажиллагааны вольт зэрэг нь хэвийн эсэхийг шалгах.
            </li>
            <li>
              {" "}
              7.4.6 Суурилуултын үед тоолуурыг унагах болон цохилтоос сэргийлж
              болгоомжтой ашиглах.
            </li>
            <li>
              {" "}
              7.4.7 Тоолуурыг суурилуулах үеийн элдэв ослоос сэргийлж маш
              болгоомжтой ажиллах.
            </li>
          </ol>
        </li>
      </ol>
      <span className="mt-3 font-semibold">Хүргэлт болон хадгалалт</span>
      <ol>
        <li>
          {" "}
          8.1 Тоолуурыг тээвэрлэх болон задлах явцад цохилт болон доргилтоос
          сэргийлэх ба хүргэх, хадгалах горимыг заагдсан журмын дагуу хийнэ.
        </li>
        <li>
          {" "}
          8.2 Тоолуурыг хадгалахдаа зориулалтын хайрцагт -45C ~ +65C
          температурт, харьцангуй чийгшилийн дээд хэмжээ нь 95%-аас ихгүй
          нөхцөлд хадгална. Метерийг зэврүүлдэг орчинд хадгалж болохгүй.
        </li>
        <li>
          {" "}
          8.3 Тоолуурыг тавиур дээр хадгалах ба 8-аас дээш хайрцагийг давхарлаж
          тавихыг хориглоно.
        </li>
      </ol>
      <span className="mt-3 font-semibold">Баталгаа</span>
      <span>
        {" "}
        Бид үйлдвэрийн лац хөдлөөгүй болон үйл ажиллагааны бүх зааварыг мөрдлөг
        болгосон техникийн үзүүлэлтэд хүрэхгүй эсвэл зөрчигдсөн бүх тоолуурыг
        борлуулалтын гэрээнд дурьдагдсан тээвэр хийгдсэн өдрөөс эхлэн баталгаат
        хугаацаа дуусах хүртэл хугацаанд солих буюу засварлаж өгнө.
      </span>
      <span className="mt-3 font-semibold">
        10. DTSD27 загвар нь өөрийгөө теслэх (self-test) функцтай, урт хугацаанд
        ажиллах болон ашиглалтыг тооцон үйлдвэрлэсэн.
      </span>
      <span>
        Хэрэв буруу ашиглалт болон байршлаас болж ямар нэгэн асуудал гарвал
        Aсуудал шийдвэрлэх хүснэгт-д дурьдсан шийдлүүдээс харна уу.
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_9[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_9[0].col2}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData10_9.slice(1, tableData10_9.length).map((row, index) => (
              <tr key={index}>
                <td className=" px-3 py-1 text-left whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-left whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product19;
