import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";

class Construction extends Component {
  render() {
    let description =
        "Бид “0.3.2.5 0.4-220кВ-ын цахилгаан дамжуулах шугам, дэд станцын засвар, угсралт, түүний туршилт тохируулга, бусад дагалдах ажил үйлчилгээ”-ний ажил гүйцэтгэх тусгай зөвшөөрлийн хүрээнд бүтээн байгуулалтын олон ажлуудыг цаг хугацаанд нь чанартай хийж гүйцэтгэсээр ирсэн.",
      description2 =
        "Манай компани Тавантолгой, Оюу толгойн ЦДАШ, 220/110/35кв-ын  дэд станцын барилга угсралтын ажил болон Намдайн хөндийн ЦДАШ гэх мэт олон ажлуудад оролцож ирсэн арвин туршлагатай хамт олон юм. ";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Барилга Угсралт" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"БАРИЛГА УГСРАЛТ"} />
        {/* End Breadcrump Area */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center text-sm">
                <div className="col-lg-4 col-md-12">
                  <div className="thumbnail">
                    <img
                      className="max-w-[400px]"
                      src="/construction.jpg"
                      alt="Construction Images"
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <p className="">{description}</p>
                      <p className="">{description2}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 w-full flex flex-row gap-5 sm:gap-0 justify-between">
                  <img
                    className="w-1/2"
                    src="/about2.jpg"
                    alt="Construction Images"
                  />
                  <img
                    className="w-1/2"
                    src="/construction2.jpg"
                    alt="Construction Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Construction;
