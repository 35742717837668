"use server";
import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";
import ServiceList from "../elements/service/ServiceList";
import { slickDot } from "../page-demo/script";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TabsOne from "../elements/tab/TabOne";

const list = [
  {
    image: "/ajil.jpg",
    title: "Монтаж “ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil2.jpg",
    title: "Оюу толгойн ажил “ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil3.jpg",
    title: "“ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil4.png",
    title: "Дэлгүүрийн орчин “ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil5.png",
    title: "Дэлгүүр “ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil6.jpg",
    title: "Найрсаг хамт олон “ИХ-ЭРЧ” ХХК",
  },
  {
    image: "/ajil7.png",
    title: "Бүх төлийн кабель “ИХ-ЭРЧ”",
  },
  {
    image: "/ajil8.jpg",
    title: "Туршлагатай хамт олон “ИХ-ЭРЧ” ХХК",
  },
];
const partners = [
  "/partner1.jpg",
  "/partner2.jpg",
  "/partner3.jpg",
  "/partner4.jpg",
  "/partner5.jpg",
  "/partner6.jpg",
  "/partner7.jpg",
  "/partner8.jpg",
  "/partner9.jpg",
  "/partner10.jpg",
  "/partner11.jpg",
  "/partner12.jpg",
  "/partner13.jpg",
  "/partner14.jpg",
];
const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--1",
    category: "",
    title: "Их Эрч ХХК",
    description:
      "“ИХ-ЭРЧ” ХХК нь 1998 онд байгуулагдсан бөгөөд цахилгааны кабель утаснуудыг импортоор оруулж дотоодын зах зээлийн хэрэгцээг ханган ажиллаж байна.",
    buttonText: "Үргэлжлүүлэх",
    buttonLink: "/",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 5,
      autoplay: true,
      draggable: false,
      swipe: false,
      swipeToSlide: false,
      touchMove: false,
      speed: 2500,
      autoplaySpeed: 0,
      cssEase: "linear",
      arrows: false,
      dots: false,
      // centerPadding: "0px",
      // centerMode: true,
    };
    return (
      <Fragment>
        <Helmet pageTitle="Их Эрч | Таны итгэлт түнш" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="2"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title ">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="btn-default"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        {/* <div
          className="about-area rm-about-style-2 ptb--120 bg_color--5"
          id="about"
        >
          <div className="about-wrapper">
            <AboutOne />
          </div>
        </div> */}
        {/* End About Area */}

        {/* Start About Area */}
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-6">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/about-3.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <span className="subtitle">МАНАЙ БАЙГУУЛЛАГА</span>
                      <h2 className="title">“ИХ-ЭРЧ” ХХК</h2>
                    </div>
                    <div className="row mt--30">
                      <TabsOne tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Counterup Area */}
        <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="3"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">ҮЙЛЧИЛГЭЭ</span>
                  <h2 className="title">ХИЙЖ БУЙ АЖЛУУД</h2>
                </div>
              </div>
            </div>
            <div className="row creative-service mt--30">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--25 mb_sm--0">
                  <h2 className="title">ХАМТРАГЧИД</h2>
                </div>
              </div>
            </div>
            <div className="row pt--20">
              <div className="col-lg-12">
                <Slider {...settings} className="">
                  {partners.map((partner, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-center"
                    >
                      <img
                        src={partner}
                        alt={`Logo ${index + 1}`}
                        className="h-5 lg:h-20 mx-auto"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area pt--120 pb--140 bg_color--5">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <span className="subtitle">МАНАЙ ОРЧИН</span>
                    <h2 className="title">АЖЛЫН ОРЧИН</h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider {...slickDot}>
                      {list.map((value, index) => (
                        <div className="single_im_portfolio" key={index}>
                          <div className="im_portfolio">
                            <div className="thumbnail_inner">
                              <div className="thumbnail">
                                <Link>
                                  <img
                                    src={value.image}
                                    className="w-[390px] h-[290px]"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <div className="inner">
                                <div className="portfolio_heading">
                                  <h4 className="title">
                                    <Link>{value.title}</Link>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Footer Style  */}
        <Footer />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
