// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import CorporateBusiness from "./home/CorporateBusiness";

import Contact from "./elements/Contact";
import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Product from "./elements/product/[id]";
import Construction from "./elements/Construction";
import Manifactor from "./elements/Manifactor";
import Distributor from "./elements/Distributor";
import ContactTwo from "./elements/contact/ContactTwo";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={CorporateBusiness}
            />

            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/construction`}
              component={Construction}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/manifactor`}
              component={Manifactor}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/distributor`}
              component={Distributor}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/product/:id`}
              component={Product}
            />

            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
