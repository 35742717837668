import React from "react";

function Product36() {
  return (
    <div className="flex flex-col">
      <img src="/Untitled17.jpg" />
      <img src="/Untitled18.jpg" />
      <img src="/Untitled19.jpg" />
    </div>
  );
}

export default Product36;
