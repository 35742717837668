import React from "react";

function Product15() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/Untitled4.jpeg" className="w-[312px]" />
      <span>
        {" "}
        10 кВ-ын СТп маркийн холболтын муфтний техникийн тодорхойлолт
      </span>
      <span>
        {" "}
        СТп-10 кабелийн муфть нь цаасан болон пластмассан тусгаарлагчтай,
        хөнгөнцагаан болон тугалган бүрээстэй ба, ган төмөр хамгаалалттай болон
        хамгаалалтгүй 10 кВ хүртэлх хүчдэлийн кабелийг холбох зориулалттай.
        Газарт, туннельд, сувагчлал зэрэг бүх төрлийн газарт суурилуулах
        боломжтой. Орчны температур -50С-с +50С хүртэлх нөхцөлд ашиглах
        боломжтой бөгөөд, харьцангуй чийгшил 98% хүртэлх нөхцөлд 35С температурт
        ашиглана.
      </span>
    </div>
  );
}

export default Product15;
