import React from "react";

function Product40() {
  return (
    <div>
      <img src="/Untitled5.jpg" />
      <img src="/Untitled6.jpg" />
    </div>
  );
}

export default Product40;
