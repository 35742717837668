import React from "react";

function Product29() {
  return (
    <div>
      <img src="/Untitled28.jpg" />
    </div>
  );
}

export default Product29;
