import React from "react";
import { tableData7_1, tableData7_2, tableData7_3 } from "./tableDatas";

function Product8() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/num_j.gif" />
      <span className="mt-3 font-semibold">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>1. СУДАЛ — зэс, дугуй гол, ГОСТ 22483 (I,II зэрэг).</li>
        <li>
          2. ТУСГААРЛАГЧ — поливнилхлоридийн пластикат хольцтой (PVC).
          Тусгаарлагдсан голчууд нь доорхи хүснэгтэнд өгөгдсөний дагуу тус тусын
          цул өнгөтэй.
        </li>
        <li>
          3. БООЛТ, ОРООЛТ — шохойлог резинэн термопластик эластомер, дан судал
          кабель NUM нь боолт ороолтгүй үйлдвэрлэгддэг.
        </li>
        <li>
          4. БҮРХЭВЧ — тодорч харагдахуйц саарал поливнилхлоридийн пластикат
          хольцтой материалаар хийгдсэн{" "}
        </li>
      </ol>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судалын тоо
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Газардуулагч-судалтай, ногоон-шар өнгөтэй, NUM-J төрлийн кабель{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                NUM-O кабель{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData7_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="font-semibold mt-3"> ХЭРЭГЛЭЭ</span>
      <span>
        {" "}
        Цахилгаан хүчийг дамжуулах, түгээх (AC вольт - 0.66 кВ, 1.0 кВ, 50 Hz)
      </span>
      <span>
        {" "}
        Халуун болон чийгтэй газарт суурилуулахдаа тусгай кабелийн суваг, блокыг
        кабелийн гадуур хэрэглэвэл зохистой. Үйлдвэр, орон сууцанд хэрэрлэж
        болно. Электрон хэрэгслүүдэд түгээхдээ оролтон дээр нь хүчдэл багасгагч
        хэрэглэвэл зохистой. Газар доор (суваг шуудуу г.м) суурилуулхад
        тохиромжгүй .
      </span>
      <span> NUM-O болон NUM-J кабель дангаараа шатдаггүй.</span>
      <span className="mt-3 font-semibold"> ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Ажиллах
        температур..........................................................................................
        -300С -аас +500С хүртэл{" "}
      </span>
      <span>
        {" "}
        Агаарын чийглэг тэсвэрлэх чадвар (+35°С-аас ихгүй нөхцөлд)
        ............................... 98% хүртэл{" "}
      </span>
      <span>
        {" "}
        Кабелийг урьдчилан халаахгүйгээр суурилуулалтын
        температур..............................-150С
      </span>
      <span>
        {" "}
        Нугалах хамгийн бага
        радиус................................................................................7.5
        гаднах диаметр
      </span>
      <span>
        {" "}
        Давтамж.............................................................................................................50
        Hz
      </span>
      <span>
        {" "}
        50 Hz (0.66 кВ) давтамжид шалгагдсан
        .................................................................2.5 кВ
      </span>
      <span>
        {" "}
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн температур
        ..............................+70°С
      </span>
      <span>
        {" "}
        Кабелийн урт, төв дамжуулагчийн огтлолын
        хэмжээ.............................................50 м -ээс багагүй{" "}
      </span>
      <span className="mt-3 font-semibold"> NUM төрлийн кабель</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData7_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold">
        {" "}
        NUM-O болон NUM-J төрлийн кабель
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData7_3.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product8;
