import React from "react";
import { tableData12 } from "./tableDatas";

function Product24() {
  return (
    <div className="flex flex-col">
      <img src="/38.jpg" />
      <img src="/39.jpg" />
      <span className="mt-3"> Техникийн үзүүлэлт</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData12[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData12[0].col2}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData12.slice(1, tableData12.length).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <img src="/40.jpg" />
    </div>
  );
}

export default Product24;
