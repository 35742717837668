import React from "react";
import { tableData8_1, tableData8_2 } from "./tableDatas";

function Product9() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/as.gif" />
      <span className="mt-3 font-semibold"> АС (ГОСТ 839-80)</span>
      <span> Тусгаарлагчгүй төмөр голчтой ба хөнгөн цагаан судалтай</span>
      <span className="mt-3 font-semibold">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <span>
        {" "}
        Тусгаарлагчгүй төмөр голчтой ба хөнгөн цагаан судалтай. Гаднах болон
        доторх төмөр бүрхүүл нь баруун-эрчлээтэй.
      </span>
      <span className="mt-3 font-semibold">ОКП КОД:</span>
      <span className=""> 35 1141 01 — АС кабель</span>
      <span className="mt-3 font-semibold"> ХЭРЭГЛЭЭ</span>
      <span className="">
        {" "}
        АС кабель нь агаарт цахилгаан дамжуулах болон сүлжээнд хэрэглэнэ.
        Агаарын атмоспер I ба II төрөл, ГОСТ 15150 -ын дагуу “УХЛ” (“ТВ”
        болон“ТС” -ээс бусад) нөхцөлд газар дээрх хүхэрийн газ өдөрт 150г/м2
        -аас ихгүй.
      </span>
      <span className="mt-3 font-semibold"> ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Ажиллах температур
        .........................................................................................
        +900С -ээс ихгүй{" "}
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Огтлол, мм2
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Хөнгөн цагаан голчтой
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Ган голчтой
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Эрчлээний тоо
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Хөнгөн цагаан болон ган судалын харьцаа
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Кабелийн уртын хэмжэээ, хамгийн багадаа , м
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судалын тоо
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судалын тоо
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Хөнгөн цагаанутас
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ган утас
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData8_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col9}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Огтлол, мм2
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Диаметр, мм{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км, t 20°С-дэх цахилгааны эсэргүүцэл, Ом, ихгүй{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Дамжуулагчийн тэсрэх хүчдэл Н, багагүй{" "}
              </th>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Утасны{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ган судал{" "}
              </th>

              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Хөнгөн цагаан хэсэг{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ган голч{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Нийт{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData8_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product9;
