import React from "react";

function Product32() {
  return (
    <div className="flex flex-col">
      <img src="/Untitled23.jpg" />
      <img src="/Untitled24.jpg" />
      <img src="/Untitled25.jpg" />
    </div>
  );
}

export default Product32;
