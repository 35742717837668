import React from "react";
import { tableData4_1, tableData4_2 } from "./tableDatas";

function Product5() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/product-image6.gif" className="mt-3" />
      <span className="mt-3">
        АПВ кабель нь поливнилхлоридийн пластикат тусгаарлагчтай, дан ширхэгтэй
        хөнгөн цагаан судалнуудаас бүрдэх, электрон тоног төхөөрөмжийн кабель,
        ГОСТ 6323-79
      </span>
      <img src="/product-image5.gif" className="" />
      <span className="mt-3">
        ПВ1 кабель нь поливнилхлоридийн пластикат тусгаарлагчтай, дан ширхэгтэй
        зэс судалнуудаас бүрдэх, электрон тоног төхөөрөмжийн кабель, ГОСТ
        6323-79
      </span>
      <span className="font-semibold mt-3">
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>
          <span className="font-semibold">1. СУДАЛ</span> — АПВ судалнууд:
          хөнгөн цагаан, дан эсвэл олон ширхэгтэй судалтай, ГОСТ 22483 ( I
          зэрэглэл - 2.5 - 16 мм2 хөндлөн огтлол, II зэрэглэл 25-120 мм2). —
          ПВ-1 судалнууд: зэс, дан эсвэл олон ширхэгтэй судалтай, ГОСТ 22483 ( I
          зэрэглэл - 0.5 - 10 мм2 хөндлөн огтлол, II зэрэглэл 16-95 мм2)
        </li>
        <li>
          <span className="font-semibold">2. ТУСГААРЛАГЧ</span> —
          поливнилхлоридийн пластикат хольцтой, олон өнгийн. Газардуулга-судалын
          бүрхүүл ногоон-шар өнгөтэй.
        </li>
      </ol>
      <span className="font-semibold mt-3">ХЭРЭГЛЭЭ</span>
      <span>
        {" "}
        450 В хүртэлх вольт-той (450/750 В хүртэлх сүлжээ), 400 Hz давтамжтай
        эсвэл 1 кВ хүртэлх DC вольттой электрон тоног төхөөрөмж, гэрэлтүүлэг
        болон цахилгааны сүлжээнд, электрон багаж хэрэгсэл суурилуулалт, машин
        механизм. АПВ, ПВ-1 кабелууд нь төмөр хоолой, байрны цахилгааны суваг,
        кабелийн трэй зэрэгт суурилуулдаг.
      </span>
      <span className="font-semibold mt-3">ОКП КОД:</span>
      <span>35 5133 01 — АПВ кабель</span>
      <span>35 5113 01 — ПВ-1 кабель</span>
      <span className="font-semibold mt-3">ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Тэсвэрлэх цаг агаарын
        нөхцөл...........................................................................
        “ХЛ”, ГОСТ 15150-69 (категор -2){" "}
      </span>
      <span>
        {" "}
        Ажиллах температур
        .......................................................................................
        -500С -аас +700Сы
      </span>
      <span>
        {" "}
        Агаарын чийглэг тэсвэрлэх чадвар (+350°С -д)
        .................................................. 100%
      </span>
      <span>
        {" "}
        Халуун орны зориулалттай кабель нь хөгц, мөөгөнцөрт тэсвэртэй
      </span>
      <span>
        {" "}
        Механик шок, шугаман хурдасгал, нугалах, доргилт, чичиргээ болон дуу
        авианы шуугианд тэсвэртэй.
      </span>
      <span> Гал түгээдгүй</span>
      <span>
        {" "}
        Кабелийг суурилуулалтын температур
        ...............................................................-150С
        -аас багагүй{" "}
      </span>
      <span>
        {" "}
        Нугалах хамгийн бага радиус
        ..............................................................................10
        гаднах диаметр
      </span>
      <span>
        {" "}
        Зөвшөөрөгдсөн үргэлжилсэн температур (судал дээр)
        .........................................+700С -ээс ихгүй{" "}
      </span>
      <span>
        {" "}
        Кабелийн уртын
        хэмжээ.....................................................................................100
        м -ээс багагүй
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                АПВ
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData4_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ПВ-1
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData4_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col9}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product5;
