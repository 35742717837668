import React from "react";
import { tableData13 } from "./tableDatas";

function Product25() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/Untitled37.jpg" />
      <span> Ачаалал таслагч ВНА-10/ Х1-Х2-Х3з-Х4-ИХ5-У2-КЭАЗ</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData13[0].col1}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData13[0].col2}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData13.slice(1, tableData13.length).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-left whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-left whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product25;
