import React from "react";
import { tableData10_1 } from "./tableDatas";

function Product17() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/42.jpg" className="max-w-[200px]" />
      <span className="mt-3 font-semibold">
        1. Функц, үзүүлэлт, ерөнхий хэрэглээ
      </span>
      <span>
        {" "}
        DDSF36A электрон 1-фазын олон тарифт тоолуур нь 50Hz давтамжтай AC актив
        энергийг хэмжих ба IEC62053-21 стандартын нарийвчлал 1 (class 1) -ийн
        бүхий л үзүүлэлт шаардлагыг хангаж, IEC62056 стандартын мэдээлэл
        дамжуулах протоколын шаардлагыг бүрэн хангасан.{" "}
      </span>
      <span>
        {" "}
        Бүтээгдэхүүний үзүүлэлтүүд: удаан эдэлгээтэй, өндөр нарийвчлалтай, хэт
        ачаалал даах чадвар өндөр, цахилгаан зарцуулалт бага, хэмжээ бага, үйл
        ажиллагааны үед мэдээллийн алдаа бага ба үнэн зөв байдал болон
        найдвартай ажиллагааг бий болгох засварлах функцтай, цахилгаан тасарсан
        үед мэдээллийг автоматаар хадгалах горим бүхий, LED дэлгэц нь автоматаар
        нэмэгдэж буй хэрэглээ болон өөр тарифын хэрэглээ, он сар өдөр, цаг, одоо
        байгаа тарифын хугацаа зэргийг сольж харуулдаг. Уг тоолуур нь 3 өөр
        тарифыг 12 цагийн хэсэгт хуваан ажиллах боломжтой. Бодит цагийн схем нь
        маш өндөр чанарын Lithium баттеритай ба тоолуурын цагийг өндөр
        нарийвчлалтайгаар хадгална, мэдээллийг унших, функцд тохиргоо хийх, цаг
        тохируулах, цагийн хэсэг (time section) бий болгоход ашиглагдах стандарт
        модулжуулах инфраред дамжуулагч нүүр хэсэг бүхий RS485.
      </span>
      <span> Энэ бүтээгдэхүүн нь доорхи үзүүлэлттэй.</span>
      <span> Амбиэнт температур: -40С ~60C Дээд харьцангуй чийгшил: 95%</span>
      <span>
        {" "}
        Вольтийн ажиллах хүрээ: 230V +20%-30% Давтамжийн хүрээ: 50Hz ± 5%
      </span>
      <span className="mt-3 font-semibold">2. Техникийн үзүүлэлтүүд</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Загвар{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Нарийвчлал{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Вольт(V){" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Номинал гүйдэл{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td
                rowSpan={2}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                DDSF36A{" "}
              </td>
              <td
                rowSpan={2}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                1{" "}
              </td>
              <td
                rowSpan={2}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                230V{" "}
              </td>
              <td
                rowSpan={1}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                5(40)A{" "}
              </td>
            </tr>
            <tr className="">
              {" "}
              <td
                rowSpan={1}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                5(60)A{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="font-semibold mt-3">2.2 Техникийн үзүүлэлтүүд</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Давтамж: 50Hz{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Гаднах хэмжээ: 92мм * 112мм * 60,5мм{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                Метрийн тогтмол: 1600 imp/kWh{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                Жин: 0,75кг{" "}
              </td>
            </tr>
            <tr>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                Эхлэх гүйдэл: 0.002%In{" "}
              </td>
              <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                Дэлгэцийн тоо утга: 5 бүхэл тоо, 1 аравтын бутархай орон{" "}
              </td>
            </tr>
            <tr>
              <td
                rowSpan={2}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                Цахилгааны хэрэглээ: <br /> Вольтын хэлхээ ≤ 1.5W 7VA Гүйдлийн
                хэлхээ ≤ 0.1 VA
              </td>
              <td
                rowSpan={1}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                Баттерины ажиллах хугацаа: 10жил{" "}
              </td>
            </tr>
            <tr>
              <td
                rowSpan={1}
                className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
              >
                Ачаалалгүй үеийн гүйлт: Энэ тоолуур нь <br /> ачаалалгүй үеийн
                гүйлтээс сэргийлэх функц <br /> бүхий логикийн хэлхээтэй юм.{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold">3. Ажиллгааны зарчим</span>
      <img src="/43.JPG" />
      <span className="mt-3 font-semibold">
        4. Тоолуурын терминалын холболтын схем
      </span>
      <img src="/44.jpg" className="max-w-[340px]" />
      <span>
        {" "}
        Дээрхи тоолуурын холболтын диарамд, C болон E нь цахилгааны пулсийн
        гаралт (тестийн пулсийн гаралт нь фотоэлектрик холболтын тусгаарлагчаар
        дамжина)
      </span>
      <span>
        {" "}
        Тоолуурын холболтын диарамд, A болон B нь RS485 дамжуулагчийн нүүрний
        хэсэг
      </span>
      <span className="mt-3 font-semibold">5. Гаднах харагдах байдал</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_1[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_1[0].col2}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_1[0].col3}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData10_1.slice(1, tableData10_1.length).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <img src="/45.jpg" className="max-w-[487px]" />
      <span className="mt-3 font-semibold">6. Дэлгэцийн функц</span>
      <ol className="mt-3">
        <li className="flex flex-col">
          <span>6.1 ​Дэлгэцийн бүтэц</span>
          <img src="/46.jpg" className="max-w-[251px]" />
          <span>1 ---- Тэмдгийн байршил (1 орон, улбар шар)</span>
          <span>
            2 --- Мэдээллийн байршил(5 орон, 1 бутархай аравтын орон, улаан)
          </span>
        </li>
        <li> 6.2 Цахилгааны индикатор: Улаан өнгөтэй LED</li>
        <li>
          {" "}
          6.3 Тарифын индикатор: Улаан өнгөтэй LED, T1 -Дээд тариф, T2- мөрний
          тариф, T3 - Дээд тарифгүй үеийн тариф
        </li>
        <li className="flex flex-col">
          <span>
            {" "}
            6.4 Автоматаар мэдээллийг дамтамжтайгаар ээлжлэн харуулах эсвэл
            давтамжийн товчлуурыг дарснаар ажиллана.
          </span>
          <span>
            Тоолуур нь цахилгаанд холбогдсоны дараа LED дэлгэц нь 1 секундын
            дараа автоматаар "мэдээллийг ээлжлэн харуулах горим"-д шилжиж 10
            минут үргэлжилнэ. Үүний дараа тоолуур нь "автоматаар мэдээллийг
            ээлжлэн харуулах горим -- дэлгэцийг зогсоох -- автоматаар мэдээллийг
            ээлжлэн харуулах горим" гэсэн горимж шилжин орно. Дэлгэцэнд гарах
            мэдээллийг тус бүрт нь 10 секунд харуулаад солигдоно (өөрчлөх
            боломжтой). Цаг харуулах горимд шилжин 60 секунд байна (өөрчлөх
            боломжтой). Ээлжлэн харуулах товчийг дарснаар LED дэлгэц идэвхижин
            "мэдээллийг ээлжлэн харуулах горим"-д шилжиж 10 минут үргэлжилнэ.
            Үүний дараа "автоматаар мэдээллийг ээлжлэн харуулах горим --
            дэлгэцийг зогсоох --- автоматаар мэдээллийг ээлжлэн харуулах горим"
            гэсэн горимж шилжин орно. Автоматаар мэдээллийг ээлжлэн харуулах
            горим идэвхижсэн үед тоолуур нь доорхи мэдээллийг үзүүлнэ.
          </span>
          <img src="/47.jpg" />
        </li>
      </ol>
      <span className="mt-3 font-semibold">
        6. Тарифын үнэ, цагийн хэсгийн өгөгдлүүдийг програмчлах дэлгэц (16
        зүйлс)
      </span>
      <ol className="mt-3">
        <li className="flex flex-col">
          <span>
            {" "}
            Автоматаар мэдээллийг ээлжлэн харуулах горимд шилжсэн үед ээлжлэн
            харуулах товчийг 2 секунд дархад тарифын үнэ, цагийн хэсгийн
            өгөгдлүүдийг програмчлах хэсэгт нэвтрэн орно. Үүний дараа тоолуур нь
            мэдээллийг автоматаар харуулах тохиргоог үзүүлнэ. Нэг өгөгдлийг
            тохируулах өгөгдмөл хугацаа нь 10 секунд (өөрчлөх боломжтой). Нэг
            ээлжлэн харуулах горимыг өөрчилсний дараа автоматаар мэдээллийг
            ээлжлэн харуулах горимд буцан орно.
          </span>
          <img src="/48.jpg" />
          <span>
            {" "}
            Cанамж: Уг тоолуур нь 3 тариф суулгаж болох ба 12 цагийн-хэсэг
            суурилуулах боломжтой. Хэрэв 12 цагийн-хэсгээс багийг ашиглаж байгаа
            үед, жишээ нь 6 цагийн-хэсгийг тохируулсан үед тохируулсан 6 цагийн
            хэсэг нь харагдах ба үлдсэн 6 хэсэг нь 00,00,00 гэж харагдана.
          </span>
        </li>
      </ol>
      <span className="mt-3 font-semibold">7. Хүргэлт болон хадгалалт</span>
      <ol>
        <li>
          {" "}
          Тоолуурыг тээвэрлэх болон задлах явцад цохилт болон доргилтоос
          сэргийлэх ба хүргэх, хадгалах горимыг заагдсан журмын дагуу хийнэ.
          Тоолуурыг тавиур дээр хадгалах ба 8-аас дээш хайрцагийг давхарлаж
          тавихыг хориглоно. Тоолуурыг хадгалахдаа зориулалтын хайрцагт, цэвэр
          газарт -40C ~ +70C температурт, харьцангуй чийгшилийн дээд хэмжээ нь
          95%-аас ихгүй нөхцөлд хадгална. Газ болон бусад аюултай материалтай
          хадгалахгүй байх. Метерийг зэврүүлдэг орчинд хадгалж болохгүй.
        </li>
      </ol>
      <span className="mt-3 font-semibold">8. Баталгаа</span>
      <ol>
        <li>
          {" "}
          Бид үйлдвэрийн лац хөдлөөгүй болон үйл ажиллагааны бүх зааварыг
          мөрдлөг болгосон техникийн үзүүлэлтэд хүрэхгүй эсвэл зөрчигдсөн бүх
          тоолуурыг 18 сарын хугацаанд (үйлдвэрээс тээвэрлэгдсэн өдрөөс эхлэн
          тооцно) солих буюу засварлаж өгнө.
        </li>
      </ol>
      <span className="mt-3 font-semibold">9. Анхааруулга</span>
      <ul>
        <li>
          {" "}
          Энэ тоолуур нь үйлдвэрт байхдаа чанарын тестед орж лац наагдан
          суурилуулхад бэлэн болно.
        </li>
        <li>
          {" "}
          Тоолуурыг өрөөнд суурилуулахдаа 1,8-аас дээш өндөрт суурилуулахыг
          зөвлөдөг. Цахилгааны тоолуурыг суурилуулах самбарыг галд тэсвэртэй,
          хатуу гадаргуу дээр байршуулах. Шатамхай хий агаарт байж болохгүй.
        </li>
        <li>
          {" "}
          Тоолуурын холболт нь холболтын терминалын хаалтан дээр байрлах
          холболтын диаграмын дагуу хийгдэх. Зэс утас болон зэс холбогч
          ашигласнаар сайн холболт болно.
        </li>
      </ul>
    </div>
  );
}

export default Product17;
