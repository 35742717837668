import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderFive from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Холбоо Барих" />
        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title mb--30 text-center">
                  <span className="subtitle">ХОЛБОО БАРИХ</span>
                  <h2 className="title">Холбоо барих хаяг</h2>
                </div>
              </div>
            </div>
            <div className="row sm:min-h-[360px]">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address sm:min-h-[330px]">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Утас</h4>
                    <p>
                      <a href="tel:+444 555 666 777">70008383, 77078383</a>
                    </p>
                    <p>
                      <a href="tel:+222 222 222 333">
                        9666-9420, 9666-9400, 9865-9400
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address sm:min-h-[330px]">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">И-мэйл хаяг</h4>
                    <p>
                      <a href="mailto:admin@gmail.com">info@ikherch.mn</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address sm:min-h-[330px]">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Хаяг</h4>
                    <p>
                      СХД-17, Москвагийн гудамж-49, Энхтайвны өргөн чөлөө 1-р
                      хороолол, Москва-49 байр, 2-р давхар Улаанбаатар хот,
                      Монгол улс
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1062.6131247465043!2d106.83225248363868!3d47.91030218029883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96932ccb7699eb%3A0x2d1432090ed368fe!2sEnergy%20Tech%20Progress%20LLC!5e0!3m2!1sen!2sus!4v1675648243634!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{
                borderRadius: "0px",
                position: "relative",
                border: "0",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* End Contact Map  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
