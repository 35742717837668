export const tableData0 = [
  {
    col1: "16/2,7",
    col2: "6",
    col3: "1,85",
    col4: "1",
    col5: "1,85",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "25/4,2",
    col2: "6",
    col3: "2,30",
    col4: "1",
    col5: "2,30",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "35/6,2",
    col2: "6",
    col3: "2,80",
    col4: "1",
    col5: "2,80",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "40/6,7",
    col2: "6",
    col3: "2,91",
    col4: "1",
    col5: "2,91",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "50/8,0",
    col2: "6",
    col3: "3,20",
    col4: "1",
    col5: "3,20",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "63/10,5",
    col2: "6",
    col3: "3,66",
    col4: "1",
    col5: "3,66",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "2000",
  },
  {
    col1: "70/11",
    col2: "6",
    col3: "3,80",
    col4: "1",
    col5: "3,80",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "2000",
  },
  {
    col1: "95/16",
    col2: "6",
    col3: "4,50",
    col4: "1",
    col5: "4,50",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "1500",
  },
  {
    col1: "100/16,7",
    col2: "6",
    col3: "4,61",
    col4: "1",
    col5: "4,61",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "1500",
  },
  {
    col1: "120/19",
    col2: "26",
    col3: "2,40",
    col4: "7",
    col5: "1,85",
    col6: "2",
    col7: "1",
    col8: "6,25",
    col9: "2000",
  },
  {
    col1: "120/27",
    col2: "30",
    col3: "2,20",
    col4: "7",
    col5: "2,20",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "125/6,9",
    col2: "18",
    col3: "2,97",
    col4: "1",
    col5: "2,97",
    col6: "2",
    col7: "-",
    col8: "18,11",
    col9: "2000",
  },
  {
    col1: "125/20,4",
    col2: "26",
    col3: "2,47",
    col4: "7",
    col5: "1,92",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "150/19",
    col2: "24",
    col3: "2,80",
    col4: "7",
    col5: "1,85",
    col6: "2",
    col7: "1",
    col8: "7,85",
    col9: "2000",
  },
  {
    col1: "150/24",
    col2: "26",
    col3: "2,70",
    col4: "7",
    col5: "2,10",
    col6: "2",
    col7: "1",
    col8: "6,14",
    col9: "2000",
  },
  {
    col1: "150/34",
    col2: "30",
    col3: "2,50",
    col4: "7",
    col5: "2,50",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "160/8,9",
    col2: "18",
    col3: "3,36",
    col4: "1",
    col5: "3,36",
    col6: "2",
    col7: "-",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "160/26,1",
    col2: "26",
    col3: "2,80",
    col4: "7",
    col5: "2,18",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "185/24",
    col2: "24",
    col3: "3,15",
    col4: "7",
    col5: "2,10",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "185/29",
    col2: "26",
    col3: "2,98",
    col4: "7",
    col5: "2,30",
    col6: "2",
    col7: "1",
    col8: "6,24",
    col9: "2000",
  },
  {
    col1: "185/43",
    col2: "30",
    col3: "2,80",
    col4: "7",
    col5: "2,80",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "200/11,1",
    col2: "18",
    col3: "3,76",
    col4: "1",
    col5: "3,76",
    col6: "2",
    col7: "-",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "200/32,6",
    col2: "26",
    col3: "3,13",
    col4: "7",
    col5: "2,43",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "205/27,0",
    col2: "24",
    col3: "3,30",
    col4: "7",
    col5: "2,20",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "240/32",
    col2: "24",
    col3: "3,60",
    col4: "7",
    col5: "2,40",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "240/39",
    col2: "26",
    col3: "3,40",
    col4: "7",
    col5: "2,65",
    col6: "2",
    col7: "1",
    col8: "6,11",
    col9: "2000",
  },
  {
    col1: "300/39",
    col2: "24",
    col3: "4,00",
    col4: "7",
    col5: "2,65",
    col6: "2",
    col7: "1",
    col8: "7,81",
    col9: "2000",
  },
  {
    col1: "300/48",
    col2: "26",
    col3: "3,80",
    col4: "7",
    col5: "2,95",
    col6: "3",
    col7: "1",
    col8: "6,16",
    col9: "2000",
  },
  {
    col1: "315/21,8",
    col2: "45",
    col3: "2,99",
    col4: "7",
    col5: "1,99",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "315/51,3",
    col2: "26",
    col3: "3,93",
    col4: "7",
    col5: "3,05",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "330/30,0",
    col2: "48",
    col3: "2,98",
    col4: "7",
    col5: "2,30",
    col6: "3",
    col7: "1",
    col8: "11,55",
    col9: "2000",
  },
  {
    col1: "330/43,0",
    col2: "54",
    col3: "2,80",
    col4: "7",
    col5: "2,80",
    col6: "3",
    col7: "1",
    col8: "7,71",
    col9: "1500",
  },
  {
    col1: "400/18",
    col2: "42",
    col3: "3,40",
    col4: "7",
    col5: "1,85",
    col6: "3",
    col7: "1",
    col8: "20,27",
    col9: "1500",
  },
  {
    col1: "400/27,7",
    col2: "45",
    col3: "3,36",
    col4: "7",
    col5: "2,24",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "400/51",
    col2: "54",
    col3: "3,05",
    col4: "7",
    col5: "3,05",
    col6: "3",
    col7: "1",
    col8: "7,71",
    col9: "1500",
  },
  {
    col1: "400/51,9",
    col2: "54",
    col3: "3,07",
    col4: "7",
    col5: "3,07",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "450/31,1",
    col2: "45",
    col3: "3,57",
    col4: "7",
    col5: "2,38",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "500/26",
    col2: "42",
    col3: "3,90",
    col4: "7",
    col5: "2,20",
    col6: "3",
    col7: "1",
    col8: "18,86",
    col9: "1200",
  },
  {
    col1: "500/34,6",
    col2: "45",
    col3: "3,76",
    col4: "7",
    col5: "2,51",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "560/38,7",
    col2: "45",
    col3: "3,98",
    col4: "7",
    col5: "2,65",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "630/43,6",
    col2: "45",
    col3: "4,22",
    col4: "7",
    col5: "2,81",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "710/49,1",
    col2: "45",
    col3: "4,48",
    col4: "7",
    col5: "2,99",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
];

export const tableData1 = [
  { col1: "2x2.5", col2: "13.6", col3: "13.6", col4: "275", col5: "272" },
  { col1: "2x4", col2: "14.4", col3: "15.2", col4: "302", col5: "328" },
  { col1: "2x6", col2: "15.4", col3: "16.2", col4: "340", col5: "368" },
  { col1: "2x10", col2: "17.4", col3: "17.8", col4: "419", col5: "425" },
  { col1: "2x16", col2: "19.3", col3: "19.7", col4: "495", col5: "510" },
  { col1: "2x16", col2: "20.2", col3: "20.6", col4: "522", col5: "538" },
  { col1: "2X25", col2: "22.6", col3: "23", col4: "469", col5: "665" },
  { col1: "2X25", col2: "23.2", col3: "23.6", col4: "688", col5: "685" },
  { col1: "2X35", col2: "25", col3: "25.4", col4: "780", col5: "798" },
  { col1: "2x35", col2: "25.6", col3: "26", col4: "799", col5: "817" },
  { col1: "2X50", col2: "28.4", col3: "28.8", col4: "974", col5: "993" },
  { col1: "2x50", col2: "29", col3: "29.4", col4: "1002", col5: "1021" },
  { col1: "2x70", col2: "", col3: "32.2", col4: "", col5: "1206" },
  { col1: "2X120", col2: "", col3: "39.2", col4: "", col5: "1698" },
  { col1: "2x150", col2: "", col3: "42.4", col4: "", col5: "1984" },
  { col1: "2X185", col2: "", col3: "46.4", col4: "", col5: "2314" },
  { col1: "3x240", col2: "", col3: "51.8", col4: "", col5: "2818" },
  { col1: "3x2.5", col2: "13.6", col3: "14", col4: "280", col5: "294" },
  { col1: "3x4", col2: "15", col3: "15.8", col4: "335", col5: "366" },
  { col1: "3x6", col2: "16.1", col3: "16.9", col4: "382", col5: "414" },
  { col1: "3x10", col2: "18.2", col3: "18.6", col4: "470", col5: "487" },
  { col1: "3x16", col2: "20.2", col3: "20.6", col4: "577", col5: "595" },
  { col1: "3X16", col2: "21.2", col3: "21.6", col4: "608", col5: "627" },
  { col1: "3X25", col2: "23.8", col3: "24.2", col4: "771", col5: "791" },
  { col1: "3X25", col2: "24.4", col3: "25.3", col4: "793", col5: "837" },
  { col1: "3x35", col2: "26.3", col3: "26.8", col4: "937", col5: "959" },
  { col1: "3x35", col2: "27", col3: "27.4", col4: "959", col5: "982" },
  { col1: "3x50", col2: "30", col3: "30.4", col4: "1192", col5: "1216" },
  { col1: "3X50", col2: "30.6", col3: "31.1", col4: "1227", col5: "1251" },
  { col1: "3x4+1x2.5", col2: "15.9", col3: "16.5", col4: "371", col5: "395" },
  { col1: "3x6+ 1X4", col2: "17.1", col3: "18.1", col4: "427", col5: "457" },
  { col1: "3x10+1x6", col2: "19.5", col3: "20", col4: "528", col5: "549" },
  { col1: "3x16+1x10", col2: "21.8", col3: "22.3", col4: "656", col5: "677" },
  { col1: "3x16+1x10", col2: "22.2", col3: "22.7", col4: "674", col5: "696" },
  { col1: "3x25 1X16", col2: "25.4", col3: "26.6", col4: "882", col5: "926" },
  { col1: "3X25 1x16", col2: "26.1", col3: "26.6", col4: "908", col5: "932" },
  { col1: "3X35 1X16", col2: "27.7", col3: "28.2", col4: "1032", col5: "1057" },
  {
    col1: "3X35- 1x16",
    col2: "27.7",
    col3: "28.1",
    col4: "1037",
    col5: "1062",
  },
  {
    col1: "3x50- 1X25",
    col2: "31.6",
    col3: "32.1",
    col4: "1328",
    col5: "1356",
  },
  {
    col1: "3x50- 1X25",
    col2: "32.3",
    col3: "32.8",
    col4: "1364",
    col5: "1396",
  },
  { col1: "4x2.5", col2: "14.3", col3: "14.8", col4: "312", col5: "329" },
  { col1: "4X4", col2: "15.9", col3: "16.9", col4: "377", col5: "414" },
  { col1: "4x6", col2: "17.1", col3: "18.1", col4: "434", col5: "464" },
  { col1: "4X10", col2: "19.5", col3: "20", col4: "542", col5: "562" },
  { col1: "4X16", col2: "21.8", col3: "22.3", col4: "675", col5: "697" },
  { col1: "4X16", col2: "22.9", col3: "23.3", col4: "712", col5: "735" },
  { col1: "4x25", col2: "26.2", col3: "12.1", col4: "221", col5: "235" },
  { col1: "4X25", col2: "26.9", col3: "27.4", col4: "965", col5: "991" },
  { col1: "4X35", col2: "28.6", col3: "29", col4: "1118", col5: "1145" },
  { col1: "4X35", col2: "29.3", col3: "29.8", col4: "1144", col5: "1172" },
  { col1: "4x50", col2: "32.7", col3: "33.9", col4: "1438", col5: "1511" },
  { col1: "4x50", col2: "33.4", col3: "33.9", col4: "1481", col5: "1511" },
];

export const tableData2_1 = [
  { col1: "1х2.5", col2: "5.2", col3: "5.4", col4: "32", col5: "34" },
  { col1: "1х4", col2: "5.8", col3: "6.2", col4: "41", col5: "46" },
  { col1: "1х6", col2: "6.3", col3: "6.7", col4: "50", col5: "56" },
  { col1: "1х10 дан судал", col2: "7.3", col3: "7.5", col4: "70", col5: "73" },
  { col1: "1х16", col2: "9.3", col3: "9.5", col4: "110", col5: "114" },
  {
    col1: "1х16 дан судал",
    col2: "8.9",
    col3: "9.1",
    col4: "105",
    col5: "108",
  },
  { col1: "1х25", col2: "10.7", col3: "10.9", col4: "153", col5: "157" },
  {
    col1: "1х25 дан судал",
    col2: "10.4",
    col3: "10.6",
    col4: "148",
    col5: "153",
  },
  { col1: "1х35", col2: "11.7", col3: "11.9", col4: "188", col5: "193" },
  {
    col1: "1х35 дан судал",
    col2: "11.4",
    col3: "11.6",
    col4: "184",
    col5: "189",
  },
  { col1: "1х50", col2: "13.4", col3: "13.6", col4: "256", col5: "263" },
  {
    col1: "1х50 дан судал",
    col2: "13.1",
    col3: "13.3",
    col4: "248",
    col5: "254",
  },
  { col1: "1х70", col2: "", col3: "15", col4: "", col5: "327" },
  { col1: "1х95", col2: "", col3: "16.9", col4: "", col5: "408" },
  { col1: "1х120", col2: "", col3: "18.7", col4: "", col5: "507" },
  { col1: "1х150", col2: "", col3: "20.3", col4: "", col5: "618" },
  { col1: "1х185", col2: "", col3: "22.1", col4: "", col5: "727" },
  { col1: "1х240", col2: "", col3: "25.2", col4: "", col5: "945" },
  { col1: "", col2: "", col3: "", col4: "", col5: "" },
  { col1: "2х2.5", col2: "8.1", col3: "9.1", col4: "60", col5: "76" },
  { col1: "2х4", col2: "10", col3: "10.8", col4: "93", col5: "104" },
  { col1: "2х6", col2: "11", col3: "11.8", col4: "113", col5: "125" },
  {
    col1: "2х10 дан судал",
    col2: "13",
    col3: "13.4",
    col4: "154",
    col5: "161",
  },
  { col1: "2х16", col2: "15.8", col3: "16.2", col4: "216", col5: "224" },
  {
    col1: "2х16 дан судал",
    col2: "14.9",
    col3: "15.3",
    col4: "205",
    col5: "212",
  },
  { col1: "2х25", col2: "19.2", col3: "19.6", col4: "324", col5: "334" },
  {
    col1: "2х25 дан судал",
    col2: "18.6",
    col3: "19",
    col4: "315",
    col5: "324",
  },
  { col1: "2х35", col2: "21.2", col3: "21.6", col4: "398", col5: "399" },
  {
    col1: "2х35 дан судал",
    col2: "20.6",
    col3: "21",
    col4: "390",
    col5: "400",
  },
  { col1: "2х50", col2: "25", col3: "25.4", col4: "563", col5: "575" },
  {
    col1: "2х50 дан судал",
    col2: "24.4",
    col3: "24.8",
    col4: "545",
    col5: "557",
  },
  { col1: "2х70", col2: "", col3: "28.2", col4: "", col5: "712" },
  { col1: "2х95", col2: "", col3: "32", col4: "", col5: "884" },
  { col1: "2х120", col2: "", col3: "35.2", col4: "", col5: "1089" },
  { col1: "2х150", col2: "", col3: "38.4", col4: "", col5: "1320" },
  { col1: "2х185", col2: "", col3: "42", col4: "", col5: "1547" },
  { col1: "2х240", col2: "", col3: "47.8", col4: "", col5: "1997" },
  { col1: "", col2: "", col3: "", col4: "", col5: "" },
  { col1: "3х2.5", col2: "9.2", col3: "9.6", col4: "87", col5: "93" },
  { col1: "3х4", col2: "10.6", col3: "11.4", col4: "115", col5: "130" },
  { col1: "3х6", col2: "11.7", col3: "12.5", col4: "142", col5: "158" },
  {
    col1: "3х10 дан судал",
    col2: "13.8",
    col3: "14.2",
    col4: "199",
    col5: "209",
  },
  { col1: "3х16", col2: "16.8", col3: "17.2", col4: "284", col5: "295" },
  {
    col1: "3х16 дан судал",
    col2: "15.8",
    col3: "16.2",
    col4: "270",
    col5: "281",
  },
  { col1: "3х25", col2: "20.4", col3: "20.9", col4: "430", col5: "443" },
  {
    col1: "3х25 дан судал",
    col2: "19.8",
    col3: "20.2",
    col4: "418",
    col5: "430",
  },
  { col1: "3х35", col2: "22.6", col3: "23", col4: "534", col5: "549" },
  {
    col1: "3х35 дан судал",
    col2: "21.9",
    col3: "22.4",
    col4: "524",
    col5: "538",
  },
  { col1: "3х50", col2: "26.6", col3: "27", col4: "760", col5: "778" },
  {
    col1: "3х50 дан судал",
    col2: "26",
    col3: "26.4",
    col4: "737",
    col5: "753",
  },
  { col1: "3х4+1х2.5", col2: "11.5", col3: "12.1", col4: "133", col5: "148" },
  { col1: "3х6+1х4", col2: "12.7", col3: "13.7", col4: "168", col5: "188" },
  { col1: "3х10+1х6", col2: "15.1", col3: "15.6", col4: "233", col5: "247" },
  { col1: "3х16+1х10", col2: "18.2", col3: "18.7", col4: "347", col5: "362" },
  {
    col1: "3х16+1х10 single core",
    col2: "17.3",
    col3: "18.3",
    col4: "321",
    col5: "350",
  },
  { col1: "3х25+1х16", col2: "21.7", col3: "22.2", col4: "499", col5: "515" },
  {
    col1: "3х25+1х16 single core",
    col2: "21",
    col3: "22.2",
    col4: "486",
    col5: "508",
  },
  { col1: "3х35+1х16", col2: "23.7", col3: "24.1", col4: "620", col5: "638" },
  {
    col1: "3х35+1х16 дан судал",
    col2: "23.7",
    col3: "24.2",
    col4: "615",
    col5: "632",
  },
  { col1: "3х50+1х25", col2: "28.3", col3: "28.8", col4: "869", col5: "889" },
  {
    col1: "3х50+1х25 дан судал",
    col2: "27.6",
    col3: "28.1",
    col4: "844",
    col5: "864",
  },
  { col1: "", col2: "", col3: "", col4: "", col5: "" },
  { col1: "4х2.5", col2: "9.9", col3: "10.4", col4: "104", col5: "112" },
  { col1: "4х4", col2: "11.5", col3: "12.5", col4: "140", col5: "158" },
  { col1: "4х6", col2: "12.7", col3: "13.7", col4: "174", col5: "195" },
  {
    col1: "4х10 дан судал",
    col2: "15.1",
    col3: "15.6",
    col4: "248",
    col5: "260",
  },
  { col1: "4х16", col2: "18.9", col3: "19.3", col4: "374", col5: "389" },
  {
    col1: "4х16 дан судал",
    col2: "17.4",
    col3: "18.3",
    col4: "340",
    col5: "369",
  },
  { col1: "4х25", col2: "22.5", col3: "23", col4: "542", col5: "559" },
  {
    col1: "4х25 дан судал",
    col2: "21.8",
    col3: "22.2",
    col4: "526",
    col5: "543",
  },
  { col1: "4х35", col2: "25.3", col3: "25.8", col4: "701", col5: "720" },
  {
    col1: "4х35 дан судал",
    col2: "24.6",
    col3: "25",
    col4: "687",
    col5: "706",
  },
  { col1: "4х50", col2: "29.4", col3: "29.9", col4: "967", col5: "990" },
  {
    col1: "4х50 дан судал",
    col2: "28.7",
    col3: "29.1",
    col4: "937",
    col5: "958",
  },
];

export const tableData2_2 = [
  { col1: "3х70", col2: "", col3: "26.2", col4: "", col5: "960" },
  { col1: "3х95", col2: "", col3: "29.2", col4: "", col5: "1201" },
  { col1: "3х120", col2: "", col3: "31.6", col4: "", col5: "1449" },
  { col1: "3х150", col2: "", col3: "34.8", col4: "", col5: "1816" },
  { col1: "3х185", col2: "", col3: "37.8", col4: "", col5: "2151" },
  { col1: "3х240", col2: "", col3: "42.2", col4: "", col5: "2745" },
  { col1: "3х70+1х35", col2: "", col3: "29.8", col4: "", col5: "1118" },
  { col1: "3х95+1х50", col2: "", col3: "33.2", col4: "", col5: "1417" },
  { col1: "3х120+1х70", col2: "", col3: "36.2", col4: "", col5: "1762" },
  { col1: "3х150+1х70", col2: "", col3: "39.6", col4: "", col5: "2107" },
  { col1: "3х185+1х95", col2: "", col3: "43", col4: "", col5: "2515" },
  { col1: "3х240+1х120", col2: "", col3: "48.6", col4: "", col5: "3235" },
  { col1: "", col2: "", col3: "", col4: "", col5: "" },
  { col1: "4х70", col2: "", col3: "29.8", col4: "", col5: "1242" },
  { col1: "4х95", col2: "", col3: "33.2", col4: "", col5: "1559" },
  { col1: "4х120", col2: "", col3: "36.2", col4: "", col5: "1916" },
  { col1: "4х150", col2: "", col3: "39.6", col4: "", col5: "2366" },
  { col1: "4х185", col2: "", col3: "43", col4: "", col5: "2805" },
  { col1: "4х240", col2: "", col3: "48.6", col4: "", col5: "3635" },
];

export const tableData3 = [
  { col1: "1х1,5", col2: "6,7", col3: "64" },
  { col1: "1х4", col2: "8,0", col3: "90" },
  { col1: "1х6", col2: "9,0", col3: "120" },
  { col1: "1х10", col2: "11,1", col3: "187" },
  { col1: "1х16", col2: "12,4", col3: "257" },
  { col1: "1х25", col2: "14,6", col3: "368" },
  { col1: "1х35", col2: "16,4", col3: "489" },
  { col1: "1х50", col2: "19,0", col3: "667" },
  { col1: "1х70", col2: "21,5", col3: "891" },
  { col1: "1х95", col2: "24,2", col3: "1141" },
  { col1: "1х120", col2: "26,4", col3: "1403" },
  { col1: "1х150*", col2: "27,2", col3: "1650" },
  { col1: "1х185*", col2: "29,9", col3: "2030" },
  { col1: "1х240*", col2: "33,3", col3: "2650" },
  { col1: "1х300*", col2: "36,1", col3: "3260" },
  { col1: "", col2: "", col3: "" },
  { col1: "2х0,75", col2: "8,2", col3: "90" },
  { col1: "2х1,0", col2: "8,5", col3: "100" },
  { col1: "2х1,5", col2: "9,4", col3: "130" },
  { col1: "2х2,5", col2: "11,2", col3: "190" },
  { col1: "2х4", col2: "13,5", col3: "280" },
  { col1: "2х6", col2: "15,5", col3: "380" },
  { col1: "2х10", col2: "21,2", col3: "680" },
  { col1: "2х16", col2: "23,7", col3: "920" },
  { col1: "2х25", col2: "28,4", col3: "1340" },
  { col1: "2х35", col2: "31,2", col3: "1680" },
  { col1: "2х50", col2: "38,0", col3: "2450" },
  { col1: "2х70", col2: "42,2", col3: "3170" },
  { col1: "2х95", col2: "47,2", col3: "4040" },
  { col1: "2х120", col2: "50,7", col3: "4800" },
  { col1: "", col2: "", col3: "" },
  { col1: "3х0,75", col2: "8,8", col3: "110" },
  { col1: "3х1,0", col2: "9,1", col3: "120" },
  { col1: "3х1,5", col2: "10,1", col3: "160" },
  { col1: "3х2,5", col2: "12,0", col3: "230" },
  { col1: "3х4", col2: "14,5", col3: "350" },
  { col1: "3х6", col2: "16,6", col3: "460" },
  { col1: "3х10", col2: "22,2", col3: "840" },
  { col1: "3х16", col2: "25,4", col3: "1130" },
  { col1: "3х25", col2: "30,4", col3: "1660" },
  { col1: "3х35", col2: "34,0", col3: "2150" },
  { col1: "3х50", col2: "39,5", col3: "2970" },
  { col1: "3х70", col2: "44,7", col3: "3930" },
  { col1: "3х95", col2: "50,6", col3: "5100" },
  { col1: "3х120", col2: "54,4", col3: "6150" },
  { col1: "", col2: "", col3: "" },
  { col1: "4х1,0", col2: "9,1", col3: "120" },
  { col1: "4х1,5", col2: "10,1", col3: "160" },
  { col1: "4х2,5", col2: "12,0", col3: "230" },
  { col1: "4х4", col2: "14,5", col3: "350" },
  { col1: "4х6", col2: "18,4", col3: "590" },
  { col1: "4х10", col2: "24,3", col3: "1000" },
  { col1: "4х16", col2: "27,8", col3: "1400" },
  { col1: "4х25", col2: "33,7", col3: "2100" },
  { col1: "4х35", col2: "37,7", col3: "2730" },
  { col1: "4х50", col2: "43,8", col3: "3700" },
  { col1: "4х70", col2: "49,7", col3: "5000" },
  { col1: "4х95", col2: "56,6", col3: "6500" },
  { col1: "", col2: "", col3: "" },
  { col1: "5x1,0", col2: "11,1", col3: "190" },
  { col1: "5x1,5", col2: "12,2", col3: "240" },
  { col1: "5x2,5", col2: "14,5", col3: "350" },
  { col1: "5x4", col2: "17,8", col3: "530" },
  { col1: "5x6", col2: "20,5", col3: "720" },
  { col1: "5x10", col2: "26,8", col3: "1250" },
  { col1: "5x16", col2: "30,9", col3: "1700" },
  { col1: "5x25", col2: "37,4", col3: "2600" },
  { col1: "", col2: "", col3: "" },
  { col1: "2x2,5+1x1,5", col2: "11,8", col3: "220" },
  { col1: "2x4+1x2,5", col2: "13,9", col3: "310" },
  { col1: "2x6+1x4", col2: "16,3", col3: "440" },
  { col1: "2x10+1x6", col2: "21,0", col3: "740" },
  { col1: "2x16+1x6", col2: "25,0", col3: "1070" },
  { col1: "2x16+1x10", col2: "25,0", col3: "1070" },
  { col1: "2x25+1x10", col2: "30,0", col3: "1550" },
  { col1: "2x25+1x16", col2: "30,0", col3: "1550" },
  { col1: "2x35+1x10", col2: "32,4", col3: "1890" },
  { col1: "2x35+1x16", col2: "32,4", col3: "1890" },
  { col1: "2x50+1x10", col2: "37,9", col3: "2600" },
  { col1: "2x50+1x16", col2: "37,9", col3: "2600" },
  { col1: "2x50+1x25", col2: "37,9", col3: "2600" },
  { col1: "2x70+1x10", col2: "42,7", col3: "3400" },
  { col1: "2x70+1x25", col2: "42,7", col3: "3400" },
  { col1: "2x70+1x35", col2: "42,7", col3: "3400" },
  { col1: "2x95+1x35", col2: "47,8", col3: "4400" },
  { col1: "2x95+1x50", col2: "47,8", col3: "4400" },
  { col1: "2x120+1x35", col2: "54,4", col3: "5800" },
  { col1: "2x120+1x70", col2: "54,4", col3: "5800" },
  { col1: "", col2: "", col3: "" },
  { col1: "3x2,5+1x1,5", col2: "13,2", col3: "240" },
  { col1: "3x4+1x2,5", col2: "15,5", col3: "340" },
  { col1: "3x6+1x4", col2: "18,0", col3: "460" },
  { col1: "3x10+1x6", col2: "23,4", col3: "790" },
  { col1: "3x16+1x6", col2: "27,6", col3: "990" },
  { col1: "3x16+1x10", col2: "27,6", col3: "1110" },
  { col1: "3x25+1x10", col2: "33,1", col3: "1480" },
  { col1: "3x25+1x16", col2: "33,1", col3: "1590" },
  { col1: "3x35+1x10", col2: "36,5", col3: "1890" },
  { col1: "3x35+1x16", col2: "36,5", col3: "2020" },
  { col1: "3x50+1x16", col2: "42,4", col3: "2580" },
  { col1: "3x50+1x25", col2: "42,4", col3: "2750" },
  { col1: "3x70+1x25", col2: "47,7", col3: "3410" },
  { col1: "3x70+1x35", col2: "47,7", col3: "3580" },
  { col1: "3x95+1x35", col2: "53,6", col3: "4450" },
  { col1: "3x95+1x50", col2: "53,6", col3: "4680" },
  { col1: "3x120+1x35", col2: "60,8", col3: "5490" },
  { col1: "3x120+1x70", col2: "60,8", col3: "5890" },
  { col1: "3x150+1x50*", col2: "64,4", col3: "6800" },
  { col1: "3x150+1x95*", col2: "68,3", col3: "7500" },
  { col1: "3x150+1x120*", col2: "68,3", col3: "7800" },
  { col1: "", col2: "", col3: "" },
  { col1: "2x2,5+2x1,5", col2: "12,3", col3: "260" },
  { col1: "2x4+2x2,5", col2: "15,0", col3: "390" },
  { col1: "2x6+2x4", col2: "17,4", col3: "550" },
  { col1: "2x10+2x6", col2: "22,5", col3: "900" },
  { col1: "2x16+2x6", col2: "24,5", col3: "1100" },
  { col1: "2x25+2x10", col2: "29,6", col3: "1600" },
  { col1: "2x35+2x10", col2: "32,1", col3: "1950" },
  { col1: "2x50+2x10", col2: "35,9", col3: "2500" },
  { col1: "2x70+2x10", col2: "39,1", col3: "3000" },
  { col1: "", col2: "", col3: "" },
  { col1: "3x2,5+2x1,5", col2: "14,2", col3: "340" },
  { col1: "3x4+2x2,5", col2: "17,2", col3: "500" },
  { col1: "3x6+2x4", col2: "20,3", col3: "710" },
  { col1: "3x10+2x6", col2: "25,5", col3: "1100" },
  { col1: "3x16+2x6", col2: "29,8", col3: "1600" },
  { col1: "3x25+2x10", col2: "35,9", col3: "2300" },
  { col1: "3x35+2x10", col2: "40,3", col3: "3000" },
  { col1: "3x50+2x10", col2: "46,5", col3: "4000" },
  { col1: "3x70+2x10", col2: "51,6", col3: "4900" },
];

export const tableData4_1 = [
  { col1: "1х2,5", col2: "3,26", col3: "14" },
  { col1: "1х4", col2: "3,72", col3: "20" },
  { col1: "1х6", col2: "4,22", col3: "26" },
  { col1: "1х10ozh", col2: "5,40", col3: "43" },
  { col1: "1х10", col2: "5,95", col3: "47" },
  { col1: "1х16ozh", col2: "6,35", col3: "63" },
  { col1: "1х16", col2: "6,80", col3: "66" },
  { col1: "1х25", col2: "8,10", col3: "100" },
  { col1: "1х25ozh", col2: "7,80", col3: "97" },
  { col1: "1х35", col2: "9,10", col3: "130" },
  { col1: "1х35ozh", col2: "8,80", col3: "127" },
  { col1: "1х50", col2: "10,80", col3: "190" },
  { col1: "1х50ozh", col2: "10,50", col3: "182" },
  { col1: "1х70", col2: "12,20", col3: "246" },
  { col1: "1х95", col2: "14,30", col3: "325" },
  { col1: "1х120", col2: "15,70", col3: "400" },
  { col1: "1х150", col2: "17,30", col3: "502" },
  { col1: "1х185", col2: "19,30", col3: "609" },
  { col1: "1х240", col2: "22,00", col3: "791" },
];

export const tableData4_2 = [
  { col1: "1х0,5", col2: "1,89", col3: "7" },
  { col1: "1х0,75", col2: "20,70", col3: "10" },
  { col1: "1х1", col2: "2,23", col3: "13" },
  { col1: "1х1,5", col2: "2,66", col3: "18" },
  { col1: "1х2,5", col2: "3,26", col3: "29" },
  { col1: "1х4", col2: "3,72", col3: "44" },
  { col1: "1х6", col2: "4,22", col3: "62" },
  { col1: "1х10ozh", col2: "5,40", col3: "103" },
  { col1: "1х10", col2: "5,95", col3: "109" },
  { col1: "1х16ozh", col2: "6,35", col3: "159" },
  { col1: "1х16", col2: "6,80", col3: "164" },
  { col1: "1х25", col2: "8,10", col3: "253" },
  { col1: "1х25ozh", col2: "7,80", col3: "249" },
  { col1: "1х35", col2: "9,10", col3: "337" },
  { col1: "1х35ozh", col2: "8,80", col3: "339" },
  { col1: "1х50", col2: "10,80", col3: "503" },
  { col1: "1х50ozh", col2: "10,50", col3: "485" },
  { col1: "1х70", col2: "12,20", col3: "672" },
  { col1: "1х95", col2: "14,30", col3: "874" },
  { col1: "1х120", col2: "15,70", col3: "1095" },
  { col1: "1х150", col2: "17,30", col3: "1395" },
  { col1: "1х185", col2: "19,30", col3: "1677" },
  { col1: "1х240", col2: "22,00", col3: "2190" },
];

export const tableData5_1 = [
  { col1: "1х0,5", col2: "2,10", col3: "8,2" },
  { col1: "1х0,75", col2: "2,30", col3: "10,3" },
  { col1: "1х1", col2: "2,50", col3: "13,6" },
  { col1: "1х1,5", col2: "3,00", col3: "20,2" },
  { col1: "1х2,5", col2: "3,70", col3: "32,2" },
  { col1: "1х4", col2: "4,20", col3: "46,5" },
  { col1: "1х6", col2: "4,70", col3: "66,9" },
  { col1: "1х10", col2: "6,30", col3: "111,6" },
  { col1: "1х16", col2: "7,50", col3: "173,9" },
  { col1: "1х25", col2: "9,20", col3: "266,9" },
  { col1: "1х35", col2: "10,30", col3: "349,0" },
  { col1: "1х50", col2: "12,30", col3: "497,7" },
  { col1: "1х70", col2: "14,40", col3: "681,0" },
  { col1: "1х95", col2: "16,30", col3: "923,1" },
  { col1: "1х120", col2: "18,20", col3: "1180,5" },
  { col1: "1х150", col2: "20,00", col3: "1430,7" },
  { col1: "1х185", col2: "22,20", col3: "1767,0" },
  { col1: "1х240", col2: "25,50", col3: "2346,6" },
];
export const tableData5_2 = [
  { col1: "1х0,5", col2: "2,00", col3: "8,1" },
  { col1: "1х0,75", col2: "2,30", col3: "10,7" },
  { col1: "1х1", col2: "2,50", col3: "13,6" },
  { col1: "1х1,5", col2: "3,00", col3: "20,2" },
  { col1: "1х2,5", col2: "3,50", col3: "30,4" },
  { col1: "1х4", col2: "4,20", col3: "46,5" },
  { col1: "1х6", col2: "4,70", col3: "66,9" },
  { col1: "1х10", col2: "6,30", col3: "111,6" },
  { col1: "1х16", col2: "7,50", col3: "173,9" },
  { col1: "1х25", col2: "9,20", col3: "266,9" },
  { col1: "1х35", col2: "10,30", col3: "349,0" },
  { col1: "1х50", col2: "12,30", col3: "497,7" },
  { col1: "1х70", col2: "14,10", col3: "681,0" },
  { col1: "1х95", col2: "16,30", col3: "923,1" },
  { col1: "1х120", col2: "18,20", col3: "1180,5" },
  { col1: "1х150", col2: "20,00", col3: "1430,7" },
  { col1: "1х185", col2: "22,20", col3: "1767,0" },
  { col1: "1х240", col2: "25,50", col3: "2326,6" },
];

export const tableData6 = [
  {
    col1: "2x0,75",
    col2: "0,6",
    col3: "0,8",
    col4: "5,7",
    col5: "7,2",
    col6: "6,0",
    col7: "6,6",
    col8: "52",
  },
  {
    col1: "2x1,0",
    col2: "0,6",
    col3: "0,8",
    col4: "5,9",
    col5: "7,5",
    col6: "6,4",
    col7: "7,0",
    col8: "59",
  },
  {
    col1: "2x1,5",
    col2: "0,7",
    col3: "0,8",
    col4: "6,8",
    col5: "8,6",
    col6: "7,4",
    col7: "8,2",
    col8: "81",
  },
  {
    col1: "2x2,5",
    col2: "0,8",
    col3: "1,0",
    col4: "8,4",
    col5: "10,6",
    col6: "-",
    col7: "-",
    col8: "123",
  },
  {
    col1: "3x0,75",
    col2: "0,6",
    col3: "0,8",
    col4: "6,0",
    col5: "7,6",
    col6: "6,4",
    col7: "7,0",
    col8: "61",
  },
  {
    col1: "3x1,0",
    col2: "0,6",
    col3: "0,8",
    col4: "6,3",
    col5: "8,0",
    col6: "6,8",
    col7: "7,6",
    col8: "71",
  },
  {
    col1: "3x1,5",
    col2: "0,7",
    col3: "0,9",
    col4: "7,4",
    col5: "9,4",
    col6: "8,0",
    col7: "8,8",
    col8: "101",
  },
  {
    col1: "3x2,5",
    col2: "0,8",
    col3: "1,1",
    col4: "9,2",
    col5: "11,4",
    col6: "-",
    col7: "-",
    col8: "154",
  },
  {
    col1: "4x0,75",
    col2: "0,6",
    col3: "0,8",
    col4: "6,6",
    col5: "8,3",
    col6: "-",
    col7: "-",
    col8: "74",
  },
  {
    col1: "4x1,0",
    col2: "0,6",
    col3: "0,9",
    col4: "7,1",
    col5: "9,0",
    col6: "-",
    col7: "-",
    col8: "89",
  },
  {
    col1: "4x1,5",
    col2: "0,7",
    col3: "1,0",
    col4: "8,4",
    col5: "10,5",
    col6: "-",
    col7: "-",
    col8: "127",
  },
  {
    col1: "4x2,5",
    col2: "0,8",
    col3: "1,1",
    col4: "10,1",
    col5: "12,5",
    col6: "-",
    col7: "-",
    col8: "188",
  },
  {
    col1: "5x0,75",
    col2: "0,6",
    col3: "0,9",
    col4: "7,4",
    col5: "9,3",
    col6: "-",
    col7: "-",
    col8: "90",
  },
  {
    col1: "5x1,0",
    col2: "0,6",
    col3: "0,9",
    col4: "7,8",
    col5: "9,8",
    col6: "-",
    col7: "-",
    col8: "106",
  },
  {
    col1: "5x1,5",
    col2: "0,7",
    col3: "1,1",
    col4: "9,3",
    col5: "11,6",
    col6: "-",
    col7: "-",
    col8: "155",
  },
  {
    col1: "5x2,5",
    col2: "0,8",
    col3: "1,2",
    col4: "11,2",
    col5: "13,9",
    col6: "-",
    col7: "-",
    col8: "229",
  },
];

export const tableData7_1 = [
  { col1: "1", col2: "ногоон-шар", col3: "хар" },
  { col1: "2", col2: "ногоон-шар, хар", col3: "хар, цэнхэр, бор" },
  { col1: "3", col2: "ногоон-шар, хар, цэнхэр", col3: "хар, цэнхэр, бор" },
  {
    col1: "4",
    col2: "ногоон-шар, хар, цэнхэр, бор",
    col3: "хар, цэнхэр, бор, хар",
  },
  {
    col1: "5",
    col2: "ногоон-шар, хар, цэнхэр",
    col3: "хар, цэнхэр, бор, хар, хар",
  },
];

export const tableData7_2 = [
  { col1: "1х1,5", col2: "5,4", col3: "42" },
  { col1: "1х2,5", col2: "6,0", col3: "57" },
  { col1: "1х4", col2: "6,6", col3: "76" },
  { col1: "1х6", col2: "7,1", col3: "98" },
  { col1: "1х10", col2: "8,4", col3: "146" },
  { col1: "1х16", col2: "9,5", col3: "215" },
  { col1: "", col2: "", col3: "" },
  { col1: "2х1,5", col2: "8,7", col3: "118" },
  { col1: "2х2,5", col2: "9,9", col3: "161" },
  { col1: "2х4", col2: "11,2", col3: "219" },
  { col1: "2х6", col2: "12,6", col3: "291" },
  { col1: "2х10", col2: "15,4", col3: "452" },
  { col1: "2х16", col2: "18,2", col3: "661" },
  { col1: "2х25", col2: "21,8", col3: "989" },
  { col1: "2х35", col2: "24,2", col3: "1272" },
];

export const tableData7_3 = [
  { col1: "3х1,5", col2: "9,1", col3: "134" },
  { col1: "3х2,5", col2: "10,4", col3: "187" },
  { col1: "3х4", col2: "11,8", col3: "259" },
  { col1: "3х6", col2: "13,3", col3: "348" },
  { col1: "3х10", col2: "16,2", col3: "544" },
  { col1: "3х16", col2: "19,6", col3: "829" },
  { col1: "3х25", col2: "23", col3: "1214" },
  { col1: "3х35", col2: "25,6", col3: "1574" },
  { col1: "4х1,5", col2: "9,8", col3: "158" },
  { col1: "4х2,5", col2: "11,2", col3: "233" },
  { col1: "4х4", col2: "13,2", col3: "323" },
  { col1: "4х6", col2: "14,8", col3: "438" },
  { col1: "4х10", col2: "17,7", col3: "662" },
  { col1: "4х16", col2: "21,4", col3: "1013" },
  { col1: "4х25", col2: "25,6", col3: "1526" },
  { col1: "4х35", col2: "28", col3: "1945" },
  { col1: "5х1,5", col2: "10,5", col3: "183" },
  { col1: "5х2,5", col2: "12,1", col3: "261" },
  { col1: "5х4", col2: "14,7", col3: "398" },
  { col1: "5х6", col2: "16,1", col3: "518" },
  { col1: "5х10", col2: "19,3", col3: "788" },
  { col1: "5х16", col2: "23,8", col3: "1230" },
  { col1: "5х25", col2: "28", col3: "1825" },
];

export const tableData8_1 = [
  {
    col1: "16/2,7",
    col2: "6",
    col3: "1,85",
    col4: "1",
    col5: "1,85",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "25/4,2",
    col2: "6",
    col3: "2,30",
    col4: "1",
    col5: "2,30",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "35/6,2",
    col2: "6",
    col3: "2,80",
    col4: "1",
    col5: "2,80",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "40/6,7",
    col2: "6",
    col3: "2,91",
    col4: "1",
    col5: "2,91",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "50/8,0",
    col2: "6",
    col3: "3,20",
    col4: "1",
    col5: "3,20",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "3000",
  },
  {
    col1: "63/10,5",
    col2: "6",
    col3: "3,66",
    col4: "1",
    col5: "3,66",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "2000",
  },
  {
    col1: "70/11",
    col2: "6",
    col3: "3,80",
    col4: "1",
    col5: "3,80",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "2000",
  },
  {
    col1: "95/16",
    col2: "6",
    col3: "4,50",
    col4: "1",
    col5: "4,50",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "1500",
  },
  {
    col1: "100/16,7",
    col2: "6",
    col3: "4,61",
    col4: "1",
    col5: "4,61",
    col6: "1",
    col7: "-",
    col8: "6,00",
    col9: "1500",
  },
  {
    col1: "120/19",
    col2: "26",
    col3: "2,40",
    col4: "7",
    col5: "1,85",
    col6: "2",
    col7: "1",
    col8: "6,25",
    col9: "2000",
  },
  {
    col1: "120/27",
    col2: "30",
    col3: "2,20",
    col4: "7",
    col5: "2,20",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "125/6,9",
    col2: "18",
    col3: "2,97",
    col4: "1",
    col5: "2,97",
    col6: "2",
    col7: "-",
    col8: "18,11",
    col9: "2000",
  },
  {
    col1: "125/20,4",
    col2: "26",
    col3: "2,47",
    col4: "7",
    col5: "1,92",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "150/19",
    col2: "24",
    col3: "2,80",
    col4: "7",
    col5: "1,85",
    col6: "2",
    col7: "1",
    col8: "7,85",
    col9: "2000",
  },
  {
    col1: "150/24",
    col2: "26",
    col3: "2,70",
    col4: "7",
    col5: "2,10",
    col6: "2",
    col7: "1",
    col8: "6,14",
    col9: "2000",
  },
  {
    col1: "150/34",
    col2: "30",
    col3: "2,50",
    col4: "7",
    col5: "2,50",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "160/8,9",
    col2: "18",
    col3: "3,36",
    col4: "1",
    col5: "3,36",
    col6: "2",
    col7: "-",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "160/26,1",
    col2: "26",
    col3: "2,80",
    col4: "7",
    col5: "2,18",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "185/24",
    col2: "24",
    col3: "3,15",
    col4: "7",
    col5: "2,10",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "185/29",
    col2: "26",
    col3: "2,98",
    col4: "7",
    col5: "2,30",
    col6: "2",
    col7: "1",
    col8: "6,24",
    col9: "2000",
  },
  {
    col1: "185/43",
    col2: "30",
    col3: "2,80",
    col4: "7",
    col5: "2,80",
    col6: "2",
    col7: "1",
    col8: "4,29",
    col9: "2000",
  },
  {
    col1: "200/11,1",
    col2: "18",
    col3: "3,76",
    col4: "1",
    col5: "3,76",
    col6: "2",
    col7: "-",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "200/32,6",
    col2: "26",
    col3: "3,13",
    col4: "7",
    col5: "2,43",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "205/27,0",
    col2: "24",
    col3: "3,30",
    col4: "7",
    col5: "2,20",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "240/32",
    col2: "24",
    col3: "3,60",
    col4: "7",
    col5: "2,40",
    col6: "2",
    col7: "1",
    col8: "7,71",
    col9: "2000",
  },
  {
    col1: "240/39",
    col2: "26",
    col3: "3,40",
    col4: "7",
    col5: "2,65",
    col6: "2",
    col7: "1",
    col8: "6,11",
    col9: "2000",
  },
  {
    col1: "300/39",
    col2: "24",
    col3: "4,00",
    col4: "7",
    col5: "2,65",
    col6: "2",
    col7: "1",
    col8: "7,81",
    col9: "2000",
  },
  {
    col1: "300/48",
    col2: "26",
    col3: "3,80",
    col4: "7",
    col5: "2,95",
    col6: "3",
    col7: "1",
    col8: "6,16",
    col9: "2000",
  },
  {
    col1: "315/21,8",
    col2: "45",
    col3: "2,99",
    col4: "7",
    col5: "1,99",
    col6: "2",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "315/51,3",
    col2: "26",
    col3: "3,93",
    col4: "7",
    col5: "3,05",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "2000",
  },
  {
    col1: "330/30,0",
    col2: "48",
    col3: "2,98",
    col4: "7",
    col5: "2,30",
    col6: "3",
    col7: "1",
    col8: "11,55",
    col9: "2000",
  },
  {
    col1: "330/43,0",
    col2: "54",
    col3: "2,80",
    col4: "7",
    col5: "2,80",
    col6: "3",
    col7: "1",
    col8: "7,71",
    col9: "1500",
  },
  {
    col1: "400/18",
    col2: "42",
    col3: "3,40",
    col4: "7",
    col5: "1,85",
    col6: "3",
    col7: "1",
    col8: "20,27",
    col9: "1500",
  },
  {
    col1: "400/27,7",
    col2: "45",
    col3: "3,36",
    col4: "7",
    col5: "2,24",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "400/51",
    col2: "54",
    col3: "3,05",
    col4: "7",
    col5: "3,05",
    col6: "3",
    col7: "1",
    col8: "7,71",
    col9: "1500",
  },
  {
    col1: "400/51,9",
    col2: "54",
    col3: "3,07",
    col4: "7",
    col5: "3,07",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "450/31,1",
    col2: "45",
    col3: "3,57",
    col4: "7",
    col5: "2,38",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1500",
  },
  {
    col1: "500/26",
    col2: "42",
    col3: "3,90",
    col4: "7",
    col5: "2,20",
    col6: "3",
    col7: "1",
    col8: "18,86",
    col9: "1200",
  },
  {
    col1: "500/34,6",
    col2: "45",
    col3: "3,76",
    col4: "7",
    col5: "2,51",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "560/38,7",
    col2: "45",
    col3: "3,98",
    col4: "7",
    col5: "2,65",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "630/43,6",
    col2: "45",
    col3: "4,22",
    col4: "7",
    col5: "2,81",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
  {
    col1: "710/49,1",
    col2: "45",
    col3: "4,48",
    col4: "7",
    col5: "2,99",
    col6: "3",
    col7: "1",
    col8: "-",
    col9: "1000",
  },
];

export const tableData8_2 = [
  {
    col1: "16/2,69",
    col2: "5,6",
    col3: "1,9",
    col4: "17,818",
    col5: "6220",
    col6: "44,0",
    col7: "20,9",
    col8: "64,9",
  },
  {
    col1: "24,9/4,15",
    col2: "6,9",
    col3: "2,3",
    col4: "11,521",
    col5: "9296",
    col6: "67,9",
    col7: "32,4",
    col8: "100,3",
  },
  {
    col1: "36,9/6,15",
    col2: "8,4",
    col3: "2,8",
    col4: "7,774",
    col5: "13524",
    col6: "100,0",
    col7: "48,0",
    col8: "148,0",
  },
  {
    col1: "40/6,7",
    col2: "8,74",
    col3: "2,91",
    col4: "7,172",
    col5: "14400",
    col6: "-",
    col7: "-",
    col8: "161,3",
  },
  {
    col1: "48,2/8,04",
    col2: "9,6",
    col3: "3,2",
    col4: "5,951",
    col5: "17112",
    col6: "132,0",
    col7: "63,0",
    col8: "195,0",
  },
  {
    col1: "63/10,5",
    col2: "10,97",
    col3: "3,66",
    col4: "4,553",
    col5: "21630",
    col6: "-",
    col7: "-",
    col8: "254,0",
  },
  {
    col1: "68/11,3",
    col2: "11,4",
    col3: "3,8",
    col4: "4,218",
    col5: "24130",
    col6: "188,0",
    col7: "88,0",
    col8: "276,0",
  },
  {
    col1: "95,4/15,9",
    col2: "13,5",
    col3: "4,5",
    col4: "3,007",
    col5: "33369",
    col6: "261,0",
    col7: "124,0",
    col8: "385,0",
  },
  {
    col1: "100/16,7",
    col2: "13,82",
    col3: "4,61",
    col4: "2,868",
    col5: "34333",
    col6: "-",
    col7: "-",
    col8: "403,2",
  },
  {
    col1: "118/18,8",
    col2: "15,2",
    col3: "5,6",
    col4: "2,440",
    col5: "41521",
    col6: "324,0",
    col7: "147,0",
    col8: "471,0",
  },
  {
    col1: "114/26,6",
    col2: "15,4",
    col3: "6,6",
    col4: "2,531",
    col5: "49465",
    col6: "320,0",
    col7: "208,0",
    col8: "528,0",
  },
  {
    col1: "125/6,9",
    col2: "14,67",
    col3: "2,97",
    col4: "2,304",
    col5: "29167",
    col6: "-",
    col7: "-",
    col8: "397,9",
  },
  {
    col1: "125/20,4",
    col2: "15,67",
    col3: "5,77",
    col4: "2,308",
    col5: "45694",
    col6: "-",
    col7: "-",
    col8: "503,5",
  },
  {
    col1: "148/18,8",
    col2: "16,8",
    col3: "5,6",
    col4: "2,046",
    col5: "46307",
    col6: "407,0",
    col7: "147,0",
    col8: "554,0",
  },
  {
    col1: "149/24,2",
    col2: "17,1",
    col3: "6,3",
    col4: "2,039",
    col5: "52279",
    col6: "409,0",
    col7: "190,0",
    col8: "599,0",
  },
  {
    col1: "147/34,3",
    col2: "17,5",
    col3: "7,5",
    col4: "2,061",
    col5: "62643",
    col6: "406,0",
    col7: "269,0",
    col8: "675,0",
  },
  {
    col1: "160/8,9",
    col2: "16,82",
    col3: "3,36",
    col4: "1,800",
    col5: "36178",
    col6: "-",
    col7: "-",
    col8: "509,4",
  },
  {
    col1: "160/26,1",
    col2: "17,73",
    col3: "6,53",
    col4: "1,803",
    col5: "57689",
    col6: "-",
    col7: "-",
    col8: "644,5",
  },
];

export const tableData9_1 = [
  { col1: "16", col2: "7", col3: "4,6", col4: "5,1", col5: "1,910" },
  { col1: "25", col2: "7", col3: "5,7", col4: "6,1", col5: "1200" },
  { col1: "35", col2: "7", col3: "6,7", col4: "7,1", col5: "868" },
  { col1: "50", col2: "7", col3: "7,85", col4: "8,35", col5: "641" },
  { col1: "70", col2: "7", col3: "9,45", col4: "9,95", col5: "443" },
  { col1: "95", col2: "7", col3: "11,1", col4: "11,7", col5: "320" },
  { col1: "120", col2: "19", col3: "11,1", col4: "13,1", col5: "253" },
  { col1: "150", col2: "19", col3: "14,0", col4: "14,5", col5: "206" },
  { col1: "185", col2: "19", col3: "15,45", col4: "16,15", col5: "164" },
  { col1: "240", col2: "19", col3: "17,75", col4: "18,45", col5: "125" },
];
export const tableData9_2 = [
  { col1: "25", col2: "7", col3: "5,7", col4: "6,1", col5: "1,380" },
  { col1: "35", col2: "7", col3: "6,7", col4: "7,1", col5: "986" },
  { col1: "50", col2: "7", col3: "7,85", col4: "8,35", col5: "720" },
  { col1: "54,6", col2: "7", col3: "9,2", col4: "9,6", col5: "630" },
  { col1: "70", col2: "7", col3: "9,45", col4: "9,95", col5: "493" },
  { col1: "95", col2: "7", col3: "11,1", col4: "11,7", col5: "363" },
  { col1: "120", col2: "19", col3: "12,2", col4: "12,9", col5: "288" },
  { col1: "150", col2: "19", col3: "13,9", col4: "14,5", col5: "236" },
  { col1: "185", col2: "19", col3: "15,75", col4: "16,15", col5: "188" },
  { col1: "240", col2: "19", col3: "17,75", col4: "18,45", col5: "145" },
];
export const tableData9_4 = [
  { col1: "16", col2: "100", col3: "-", col4: "-", col5: "1,5", col6: "-" },
  { col1: "25", col2: "130", col3: "-", col4: "-", col5: "2,3", col6: "-" },
  {
    col1: "35",
    col2: "160",
    col3: "200",
    col4: "220",
    col5: "3,2",
    col6: "3,0",
  },
  {
    col1: "50",
    col2: "195",
    col3: "245",
    col4: "270",
    col5: "4,6",
    col6: "4,3",
  },
  {
    col1: "70",
    col2: "240",
    col3: "310",
    col4: "340",
    col5: "6,5",
    col6: "6,0",
  },
  {
    col1: "95",
    col2: "300",
    col3: "370",
    col4: "400",
    col5: "8,8",
    col6: "8,2",
  },
  {
    col1: "120",
    col2: "340",
    col3: "430",
    col4: "460",
    col5: "10,9",
    col6: "10,3",
  },
  {
    col1: "150",
    col2: "380",
    col3: "485",
    col4: "520",
    col5: "13,2",
    col6: "12,9",
  },
  {
    col1: "185",
    col2: "436",
    col3: "560",
    col4: "600",
    col5: "16,5",
    col6: "15,9",
  },
  {
    col1: "240",
    col2: "515",
    col3: "600",
    col4: "670",
    col5: "22,0",
    col6: "20,6",
  },
];

export const tableData9_5 = [
  { col1: "1х16+1х25", col2: "15", col3: "135" },
  { col1: "3х16+1х25", col2: "22", col3: "270" },
  { col1: "3х25+1х35", col2: "26", col3: "390" },
  { col1: "3х35+1х50", col2: "30", col3: "530" },
  { col1: "3х50+1х50", col2: "32", col3: "685" },
  { col1: "3х50+1х70", col2: "35", col3: "740" },
  { col1: "3х70+1х70", col2: "37", col3: "930" },
  { col1: "3х70+1х95", col2: "41", col3: "990" },
  { col1: "3х95+1х70", col2: "41", col3: "1190" },
  { col1: "3х95+1х95", col2: "43", col3: "1255" },
  { col1: "3х120+1х95", col2: "46", col3: "1480" },
  { col1: "3х150+1х95", col2: "48", col3: "1715" },
  { col1: "3х185+1х95", col2: "52", col3: "2330" },
  { col1: "3х240+1х95", col2: "56", col3: "2895" },
  { col1: "3х16+1х25", col2: "24", col3: "308" },
  { col1: "3х16+1х54,6*", col2: "28", col3: "427" },
  { col1: "3х25+1х35", col2: "27", col3: "424" },
  { col1: "3х25+1х54,6*", col2: "30", col3: "512" },
  { col1: "3х35+1х50", col2: "31", col3: "571" },
  { col1: "3х35+1х54,6*", col2: "32", col3: "606" },
  { col1: "3х50+1х50", col2: "34", col3: "727" },
  { col1: "3х50+1х54,5*", col2: "35", col3: "762" },
  { col1: "3х50+1х70", col2: "36", col3: "798" },
  { col1: "3х70+1х54,6*", col2: "39", col3: "973" },
  { col1: "3х70+1х70", col2: "40", col3: "1010" },
  { col1: "3х70+1х95", col2: "41", col3: "1087" },
  { col1: "3х95+1х70", col2: "43", col3: "1240" },
  { col1: "3х95+1х95", col2: "45", col3: "1319" },
  { col1: "3х120+1х95", col2: "48", col3: "1553" },
  { col1: "3х150х1х95", col2: "50", col3: "1787" },
  { col1: "3х185+1х95", col2: "55", col3: "2403" },
  { col1: "3х240+1х95", col2: "60", col3: "2968" },
  { col1: "1х35", col2: "12", col3: "165" },
  { col1: "1х50", col2: "13", col3: "215" },
  { col1: "1х70", col2: "15", col3: "282" },
  { col1: "1х95", col2: "16", col3: "364" },
  { col1: "1х120", col2: "18", col3: "445" },
  { col1: "1х150", col2: "19", col3: "540" },
  { col1: "1х185", col2: "24", col3: "722" },
  { col1: "1х240", col2: "24", col3: "950" },
  { col1: "1х35", col2: "14", col3: "209" },
  { col1: "1х50", col2: "16", col3: "263" },
  { col1: "1х70", col2: "17", col3: "334" },
  { col1: "1х95", col2: "19", col3: "421" },
  { col1: "1х120", col2: "20", col3: "518" },
  { col1: "1х150", col2: "22", col3: "618" },
  { col1: "1х185", col2: "24", col3: "808" },
  { col1: "1х240", col2: "26", col3: "1045" },
  { col1: "2х16", col2: "15", col3: "139" },
  { col1: "4х16", col2: "18", col3: "278" },
  { col1: "2х25", col2: "17", col3: "196" },
  { col1: "4х25", col2: "21", col3: "392" },
];

export const tableData10_1 = [
  {
    col1: "1 - Инфраред хүлээн авагч",
    col2: "2 - T1 (Дээд тариф)",
    col3: "3 - Дээд хаалтын лац",
  },
  {
    col1: "4 - Дээд хаалт",
    col2: "5 - LED дэлгэц",
    col3: "6 - Пулс индикатор",
  },
  {
    col1: "7 - T3 (Дээд хязгааргүй үеийн тариф)",
    col2: "8 - T2 (Мөрний тариф)",
    col3: "9 - Инфраред гэрэл гаргагч",
  },
  {
    col1: "10 - Ээлжлэн харуулах товчлуур",
    col2: "11 - Терминалын тагны лац",
    col3: "12 - Терминалын хаалт",
  },
  { col1: "13 - Суурийн хаалт", col2: "14 - Өлгөх ховил", col3: "" },
];

export const tableData10_2 = [
  {
    col1: "Загвар",
    col2: "Нарийвчлал",
    col3: "Вольт(V)",
    col4: "Номинал гүйдэл",
  },
  { col1: "DDS26", col2: "1", col3: "230V", col4: "5(60)A" },
];

export const tableData10_3 = [
  {
    col1: "Давтамж: 50Hz",
    col2: "Дэлгэцийн тоо утга: 5 бүхэл тоо, 1 аравтын бутархай орон",
  },
  { col1: "Метрийн тогтмол: 1600 imp/kWh 50(60)A", col2: "Жин: 0,42кг" },
  {
    col1: "Цахилгааны хэрэглээ: Вольтын хэлхээ ≤ 0.5W 5VA Гүйдлийн хэлхээ ≤ 0.2 VA",
    col2: "Гаднах хэмжээ: 127мм * 102мм * 51мм",
  },
  { col1: "Эхлэх гүйдэл: 0.004%In", col2: "Ажиллах хугацаа: 10жил" },
];

export const tableData10_4 = [
  { col1: "0.05lb ≤ I < 0.1Ib", col2: "1", col3: "±1.5" },
  { col1: "0.1lb ≤ I ≤ Imax", col2: "1", col3: "±1.0" },
  {
    col1: "0.1lb ≤ I < 0.2Ib",
    col2: "0.5 хоцрогдол 0.8 түрүүлэх",
    col3: "±1.5 ±1.5",
  },
  {
    col1: "0.2lb ≤ I ≤ Imax",
    col2: "0.5 хоцрогдол 0.8 түрүүлэх",
    col3: "±1.0 ±1.0",
  },
  {
    col1: "Харилцагчийн тусгай хүсэлт 0.2lb ≤ I ≤ Ib",
    col2: "0.25 хоцрогдол 0.5 түрүүлэх",
    col3: "±3.5 ±2.5",
  },
];

export const tableData10_5 = [
  { col1: "1 - Суурь", col2: "2 - Хаалт", col3: "3 - Бүртгэгч" },
  {
    col1: "4 - Буцах энергийн индикатор",
    col2: "5 - Пулс индикатор",
    col3: "6 - Пулс гаралт",
  },
  {
    col1: "7 - Нүхтэй толгой болон нэмэх эрэг",
    col2: "8 - Терминалын хаалт",
    col3: "9 - Терминалын тагийг лацдах эрэг",
  },
];

export const tableData10_6 = [
  {
    col1: "Загвар",
    col2: "Нарийвчлал",
    col3: "Вольт(V)",
    col4: "Загвар",
    col5: "Номинал Гүйдэл (A)",
    col6: "Тоолуурын тогтмол (imp/kWh)",
  },
  {
    col1: "DTSD27 3 фаз 4 утастай",
    col2: "Астив - 1 Реактив - 2",
    col3: "3*230(400)",
    col4: "Шууд холболт",
    col5: "10(100)",
    col6: "800 800",
  },
];

export const tableData10_7 = [
  {
    col1: "Ачаалах гүйдэл",
    col2: "Цахилгааны өгөгдөл",
    col3: "Актив алдаа",
    col4: "Реактив алдаа",
  },
  { col1: "0.05lb ≤ I < 0.1Ib", col2: "1", col3: "±1.5", col4: "±2.5" },
  { col1: "0.1lb ≤ I ≤ Imax", col2: "1", col3: "±1.0", col4: "±2.0" },
  {
    col1: "0.1lb ≤ I < 0.2Ib",
    col2: "0.5 Индуктив 0.8 Индуктив",
    col3: "±1.5 ±1.5",
    col4: "±2.5 --",
  },
  {
    col1: "0.2lb ≤ I ≤ Imax",
    col2: "0.5 Индуктив 0.8 Индуктив",
    col3: "±1.0 ±1.0",
    col4: "±2.0 --",
  },
  {
    col1: "Харилцагчийн тусгай хүсэлт 0.2lb ≤ I ≤ Ib",
    col2: "0.25 Индуктив 0.8 Индуктив",
    col3: "±3.5 ±2.5",
    col4: "±2.5 --",
  },
];

export const tableData10_8 = [
  {
    col1: "1 - Дээд тагны лац",
    col2: "2 - Терминал тогтоох эрэг шураг",
    col3: "3 - Дээд хаалтын лац",
  },
  {
    col1: "4 - Дэлгэц",
    col2: "5 - Т1 тариф индикатор",
    col3: "6 - Т2 тариф индикатор",
  },
  {
    col1: "7 - Т3 тариф индикатор",
    col2: "8 - Буцах энергийн индикатор",
    col3: "9 - актив импулс индикатор",
  },
  {
    col1: "10 - Программийн товчлуур",
    col2: "11 - Реактив импулс индикатор",
    col3: "12 - Дэлгэц гүйлгэх товчлуур",
  },
  {
    col1: "13 - Инфраред холболт",
    col2: "14 - Терминалын тагны лац",
    col3: "15 - Терминал блок",
  },
  {
    col1: "16 - Терминалын хаалт",
    col2: "17 - Тоолуурын суурийн хавтас",
    col3: "18 - Дээд хаалт",
  },
  { col1: "19 - Өлгөх ховил", col2: "", col3: "" },
];

export const tableData10_9 = [
  { col1: "Алдаа / саатал", col2: "Хийх алхам" },
  {
    col1: "LED дэлгэцэнд 'EEP_Err' гэсэн бичиг гарах",
    col2: "Тоолуурын мэдээллийн ой санамжид гэмтэл гарсан ба үйлдвэрээс мэргэжлийн үйлчилгээ хийх шаардлагатай",
  },
  {
    col1: "Дэлгэцэнд ямар нэгэн дүрс нь харагдахгүй болон гэмтэлтэй харагдах, эсвэл цахилгааны LED анивчиж байгаа боловч дэлгэц дээр ямар нэгэн юм гарахгүй байх",
    col2: "LED дэлгэц нь хэвийн ажиллахгүй байгаа тул үйлдвэртэй холбогдож LED дэлгэцийг солиулах",
  },
  {
    col1: "LED дэлгэцэнд ямар нэгэн зүйл харагдахгүй, цахилгааны пулс индикатор нь анивчихгүй",
    col2: "Холболт зөв эсэхийг шалгах, вольт нь хэвийн ажиллагааны хүрээнд байгаа эсэхийг шалгах",
  },
  {
    col1: "LCD дэлгэц хэвийн ажиллаж, энергийн пулс индикатор нь ассан байвал",
    col2: "Холболт зөв эсэхийг шалгах, цахилгаан зарцуулалт нь харьцангуй бага, пулс нь арай удаанаар анивчина",
  },
  {
    col1: "LCD дэлгэц хэвийн ажиллаж, өөрчлөх (reversal) энергийн индикатор нь ассан байвал",
    col2: "Холболт зөв эсэхийг шалгах, хэрэглэгч нь цахилгааныг буцах чиглэлээр ашигласан",
  },
  {
    col1: "Тоолуурын мэдээллийг холбооны хэрэгслээр уншихад алдаа гарсан",
    col2: "Холбооны хэрэгсэлийн үзүүр нь холболтын цонхноос хэт хол байх, кабель нь сайн холбогдсон эсэхийг шалгах",
  },
];

export const tableData10_10 = [
  {
    col1: "Загвар",
    col2: "Нарийвчлал",
    col3: "Вольт(V)",
    col4: "Загвар",
    col5: "Номинал Гүйдэл (A)",
    col6: "Тоолуурын тогтмол (imp/kWh)",
  },
  {
    col1: "DTSD27 3 фаз 4 утастай",
    col2: "Актив - 1 Реактив - 2",
    col3: "3*230(400)",
    col4: "CТ холболт",
    col5: "1.5(5)",
    col6: "3200 3200",
  },
];

export const tableData10_11 = [
  {
    col1: "Ачаалах гүйдэл",
    col2: "Цахилгааны өгөгдөл",
    col3: "Актив алдаа",
    col4: "Реактив алдаа",
  },
  { col1: "0.05lb ≤ I < 0.1Ib", col2: "1", col3: "±1.5", col4: "±2.5" },
  { col1: "0.1lb ≤ I ≤ Imax", col2: "1", col3: "±1.0", col4: "±2.0" },
  {
    col1: "0.1lb ≤ I < 0.2Ib",
    col2: "0.5 Индуктив 0.8 Индуктив",
    col3: "±1.5 ±1.5",
    col4: "±2.5 --",
  },
  {
    col1: "0.2lb ≤ I ≤ Imax",
    col2: "0.5 Индуктив 0.8 Индуктив",
    col3: "±1.0 ±1.0",
    col4: "±2.0 --",
  },
  {
    col1: "Харилцагчийн тусгай хүсэлт 0.2lb ≤ I ≤ Ib",
    col2: "0.25 Индуктив 0.8 Индуктив",
    col3: "±3.5 ±2.5",
    col4: "±2.5 --",
  },
];

export const tableData10_12 = [
  {
    col1: "1 - Дээд тагны лац",
    col2: "2 - Терминал тогтоох эрэг шураг",
    col3: "3 - Дээд хаалтын лац",
  },
  {
    col1: "4 - Дэлгэц",
    col2: "5 - Т1 тариф индикатор",
    col3: "6 - Т2 тариф индикатор",
  },
  {
    col1: "7 - Т3 тариф индикатор",
    col2: "8 - Буцах энергийн индикатор",
    col3: "9 - актив импулс индикатор",
  },
  {
    col1: "10 - Программийн товчлуур",
    col2: "11 - Реактив импулс индикатор",
    col3: "12 - Дэлгэц гүйлгэх товчлуур",
  },
  {
    col1: "13 - Инфраред холболт",
    col2: "14 - Терминалын тагны лац",
    col3: "15 - Терминал блок",
  },
  {
    col1: "16 - Терминалын хаалт",
    col2: "17 - Тоолуурын суурийн хавтас",
    col3: "18 - Дээд хаалт",
  },
  { col1: "19 - Өлгөх ховил", col2: "", col3: "" },
];

export const tableData10_13 = [
  { col1: "Алдаа / саатал", col2: "Хийх алхам" },
  {
    col1: "LED дэлгэцэнд 'EEP_Err' гэсэн бичиг гарах",
    col2: "Тоолуурын мэдээллийн ой санамжид гэмтэл гарсан ба үйлдвэрээс мэргэжлийн үйлчилгээ хийх шаардлагатай",
  },
  {
    col1: "Дэлгэцэнд ямар нэгэн дүрс нь харагдахгүй болон гэмтэлтэй харагдах, эсвэл цахилгааны LED анивчиж байгаа боловч дэлгэц дээр ямар нэгэн юм гарахгүй байх",
    col2: "LED дэлгэц нь хэвийн ажиллахгүй байгаа тул үйлдвэртэй холбогдож LED дэлгэцийг солиулах",
  },
  {
    col1: "LED дэлгэцэнд ямар нэгэн зүйл харагдахгүй, цахилгааны пулс индикатор нь анивчихгүй",
    col2: "Холболт зөв эсэхийг шалгах, вольт нь хэвийн ажиллагааны хүрээнд байгаа эсэхийг шалгах",
  },
  {
    col1: "LCD дэлгэц хэвийн ажиллаж, энергийн пулс индикатор нь ассан байвал",
    col2: "Холболт зөв эсэхийг шалгах, цахилгаан зарцуулалт нь харьцангуй бага, пулс нь арай удаанаар анивчина",
  },
  {
    col1: "LCD дэлгэц хэвийн ажиллаж, өөрчлөх (reversal) энергийн индикатор нь ассан байвал",
    col2: "Холболт зөв эсэхийг шалгах, хэрэглэгч нь цахилгааныг буцах чиглэлээр ашигласан",
  },
  {
    col1: "Тоолуурын мэдээллийг холбооны хэрэгслээр уншихад алдаа гарсан",
    col2: "Холбооны хэрэгсэлийн үзүүр нь холболтын цонхноос хэт хол байх, кабель нь сайн холбогдсон эсэхийг шалгах",
  },
];

export const tableData10_14 = [
  {
    col1: "Загвар",
    col2: "Нарийвчлал",
    col3: "Вольт(V)",
    col4: "Номинал Гүйдэл (A)",
    col5: "Тоолуурын тогтмол (imp/kWh)",
  },
  {
    col1: "DTSD27",
    col2: "1",
    col3: "3*230(400)",
    col4: "1.5(6) 10(100)",
    col5: "3200 200",
  },
];

export const tableData10_15 = [
  {
    col1: "0.05lb ≤ I < 0.1Ib",
    col2: "0.02In ≤ I < 0.05In",
    col3: "1",
    col4: "±1.5",
  },
  {
    col1: "0.1lb ≤ I ≤ 0.1Imax",
    col2: "0.05In ≤ I < Imax",
    col3: "1",
    col4: "±1.0",
  },
  {
    col1: "0.1lb ≤ I < 0.2Ib",
    col2: "0.05In ≤ I < 0.1In",
    col3: "0.5 Индуктив 0.8 Капаситив",
    col4: "±1.5 ±1.5",
  },
  {
    col1: "0.2lb ≤ I ≤ Imax",
    col2: "0.1In ≤ I ≤ Imax",
    col3: "0.5 Индуктив 0.8 Капаситив",
    col4: "±1.0 ±1.0",
  },
  {
    col1: "Харилцагчийн тусгай хүсэлт 0.2lb ≤ I ≤ Ib",
    col2: "0.1In ≤ I ≤ In",
    col3: "0.25 Индуктив 0.5 Капаситив",
    col4: "±3.5 ±3.5",
  },
];

export const tableData10_16 = [
  { col1: "1 - Дээд тагны лац", col2: "8 - тагны лац" },
  { col1: "2 - Нэршилтэй хавтас", col2: "9 - Шурупны ховил" },
  { col1: "3 - Тоологч", col2: "10 - блокын суурь" },
  {
    col1: "4 - А-фазын алдагдал мэдээлэгч LED",
    col2: "11 - шугамын терминал блокын лац",
  },
  { col1: "5 - B-фазын алдагдал мэдээлэгч LED", col2: "12 - Терминалын таг" },
  { col1: "6 - C-фазын алдагдал мэдээлэгч LED", col2: "13 - Дээд таг" },
  { col1: "7 - Пулс мэдээлэгч LED", col2: "14 - Өлгүүр" },
];

export const tableData10_17 = [
  {
    col1: "Загвар",
    col2: "Нарийвчлал",
    col3: "Вольт(V)",
    col4: "Загвар",
    col5: "Номинал Гүйдэл (A)",
    col6: "Тоолуурын тогтмол (imp/kWh)",
  },
  {
    col1: "DTSD27 3 фаз 4 утастай",
    col2: "Астив - 1 Реактив - 2",
    col3: "3*230(400)",
    col4: "Шууд холболт",
    col5: "10(100)",
    col6: "3200 200",
  },
];

export const tableData10_18 = [
  {
    col1: "0.05lb ≤ I < 0.1Ib",
    col2: "0.02In ≤ I < 0.05In",
    col3: "1",
    col4: "±1.5",
  },
  {
    col1: "0.1lb ≤ I ≤ 0.1Imax",
    col2: "0.05In ≤ I < Imax",
    col3: "1",
    col4: "±1.0",
  },
  {
    col1: "0.1lb ≤ I < 0.2Ib",
    col2: "0.05In ≤ I < 0.1In",
    col3: "0.5 Индуктив 0.8 Индуктив",
    col4: "±1.5 ±1.5",
  },
  {
    col1: "0.2lb ≤ I ≤ Imax",
    col2: "0.1In ≤ I ≤ Imax",
    col3: "0.5 Индуктив 0.8 Индуктив",
    col4: "±1.0 ±1.0",
  },
  {
    col1: "Харилцагчийн тусгай хүсэлт 0.2lb ≤ I ≤ Ib",
    col2: "0.1In ≤ I ≤ In",
    col3: "0.25 Индуктив 0.8 Индуктив",
    col4: "±3.5 ±2.5",
  },
];

export const tableData10_19 = [
  { col1: "1 - Дээд тагны лац", col2: "8 - тагны лац" },
  { col1: "2 - Нэршилтэй хавтас", col2: "9 - Шурупны ховил" },
  { col1: "3 - Тоологч", col2: "10 - блокын суурь" },
  {
    col1: "4 - А-фазын алдагдал мэдээлэгч LED",
    col2: "11 - шугамын терминал блокын лац",
  },
  { col1: "5 - B-фазын алдагдал мэдээлэгч LED", col2: "12 - Терминалын таг" },
  { col1: "6 - C-фазын алдагдал мэдээлэгч LED", col2: "13 - Дээд таг" },
  { col1: "7 - Пулс мэдээлэгч LED", col2: "14 - Өлгүүр" },
];

export const tableData11 = [
  { col1: "Тоолуурын нэр", col2: "Техникийн үзүүлэлт" },
  { col1: "DN15 - Хүйтэн усны тоолуур", col2: "ETK 1.5m3/h, length 110mm" },
  { col1: "DN15 - Халуун усны тоолуур", col2: "ETW 1.5m3/h, length 110mm" },
  { col1: "DN15 - Хүйтэн усны тоолуур", col2: "ETKD 1.5m3/h, length 110mm" },
  { col1: "DN15 - Халуун усны тоолуур", col2: "ETWD 1.5m3/h, length 110mm" },
  {
    col1: "DN20 - Хүйтэн усны тоолуур",
    col2: "ETK MFO2 2.5m3/h, length 130mm",
  },
  {
    col1: "DN20 - Халуун усны тоолуур",
    col2: "ETW MFO2 2.5m3/h, length 130mm",
  },
  { col1: "DN25 - Хүйтэн усны тоолуур", col2: "MTK 3.5m3/h, length 260mm" },
  { col1: "DN25 - Халуун усны тоолуур", col2: "MTW 3.5m3/h, length 260mm" },
  { col1: "DN32 - Хүйтэн усны тоолуур", col2: "MTK 6m3/h, length 260mm" },
  { col1: "DN32 - Халуун усны тоолуур", col2: "MTW 6m3/h, length 260mm" },
  { col1: "DN40 - Хүйтэн усны тоолуур", col2: "MTK 15m3/h, length 200mm" },
  { col1: "DN50 - Хүйтэн усны тоолуур", col2: "WPH 15m3/h, length 200mm" },
  { col1: "DN50 - Халуун усны тоолуур", col2: "WPH 15m3/h, length 200mm" },
  { col1: "DN65 - Хүйтэн усны тоолуур", col2: "WPH 25m3/h, length 200mm" },
  { col1: "DN80 - Хүйтэн усны тоолуур", col2: "WPH 40m3/h, length 225mm" },
  { col1: "DN100 - Хүйтэн усны тоолуур", col2: "WPH 60m3/h, length 250mm" },
  { col1: "DN125 - Хүйтэн усны тоолуур", col2: "WPH 100m3/h, length 250mm" },
  { col1: "DN150 - Хүйтэн усны тоолуур", col2: "WPH 150m3/h, length 300mm" },
  { col1: "DN200 - Хүйтэн усны тоолуур", col2: "WPH 250m3/h, length 350mm" },
];

export const tableData12 = [
  { col1: "Номинал гүйдэл, А", col2: "0,66" },
  { col1: "Анхдагч хэлхээний гүйдэл, кВ", col2: "5-5000" },
  { col1: "Хоёрдогч хэлхээний гүйдэл, А", col2: "5" },
  { col1: "Давтамж", col2: "50 Гц" },
  { col1: "Нарийвчлал", col2: "0.5S" },
  { col1: "Дундаж үйлчилгээний хугацаа", col2: "25 жил" },
  { col1: "Шалгалт тохируулгын интервал", col2: "8 жил" },
];

export const tableData13 = [
  { col1: "Нэрийн хэсэг", col2: "Тайлбар" },
  { col1: "ВНА", col2: "Цуврал" },
  { col1: "10", col2: "Хэвийн хүчдэл, кВ" },
  { col1: "Х1", col2: "Хэвийн гүйдэл, А" },
  { col1: "Х2", col2: "Приводын байршил: Л – зүүн гартай П - баруун гар" },
  {
    col1: "Х3з",
    col2: "Газардуулга хутга хийх боломжтой: IIIз – хоёр талаас газардуулгын утагтай тэмдэглэгээ байхгүй үед Х3 – нугасны тал дээр газардуулга хутга",
  },
  {
    col1: "Х4",
    col2: "ПТ1.2, ПТ1.3 патрон сурулулха контактууд; Суурилагдсан холбоо байхгүй тохиолдолд тэмдэглэгээ байхгүй",
  },
  { col1: "ИХ5​", col2: "Бүрхүүл: И1 – цайрдсан И2- будсан" },
  { col1: "У2", col2: "Цаг уурын өөрчлөлт, байршуулах зэрэг" },
  { col1: "КЭАЗ", col2: "Барааны тэмдэг" },
];
