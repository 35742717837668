import React from "react";
import { tableData5_1, tableData5_2 } from "./tableDatas";

function Product6() {
  return (
    <div className="flex flex-col text-sm">
      <span className="mt-3 font-semibold">
        ПВ-3 кабель нь поливнилхлоридийн пластикат тусгаарлагчтай, олон
        ширхэгтэй зэс судалнуудаас бүрдэх, маш уян хатан чанартай электрон тоног
        төхөөрөмжийн кабель, ГОСТ 6323-79
      </span>
      <img src="/product-image7.gif" className="" />
      <span className="font-semibold mt-3">
        ПВ-4 кабель нь поливнилхлоридийн пластикат тусгаарлагчтай, олон
        ширхэгтэй зэс судалнуудаас бүрдэх, маш уян хатан чанартай электрон тоног
        төхөөрөмжийн кабель, ГОСТ 6323-79
      </span>
      <img src="/product-image8.gif" className="" />
      <span className="font-semibold mt-3">
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol className="mt-3">
        <span className="font-semibold">1. СУДАЛ:</span>

        <li>
          — ПВ-3 судал: зэс, эрчилсэн кабель, ГОСТ 22483 ( I зэрэглэл - 0.5 -аас
          1.5 мм2 хөндлөн огтлол, IV зэрэглэл 2.5 - аас 4 мм2, III зэрэглэл 6 -
          аас 95 мм2). <br />— ПВ-4 судал: зэс, эрчилсэн кабель, ГОСТ 22483 ( V
          зэрэглэл - 0.5 -аас 0.75 мм2 хөндлөн огтлол, IV-V зэрэглэл 1 - аас 1.5
          мм2, V зэрэглэл 2.5 - аас 4 мм2, A эсвэл V зэрэглэл 6 ба 10 мм2,)
        </li>
        <span className="font-semibold">2. СУДАЛ:</span>
        <li>
          поливнилхлоридийн пластикат хольцтой, олон өнгийн. Газардуулга-судалын
          бүрхүүл ногоон-шар өнгөтэй.
        </li>
      </ol>
      <span className="mt-3 font-semibold">ХЭРЭГЛЭЭ</span>
      <span>
        {" "}
        450 В хүртэлх вольт-той (450/750 В хүртэлх сүлжээ), 400 Hz давтамжтай
        эсвэл 1 кВ хүртэлх DC вольттой электрон тоног төхөөрөмж, гэрэлтүүлэг
        болон цахилгааны сүлжээнд, электрон багаж хэрэгсэл суурилуулалт, машин
        механизм.{" "}
      </span>
      <span> ПВ-З кабель нугалах электроны сүлжээнд</span>
      <span> ПВ-4 кабель ихэвчлэн нугалах электроны сүлжээнд</span>
      <span className="mt-3 font-semibold">ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Тэсвэрлэх цаг агаарын
        нөхцөл............................................................................“ХЛ”,
        ГОСТ 15150-69 (категор -2)
      </span>
      <span>
        {" "}
        Ажиллах температур
        .......................................................................................
        -500С -аас +700С хүртэл{" "}
      </span>
      <span>
        {" "}
        Агаарын чийглэг тэсвэрлэх чадвар (+350°С -д)
        .................................................. 100%{" "}
      </span>
      <span>
        {" "}
        Халуун орны зориулалттай кабель нь хөгц, мөөгөнцөрт тэсвэртэй
      </span>
      <span>
        {" "}
        Механик шок, шугаман хурдасгал, нугалах, доргилт, чичиргээ болон дуу
        авианы шуугианд тэсвэртэй.
      </span>
      <span> Гал түгээдгүй </span>
      <span>
        {" "}
        Кабелийг суурилуулалтын температур
        ...............................................................-150С
        -аас багагүй{" "}
      </span>
      <span>
        {" "}
        Нугалах хамгийн бага радиус
        .............................................................................
        5 гаднах диамет{" "}
      </span>
      <span>
        {" "}
        Зөвшөөрөгдсөн үргэлжилсэн температур (судал дээр)
        .........................................+700С -ээс ихгүй{" "}
      </span>
      <span>
        {" "}
        Кабелийн уртын
        хэмжээ.....................................................................................100
        м -ээс багагүй
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ПВ-3{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData5_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ПВ-4{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData5_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product6;
