import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";

class Distributor extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Дистрибютерийн худалдаа" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"ДИСТРИБЮТЕРИЙН ХУДАЛДАА"} />
        {/* End Breadcrump Area */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center text-sm">
                <div className="about-inner inner">
                  <div className="section-title">
                    <p className="">
                      {" "}
                      “Энержи Тех Прогресс” ХХК нь эрчим хүчний тоног төхөөрөмж,
                      бүх төрлийн кабель утас, муфт, усны болон цахилгааны
                      тоолуур, өндөр хүчдэлийн трансформатор, цахилгааны агаарын
                      шугамын тоноглол, автомат, салгуурууд болон бусад сэлбэг
                      материалуудыг АНУ, ОХУ, ХБНГУ, БНСУ, ЯПОН, БНХАУ, ФРАНЦ
                      зэрэг улс орнуудын салбартаа тэргүүлэх үйлдвэрлэгчид болох
                      Tyco Electronics Ltd (TE Connectivity), Schneider
                      Electric, Сибкабель, Томскабель, Lappcable, Zenner, КЭАЗ,
                      Shenzhen Star Instrument гэх мэт үйлдвэрлэгчдээс нь албан
                      ёсны дистрибютерийн эрхтэйгээр импортлон эрчим хүчний
                      төрөлжсөн худалдаа явуулж байна.
                    </p>
                    <p>
                      {" "}
                      Манай компани нь 2014 оноос Schneider Electric компанийн
                      албан ёсны дистрибютерийн эрхтэй болсон ба 0,4кВ – 35кВ
                      –ын хүчдэлийн хүрээнд эрчим хүчний бүхий л тоноглолыг
                      нийлүүлж, PRISMA болон бусад цахилгааны самбарын угсралтын
                      ажлыг хийж гүйцэтгэдэг.{" "}
                    </p>
                  </div>
                </div>

                <img src="/dist1.jpg" className="mx-auto mt-5" />
                <img src="/dist2.jpg" className="mx-auto mt-5" />
                <img src="/dist3.jpg" className="mx-auto mt-5" />
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Distributor;
