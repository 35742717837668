import React from "react";

function Product39() {
  return (
    <div className="flex flex-col text-sm justify-center items-center gap-3">
      <div className="flex flex-col md:flex-row gap-4">
        <img src="/Untitled7.jpg" />
        <div className="flex flex-col gap-3">
          <span>
            {" "}
            ДЭД СТАНЦ, ЩО70, КСО, КТПН, ЕРӨНХИЙ ЩИТ, АВР-ТАЙ ЩИТ, ДАВХАРЫН ЩИТ,
            SCHNEIDER PRISMA IPM
          </span>
          <span>
            {" "}
            Манай компани нь төрөл бүрийн дэд станц, Щ070, КСО, КТПН, ерөнхий
            щит, АВР-тай щит, давхарын шит угсархаас гадна Schneider Electric
            компаний PRISMA iPM system L болон system M загварын самбарыг зураг
            төслийн дагуу угсарч, суурилуулж өгч байна. Эдгээр самбарууд нь
            хананд өлгөгддөг, суурин болон ерөнхий хуваарилах самбарууд гэх мэт
            олон төрөл байгаа бөгөөд олон улсын чанарын шаардлагыг бүрэн
            хангасан.
          </span>
          <div className="flex flex-col">
            <span> Стандартууд : </span>
            <span> IEC61439-1 (2009-01)</span>
            <span> IEC61439-2 (2009-01)</span>
          </div>
          <div className="flex flex-col">
            <span> Хамгаалалтын зэргүүд: : </span>
            <span> IP30</span>
            <span> IP31</span>
            <span>IP43</span>
            <span>IP54</span>
          </div>
        </div>
      </div>
      <img src="/Untitled8.jpg" className="max-w-[538px]" />
      <img src="/Untitled9.jpg" className="max-w-[538px]" />
    </div>
  );
}

export default Product39;
