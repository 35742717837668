import React from "react";

function Product28() {
  return (
    <div>
      <img src="/Untitled29.jpg" />
      <img src="/Untitled30.gif" />
    </div>
  );
}

export default Product28;
