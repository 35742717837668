import React from "react";

function Product3() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/product-image3.gif" className="mt-3" />
      <span className="font-semibold mt-3">
        ВВГ (0.66 кВ, 1.0 кВ, ГОСТ 16442-80 стандарт-д нийцсэн)
      </span>
      <span className="">
        Зэс дамжуулагчтай цахилгаан хүчний кабель. Поливнилхлоридийн пластикат
        тусгаарлагч болон бүрхэвчтэй, амархан шатдаггүй
      </span>
      <span className="font-semibold mt-3"> ВВГнг ВВГнг-ХЛ, АВВГнг-ХЛ</span>
      <span>Хүйтэнд тэсвэртэй цахилгаан хүчний кабель.</span>
      <span className="font-semibold mt-3">
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>1. СУДАЛ – зэс, дан эсвэл олон судалтай, ГОСТ 22483 стандарт.</li>
        <li>
          2. ТУСГААРЛАГЧ – Поливнилхлоридийн пластикат (PVC). Дотоод судал
          кабель нь өөр өнгөөр ялгарсан. Тэг судалын бүрхүүл цэнхэр, газардуулга
          судалын бүрхүүл ногоон-шар өнгөтэй.
        </li>
        <li>
          3. СУДАЛ ЭРЧЛЭЭ – 2, 3, 4, 5-аар эрчилсэн тусгаарлагдсан
          дамжуулагчтай. -Хоёр дамжуулагчтай кабелийн огтлолын хэмжээ нь адил.
          Гурав, -дөрөв, -таван дамжуулагчтай кабелийн огтлолын хэмжээ адил
          эсвэл нэг дамжуулагч (тэг-судал эсвэл газардуулга-судал) нь жижиг
          огтлолтой.
        </li>
        <li>
          4. БҮРХЭВЧ – поливнилхлоридийн пластикат хольцтой амархан шатдаггүй
          материалаас бүрдсэн.
        </li>
      </ol>
      <span className="font-semibold mt-3">ХЭРЭГЛЭЭ:</span>
      <span>
        {" "}
        Цахилгаан хүчийг дамжуулах, түгээх (AC вольт - 0.66 кВ, 1.0 кВ, 50 Hz)
      </span>
      <span>
        {" "}
        Халуун болон чийгтэй газарт суурилуулахдаа тусгай кабелийн суваг, блокыг
        кабелийн гадуур хэрэглэвэл зохистой. Газар доор суурилуулхад тохиромжгүй
        (суваг шуудуу).
      </span>
      <span> ВВГ кабель дангаараа шатдаггүй. </span>
      <span> ВВГнг кабель бөөнөөрөө шатдаггүй.</span>
      <span className="font-semibold mt-3"> ОКП КОД:</span>
      <ol>
        <li> 35 212211 — ВВГ кабель 0.66 кВ</li>
        <li> 35 3371 26 — ВВГ кабель 1.0 кВ</li>
        <li> 35 3371 27 — ВВГ 1.0 кВ (дамжуулагч нь дөрвөлжин хэлбэртэй)</li>
        <li> 35 2122 4500 — ВВГнг кабель 0.66 кВ</li>
        <li> 35 3371 3600 — ВВГнг кабель 1.0 кВ</li>
      </ol>
      <span className="font-semibold mt-3">ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Тэсвэрлэх цаг агаарын
        нөхцөл...........................................................................
        УХЛ (хүйтэнд тэсвэртэй), Т (халуунд тэсвэртэй), ГОСТ 15150-69 (категор
        -1, -5)
      </span>
      <span>
        {" "}
        Ажиллах
        температур.......................................................................................
        -50°С -аас +50°С хүртэл{" "}
      </span>
      <span>
        {" "}
        Агаарын чийглэг тэсвэрлэх чадвар (+35°С-аас ихгүй нөхцөлд)
        ............................ 98% хүртэл{" "}
      </span>
      <span>
        Кабелийг урьдчилан халаахгүйгээр суурилуулалтын температур
        ........................ - 15°С -аас багагүй
      </span>
      <span> Нугалах хамгийн бага радиус: </span>
      <span>
        {" "}
        ВВГ кабелийн дан судал
        ..................................................................................
        10 гаднах диаметр
      </span>
      <span>
        {" "}
        ВВГнг кабелийн дан судал
        ...............................................................................
        15 гаднах диаметр
      </span>
      <span>
        {" "}
        Эрчилсэн кабель
        ............................................................................................
        7.5 гаднах диаметр
      </span>
      <span>
        {" "}
        Давтамж
        ........................................................................................................
        50 Hz
      </span>
      <span className="mt-3"> 50 Hz (AC вольт) давтамжид шалгагдсан: </span>
      <span className="">
        {" "}
        0.66 кВ
        вольт..................................................................................................
        3 кВ
      </span>
      <span>
        {" "}
        1.0 кВ
        вольт....................................................................................................
        3.5 кВ
      </span>
      <span>
        {" "}
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн
        температур........................... +70°С
      </span>
      <span className="mt-3">
        {" "}
        Кабелийн урт, төв дамжуулагчийн огтлолын хэмжээ:
      </span>
      <span>
        {" "}
        1.5 -аас 16 мм2
        хүртэл......................................................................................450
        м
      </span>
      <span>
        {" "}
        25 -аас 70 мм2
        хүртэл.......................................................................................300
        м
      </span>
      <span>
        {" "}
        95 мм2 болон түүнээс
        дээш...............................................................................200
        м
      </span>
    </div>
  );
}

export default Product3;
