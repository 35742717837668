import React from "react";

function Product33() {
  return (
    <div className="flex flex-col">
      <img src="/Untitled21.jpg" />
      <img src="/Untitled22.jpg" />
    </div>
  );
}

export default Product33;
