import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiCheck } from "react-icons/fi";

class TabsOne extends Component {
  render() {
    let tab1 = "Бидний тухай",
      tab2 = "Бидний Чанар",
      tab3 = "Бидний Зорилго";
    const { tabStyle } = this.props;

    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-[17px]">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content sm:min-h-[250px]">
                      <p>
                        “ИХ-ЭРЧ” ХХК нь 1998 онд байгуулагдсан бөгөөд цахилгааны
                        кабель утаснуудыг импортоор оруулж дотоодын зах зээлийн
                        хэрэгцээг ханган ажиллаж байна. Өнөөдрийн байдлаар манай
                        компани нь бараа бүтээгдэхүүнээ ОХУ-ын “Сибкабель”,
                        “Томсккабель”, “Иркутсккабель” болон бусад үйлдвэрүүдээс
                        шууд татан авчирч үйлчлүүлэгч, харилцагчиддаа нийлүүлж
                        байна.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content sm:min-h-[250px]">
                      <p>
                        Манай компанийн нийлүүлж буй бараа бүтээгдэхүүний чанар
                        нь олон улсын чанарын ISO 9001-2002, ISO 9001:2000
                        баталгааг хангасан бүтээгдэхүүнүүд байдаг.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content sm:min-h-[250px]">
                      <p>
                        ЭРХЭМ ЗОРИЛГО: Эрчим хүчний тоног төхөөрөмжийн худалдаа
                        болон барилга угсралтын чиглэлээр салбартаа тэргүүлэгч
                        байж, улс эх орныхоо хөгжил дэвшил, хувь хүний хөгжилд
                        бодитой хувь нэмэр оруулах
                      </p>
                      <p>
                        5 ЖИЛИЙН ЗОРИЛГО: Эрчим хүчний салбартаа тэргүүлэгч
                        байгууллагуудын жишигийг судлан, эн тэнцүүхэн
                        өрсөлдөхүйц үйлчилгээ, бүтээгдэхүүнийг хэрэглэгчдэд
                        хүргэн олон нийтэд компаний нэр хүндийг өсгөх
                      </p>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}
export default TabsOne;
