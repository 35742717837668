import React from "react";

function Product32() {
  return (
    <div>
      <img src="/Untitled27.jpg" />
    </div>
  );
}

export default Product32;
