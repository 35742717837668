import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

import logoLight from "../../assets/images/logo/logo-all-dark.png";
const logoUrl = <img src={logoLight} alt="Digital Agency" />;

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--6">
          {/* Start Footer Area  */}
          <div className="footer-wrapper ptb--70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="ft-text">
                    <div className="logo">
                      <Link to="/">{logoUrl}</Link>
                    </div>
                    <p>© 2024 Зохиогчийн эрхийг "Их Эрч" ХХК</p>
                  </div>
                </div>

                {/* Start Single Widget  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--40">
                  <div className="footer-link">
                    <h4>Байршил</h4>
                    <ul className="ft-link">
                      <li>
                        <a>
                          СХД-17, Москвагийн гудамж-49, Энхтайвны өргөн чөлөө
                          1-р хороолол, Москва-49 байр, 2-р давхар Улаанбаатар
                          хот, Монгол улс
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    <h4>Холбоо Барих</h4>
                    <ul className="ft-link">
                      <li>
                        И-мэйл: <a>info@ikherch.mn</a>
                      </li>

                      <li>
                        Холбогдох утасны дугаар:
                        <a>
                          +976-70008383, +976-77078383, 9666-9420, 9666-9400,
                          9865-9400
                        </a>
                      </li>
                    </ul>

                    <div className="social-share-inner mt--20">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a href={`${val.link}`}>{val.Social}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End Single Widget  */}
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
