import React from "react";

function Product27() {
  return (
    <div>
      <img src="/Untitled31.jpg" />
      <img src="/Untitled32.jpeg" />
      <img src="/Untitled33.jpg" />
    </div>
  );
}

export default Product27;
