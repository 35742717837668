import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Footer from "../component/footer/Footer";
import HeaderFive from "../component/header/HeaderFive";

class Manifactor extends Component {
  render() {
    let description =
      " Манай компани нь Schneider Electric компаний PRISMA iPM system L болон system M загварын самбарыг зураг төслийн дагуу угсарч, суурилуулж өгч байна. Эдгээр самбарууд нь хананд өлгөгддөг, суурин болон ерөнхий хуваарилах самбарууд гэх мэт олон төрөл байгаа бөгөөд олон улсын чанарын шаардлагыг бүрэн хангасан.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Үйлдвэрлэл" />

        <HeaderFive
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"ҮЙЛДВЭРЛЭЛ"} />
        {/* End Breadcrump Area */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center text-sm">
                <div className="col-lg-4 col-md-12">
                  <div className="thumbnail">
                    <img
                      className="max-w-[400px]"
                      src="/manifactor.jpg"
                      alt="Manifactor Images"
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <span className="font-semibold text-2xl">
                        {" "}
                        PRISMA IPM
                      </span>
                      <p className="">{description}</p>
                      <div className="flex flex-col text-lg">
                        <span>
                          {" "}
                          Стандартууд: IEC61439-1 (2009-01), IEC61439-2
                          (2009-01)
                        </span>
                        <span>
                          {" "}
                          Хамгаалалтын зэргүүд: IP30, IP31, IP43, IP54
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  className="mx-auto mt-5"
                  src="/manifactor2.jpg"
                  alt="Manifactor Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Manifactor;
