import React from "react";
import { tableData3 } from "./tableDatas";

function Product4() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/product-image4.gif" />
      <span className="font-semibold mt-3"> КГ (0,66 кВ, ТУ 16.К73.05-93)</span>
      <ul className="mt-3">
        <li> уян зэс кабель.</li>
        <li> дамжуулагч нь олон тооны жижиг зэс салаанаас бүрднэ</li>
        <li> резин тусгаарлагч болон резин бүрхэвч</li>
      </ul>
      <span className="font-semibold mt-3">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol className="mt-3">
        <li>
          1. СУДАЛ —дугуй хэлбэртэй олон жижиг зэс салаанаас бүрднэ. ГОСТ 22483
          (V) стандарт. Халуун орны уур амьсгалд зориулалттай хийгдсэн КГ-T
          кабельний судал нь тугалганы хольцтой ( 40 % -өөс багагүй) бүрхүүлээр
          бүрсэн байдаг.
        </li>
        <li>2. ТУСГААРЛАГЧ ҮЕ ДАВХАРГА — полимер лент</li>
        <li>
          3. ТУСГААРЛАГЧ —резин. Кабелийн дотоод судал нь өөр өөр өнгөөр
          ялгарсан. Тэг судалын бүрхүүл цэнхэр өнгөтэй. Хэрвээ тэг-судал байхгүй
          бол цэнхэр өнгө нь газардуулга-судлаас өөр судалд ашиглагдаж болно.
          Газардуулга судалын бүрхүүл ногоон-шар өнгөтэй ба "0" гэсэн
          тоонтэмдэгтэй.
        </li>
        <li>
          4. СУДАЛ ЭРЧЛЭЭ — судалнууд нь 16 -аас илүүгүй диаметртэй эрчлэгдсэн
          байдаг.
        </li>
        <li>
          5. БҮРХЭВЧ — энэхүү бүрхэвчийг хүйтэнд тэсвэртэй болгохын тулд хүйтэнд
          тэсвэртэй резинээр хийсэн. Халуун оронд хэрэглэх кабелийн бүрхэвчийг
          анти-септик материалаар хийдэг.
        </li>
      </ol>
      <span className="font-bold mt-3"> ХЭРЭГЛЭЭ</span>
      <span className="">
        {" "}
        660 AV цахилгааны сүлжээнд (400 Hz хүртэл давтамжтай) эсвэл 1000 CV.{" "}
      </span>
      <span>
        {" "}
        Халуунд тэсвэртэй кабель "Т", хүйтэнд тэсвэртэй кабель “ХЛ",
        тэг-судалтай кабель “n” тэмдэгтэй. 2, 3 төв судал, нэг эсвэл хоёр туслах
        судалтай-тай кабель "v" тэмдэгтэй.
      </span>
      <span className="font-semibold mt-3"> ОКП КОД:</span>
      <ol>
        <li> 35 4441 01 – КГ кабель</li>
        <li> 35 4441 23 – КГ-T кабель</li>
        <li> 35 4441 17 – КГ-ХЛ кабель</li>
      </ol>
      <span className="font-bold mt-3"> ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span className="">
        {" "}
        Тэсвэрлэх цаг агаарын
        нөхцөл..........................................................................
        U, ХЛ, T, ГОСТ 15150-69 (категор -1, -2, -3).{" "}
      </span>
      <span className="mt-3"> Орчны температурын өөрчлөлтөд тэсвэртэй: </span>
      <span>
        {" "}
        КГ
        .................................................................................................................
        -400С -аас +500С хүртэл{" "}
      </span>
      <span>
        КГ-ХЛ
        ............................................................................................................
        -600С -аас +500С хүртэл
      </span>
      <span>
        {" "}
        КГ-T
        ..............................................................................................................
        -100С -аас +550С хүртэл{" "}
      </span>
      <span>
        {" "}
        Нугалах хамгийн бага
        радиус.............................................................................8-аас
        багагүй кабелийн диаметр
      </span>
      <span>
        {" "}
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн
        температур........................... 750С
      </span>
      <span className="mt-3">
        {" "}
        Кабелийн урт, төв дамжуулагчийн огтлолын хэмжээ:
      </span>
      <span>
        {" "}
        35 мм2 хүртэлх хөндлөн
        огтлолтой...................................................................150
        м -ээс багагүй{" "}
      </span>
      <span>
        {" "}
        50 мм2 ба түүнээс дээш хөндлөн
        огтлолтой.......................................................125 м
        -ээс багагүй{" "}
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData3.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product4;
