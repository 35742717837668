import React from "react";

function Product12() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/Untitled1.jpeg" />
      <span className="font-semibold">
        {" "}
        КВВГ (0.66 кВ, 1.0 кВ, ГОСТ 16442-80){" "}
      </span>
      <span>
        {" "}
        ХЦ дамжуулагчтай цахилгаан контрольный хүчний кабель. Поливнилхлоридийн
        пластикат тусгаарлагч болон бүрхэвчтэй.
      </span>
      <span className="font-semibold mt-3">
        {" "}
        КВВГнг (0.66 кВ, 1.0 кВ, ТУ 3500-002-59680332-2004){" "}
      </span>
      <span>
        {" "}
        ХЦ дамжуулагчтай цахилгаан контрольный хүчний кабель. Поливнилхлоридийн
        пластикат тусгаарлагч болон поливнилхлоридийн пластикат холимогтой
        бүрхэвч, амархан шатдаггүй
      </span>
      <span className="font-semibold mt-3">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ{" "}
      </span>
      <ol>
        <li>
          1. СУДАЛ – зэс, дан эсвэл олон судалтай, дугуй эсвэл дөрвөлжин, ГОСТ
          22483 (I,II) стандарт
        </li>
        <li>
          2. ТУСГААРЛАГЧ – поливнилхлоридийн пластикат хольцтой (PVC). Кабелийн
          дотоод судал нь өөр өөр өнгөөр ялгарсан. Тэг судалын бүрхүүл цэнхэр
          өнгөтэй. Газардуулга судалын бүрхүүл ногоон-шар өнгөтэй.
        </li>
        <li>
          3. СУДАЛ ЭРЧЛЭЭ – 2, 3, 4, 5-аар эрчилсэн тусгаарлагдсан
          дамжуулагчтай. -Хоёр дамжуулагчтай кабелийн огтлолын хэмжээ нь адил.
          Гурав, -дөрөв, -таван дамжуулагчтай кабелийн огтлолын хэмжээ адил
          эсвэл нэг дамжуулагч (тэг судал эсвэл газардуулга судал) нь жижиг
          огтлолтой.
        </li>
        <li>
          4. БҮРХЭВЧ – поливнилхлоридийн пластикат хольцтой амархан шатдаггүй
          материалаас бүрдсэн
        </li>
      </ol>
    </div>
  );
}

export default Product12;
