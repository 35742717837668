import React, { Component } from "react";

import { FaBuilding, FaBoxes, FaShoppingBag } from "react-icons/fa";

const ServiceList = [
  {
    icon: <FaBuilding />,
    title: "БАРИЛГА УГСРАЛТ",
    description:
      "Тавантолгой, Оюу толгойн ЦДАШ, 220/110/35кв-ын дэд станцын барилга угсралт гэх мэт ажлыг хийж гүйцэтгэсэн.",
  },
  {
    icon: <FaBoxes />,
    title: "ҮЙЛДВЭРЛЭЛ",
    description:
      "PRISMA iPM system L болон system M загварын самбарыг зураг төслийн дагуу угсарч, суурилуулна.",
  },
  {
    icon: <FaShoppingBag />,
    title: "ХУДАЛДАА",
    description:
      "Чинт гэх мэт олон улсын стандартын шаардалагад нийцсэн компанийн бүтээгдэхүүн болон бүх төлийн кабель, шийд зарж борлуулж байна",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row service-main-wrapper">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a>
                <div className="service service__style--2 text-left bg-gray">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
