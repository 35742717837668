import React from "react";
import {
  tableData9_1,
  tableData9_2,
  tableData9_4,
  tableData9_5,
} from "./tableDatas";

function Product10() {
  return (
    <div className="flex flex-col text-sm">
      <span className="font-semibold mt-3">
        {" "}
        Тусгаарлагчтай судалтай (агаарын багц кондуктор), ГОСТ R 52373-2005, ТУ
        16-705.500-2006{" "}
      </span>
      <span>
        {" "}
        (ТУ 16-705.500-2006 нь ТУ 16.К71-268-98 болон ТУ 16.К71- 272-98 зэргийн
        оронд хэрэглэгддэг)
      </span>
      <span className="mt-3">
        {" "}
        СИП-1 – хамгаалалттай хөнгөн цагаан судал, гэрэл-тогтворжуулагчтай ПЭ,
        тэг-судал нь тусгаарлагчгүй{" "}
      </span>
      <span className="">
        {" "}
        СИП-2 – хамгаалалттай хөнгөн цагаан судал, гэрэл-тогтворжуулагчтай ПЭ,
      </span>
      <img src="/sip1_2.gif" />
      <span className="mt-3">
        {" "}
        СИП-3 - хамгаалалттай хөнгөн цагаан судал, гэрэл-тогтворжуулагчтай ПЭ
        төрлийн тусгаарлагчтай
      </span>
      <span className="">
        {" "}
        СИП-4 - хамгаалалттай хөнгөн цагаан судал (куррир элементгүй),
        гэрэл-тогтворжуулагчтай ПЭ төрлийн тусгаарлагчтай
      </span>
      <img src="/sip3_4.gif" />
      <span className="mt-3 font-semibold">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <span>
        1. СУДАЛ – хөнгөөн цагөөн, дугуй, судал бүр нь олон ширхэгээс бүрдсэн.
        Дамжуулагч судалын гаднах диаметр болон цахилгааны эсэргүүцэх чанар
        доорхи хүснэгтэд дурьдагдсан.
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судалын хөндлөн огтлол, мм2{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Нэг судалд дахь ширхэгийн тоо{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Дамжуулагч судалын гаднах диаметр, мм{" "}
              </th>

              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Үелсэн судалын цахилгааны эсэргүүцэл (1км), Охм{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Min.{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Max{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData9_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span>
        2. ЗАВСРЫН СУДАЛ – хөнгөн цагаан, дугуй, судал бүр нь эрчилсэн олон
        ширхэгээс бүрдсэн. Судалын хөндлөн огтлол, Нэг судалд дахь ширхэгийн
        тоо, Дамжуулагч судалын гаднах диаметр, цахилгааны эсэргүүцэх чанар
        зэрэг нь доорхи хүснэгтэд дурьдагдсан:
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судалын хөндлөн огтлол, мм2{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Нэг судалд дахь ширхэгийн тоо{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Дамжуулагч судалын гаднах диаметр, мм{" "}
              </th>

              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Үелсэн судалын цахилгааны эсэргүүцэл (1км), Охм{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Min.{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Max{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData9_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="">
        {" "}
        3. ТУСГААРЛАГЧ– СИП-1 кабелийн завсрын судал нь тусгаарлагчгүй. the
        neutral core is not insulated. Бусад дамжуулагчид нь
        гэрэл-тогтворжуулагчтай ПЭ судалтай. the insulation made
      </span>
      <span>
        {" "}
        from light-stabilized cross-linked ПЭ. Тусгаарлагчын зузаан нь доорхи
        хүснэгтэд дурьдагдсан.
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Кабелийн төрөл{" "}
              </th>
              <th
                colSpan={11}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Тусгаарлагчын зузаан, мм{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                16{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                25{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                35{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                50{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                54,6{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                70
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                95
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                120
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                150
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                185
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                240
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                СИП-1{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,3{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,3
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,3
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,5
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,5
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,7
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,7
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,7
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,7
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,9
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,9
              </th>
            </tr>
            <tr>
              {" "}
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                СИП-2{" "}
              </th>
            </tr>
            <tr>
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                СИП-3 20 кВ{" "}
              </th>
              <th
                colSpan={11}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                2,3{" "}
              </th>
            </tr>
            <tr>
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                СИП-3 35 кВ{" "}
              </th>
              <th
                colSpan={11}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                3,5{" "}
              </th>
            </tr>
            <tr>
              {" "}
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                СИП-4{" "}
              </th>
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,3{" "}
              </th>
              <th
                rowSpan={1}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1,3{" "}
              </th>
              <th
                colSpan={9}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="mt-3">
        4. СУДАЛ ЭРЧЛЭЭ – тусгаарласан үелсэн судал нь завсрын судалын гадуур
        эрчилсэн. Эрчлээний чиглэл нь баруун.
      </span>
      <span className="mt-3 font-semibold"> ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Тэсвэрлэх цаг агаарын
        нөхцөл..........................................................................
        УХЛ, ГОСТ 15150-69 (категор 1, 2, 3)
      </span>
      <span>
        {" "}
        Кондуктор нь нарны цацраг туяанд тэсвэртэй (1120 Вт/м2±10%),
        ултра-войлетийн нягтаршилд тэсвэртэй (68 Вт/м2±25%).{" "}
      </span>
      <span>
        {" "}
        Кондуктор нугаралтад тэсвэртэй температур
        ................................................... -40°C
      </span>
      <span>
        {" "}
        Кабелийг суурилуулалтын температур
        ............................................................. -20°C -аас
        багагүй{" "}
      </span>
      <span>
        {" "}
        Завсрын судал дээрх даралтын хүч ажиллаж буй
        үед..........................................45 Н -аас ихгүй,{" "}
      </span>
      <span>
        {" "}
        Тусгаарлагчтай судлууд нь 50 Hz давтамж болон 3.5 кВ-д шалгагдсан
      </span>
      <span>
        {" "}
        20°C -д 10 минутаас багагүй хугацаанд байсны дараа эхний 5 минутад (AC
        вольт, 50 Hz давтамжид) доорхи үзүүлэлтүүдийг үзүүлсэн:
      </span>
      <span>
        {" "}
        СИП-1, СИП-2 болон СИП-4
        дамжуулагч..............................................................4
        кВ
      </span>
      <span>
        {" "}
        СИП-3 дамжуулагч 20
        кВ...................................................................................6
        кВ
      </span>
      <span>
        {" "}
        СИП-3 дамжуулагч 35
        кВ..................................................................................10
        кВ
      </span>
      <span>
        {" "}
        Кондуктор 1 цагийн турш AC вольт 4 кВ, 50 Hz давтамжыг тэсвэрлэсэн.{" "}
      </span>
      <span>
        {" "}
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн температур +90°С -ээс
        багагүй, богино холбоонд 250°C -ээс багагүй.{" "}
      </span>
      <span>
        {" "}
        Зөвшөөрөгдсөн кондукторын цахилгаан гүйдэл, +25°C-д, салхины хурд 0.6
        м/с, нарны цацраг туяаны хүч 1000 В/м2. Зөвшөөрөгдсөн нэг секунд дэх
        богино холбооны цахилгааны гүйдэл:
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Төв судалын хөндлөн огтлол, мм{" "}
              </th>
              <th
                colSpan={3}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Зөвшөөрөгдсөн цахилгаан гүйдэл, А, ихгүй{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Зөвшөөрөгдсөн нэг секунд дэх богино холбооны цахилгааны гүйдэл,
                kA, ихгүй{" "}
              </th>
            </tr>
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                өөрийгөө дэмжигч тусгаарлагчтай судал{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                хамгаалалттай кабель{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                өөрийгөө дэмжигч тусгаарлагчтай судал{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                хамгаалалттай кабель{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                25kВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                35kВ{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData9_4.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold"> ХЭРЭГЛЭЭ</span>
      <span>
        {" "}
        СИП-1 - агаарын цахилгааны дамжуулганд, агаарын атмосперийн төрөл I
        болон II, ГОСТ 15150-69
      </span>
      <span>
        {" "}
        СИП-2 - агаарын цахилгааны дамжуулганд, агаарын атмосперийн төрөл I
        болон II, ГОСТ 15150-69 , далайн эрэг, давслаг нуурын эрэг ба үйлвэрийн
        орчинд{" "}
      </span>
      <span>
        {" "}
        СИП-3 - агаарын цахилгааны дамжуулганд, вольт 10-35 кВ, агаарын
        атмосперийн төрөл II болон III, ГОСТ 15150-69 , далайн эрэг, давслаг
        нуурын эрэг ба үйлвэрийн орчинд{" "}
      </span>
      <span>
        {" "}
        СИП-4 - агаарын цахилгааны дамжуулганд, байрны хананд ба инженерийн
        байгууламж, агаарын атмосперийн төрөл II болон III, ГОСТ 15150-69
      </span>
      <span> ОКП КОД:</span>
      <span> 35 5332 0700 – СИП-1 кабель</span>
      <span> 35 5332 0900 – СИП-2 кабель</span>
      <span> 35 5332 0100 – СИП-3 кабель</span>
      <span> 35 5332 1700 – СИП-4 кабель</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Тэмдэг болон вольт{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Судлын тоо, үндсэн судалын огтлол, мм2{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Кабелийн гадна диаметр, мм{" "}
              </th>

              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData9_5.slice(0, 1).map((row, index) => (
              <tr key={index}>
                <td
                  rowSpan={14}
                  className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                >
                  СИП-1 0,6 / 1 kV
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(1, 14).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(14, 15).map((row, index) => (
              <tr key={index}>
                <td
                  rowSpan={18}
                  className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                >
                  СИП-2 0,6 / 1 kV{" "}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(15, 32).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(32, 33).map((row, index) => (
              <tr key={index}>
                <td
                  rowSpan={8}
                  className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                >
                  СИП-3 20 кВ{" "}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(33, 40).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(40, 41).map((row, index) => (
              <tr key={index}>
                <td
                  rowSpan={8}
                  className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                >
                  СИП-3 20 кВ{" "}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(41, 48).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(48, 49).map((row, index) => (
              <tr key={index}>
                <td
                  rowSpan={4}
                  className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                >
                  СИП-4 0,6/1 кВ{" "}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
            {tableData9_5.slice(49, 52).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product10;
