import React from "react";
import { tableData1 } from "./tableDatas";

function Product1() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/product-image1.gif" className="mt-3" />
      <span className="font-semibold mt-3">
        АВБбШв (0.66 кВ. 1.0 кВ, ГОСТ 16442-80)
      </span>
      <span>
        Цахилгаан хүчний хөнгөн цагаан кабель. Поливнилхлоридийн пластикат
        тусгаарлагч, БбШв төрлийн кабелийн хамгаалалттай.
      </span>
      <span className="font-semibold mt-3">
        АВБбШв (0.66 кВ. 1.0 кВ, ТУ 3500-002-59680332-2004)
      </span>
      <span>
        Цахилгаан хүчний хөнгөн цагаан кабель. Поливнилхлоридийн пластикат
        тусгаарлагч, БбШв төрлийн кабелийн хамгаалалттай. Амархан шатдаггүй
        материалаар хийгдсэн.
      </span>
      <span className="font-semibold mt-3">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>
          1. СУДАЛ – хөнгөн цагаан, дан эсвэл олон судалтай, дугуй эсвэл
          дөрвөлжин, ГОСТ 22483 (I,II зэрэг) стандарт
        </li>
        <li>
          2. ТУСГААРЛАГЧ – поливнилхлоридийн пластикат хольцтой (PVC). Кабелийн
          дотоод судал нь өөр өөр өнгөөр ялгарсан. Тэг-судалын бүрхүүл цэнхэр
          өнгөтэй. Газардуулга-судалын бүрхүүл ногоон-шар өнгөтэй.
        </li>
        <li>
          3. СУДАЛ ЭРЧЛЭЭ – 2, 3, 4, 5-аар эрчилсэн тусгаарлагдсан
          дамжуулагчтай. -Хоёр дамжуулагчтай кабелийн огтлолын хэмжээ нь адил.
          Гурав, -дөрөв, -таван дамжуулагчтай кабелийн огтлолын хэмжээ адил
          эсвэл нэг дамжуулагч (тэг-судал эсвэл газардуулга-судал) нь жижиг
          огтлолтой.
        </li>
        <li>
          4. БООЛТ, ОРООЛТ – БбШв хамгаалалт нь поливнилхлоридийн пластикат
          хольцтой (PVC) эсвэл тусгаарлагчийн материал түүнтэй ижил төстэй
          материалаас бүрднэ. Хоёр PVC материалаас бүрдсэн судалын дундуур
          АВБбШнг кабель байрладаг. Амархан шатдаггүй шинж чанартай.
        </li>
        <li>
          5. КАБЕЛИЙН ХАМГААЛАЛТ – БбШв төрлийн хамгаалалт нь: - хоёр төмөр
          гальваникдсан зүсмэл - поливнилхлоридийн пластикат хольцтой бүрхүүл. -
          амархан шатдаггүй поливнилхлоридийн пластикат хольцтой бүрхүүл.
        </li>
      </ol>
      <span className="font-semibold mt-3">ХЭРЭГЛЭЭ:</span>
      <span>
        Цахилгаан хүчийг дамжуулах, түгээх (AC вольт - 0.66 кВ, 1.0 кВ, 50 Hz)
        Халуун болон чийгтэй газарт суурилуулахдаа тусгай кабелийн суваг, блокыг
        кабелийн гадуур хэрэглэвэл зохистой. Газар доор суурилуулхад тохиромжгүй
        (суваг шуудуу).
      </span>
      <span>АВВГ кабель дангаараа шатдаггүй.</span>
      <span>АВВГнг кабель бөөнөөрөө шатдаггүй.</span>
      <span className="font-semibold mt-3">ОКП КОД:</span>
      <ul>
        <li> 35 2222 11 — АВВГ кабель 0.66 кВ</li>
        <li> 35 3771 52 — АВВГ кабель 1.0 кВ</li>
        <li> 35 2222 45 — АВВГнг кабель 0.66 кВ</li>
        <li> 35 3771 54 — АВВГнг кабель 1.0 кВ</li>
      </ul>
      <span className="font-semibold mt-3">ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span className="">
        Тэсвэрлэх цаг агаарын нөхцөл
        ..................................................................................УХЛ
        (хүйтэнд тэсвэртэй), Т (халуунд тэсвэртэй), ГОСТ 15150-69 (категор -1,
        -5)
      </span>
      <span>
        Ажиллах
        температур................................................................................................
        -50°С -аас +50°С хүртэл
      </span>
      <span>
        Агаарын чийглэг тэсвэрлэх чадвар (+35°С-аас ихгүй нөхцөлд)
        ..................................... 98% хүртэл
      </span>
      <span>
        Кабелийг урьдчилан халаахгүйгээр суурилуулалтын
        температур.................................. -15°С -аас багагүй
      </span>
      <span className="mt-3">Нугалах хамгийн бага радиус:</span>
      <span className="">
        АВВГ кабель дан судал
        ............................................................................................
        10 гаднах диаметр
      </span>
      <span>
        АВВГнг кабель дан судал
        ..........................................................................................15
        гаднах диаметр
      </span>
      <span>
        Эрчилсэн кабель
        ......................................................................................................7.5
        гаднах диаметр
      </span>
      <span>
        Давтамж........................................................................................................50
        Hz
      </span>
      <span>50 Hz (AC вольт) давтамжид шалгагдсан:</span>
      <span>
        0.66 кВ
        вольт............................................................................................................3
        кВ
      </span>
      <span>
        1.0 кВ
        вольт.............................................................................................................3.5
        кВ
      </span>
      <span>
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн температур
        ....................................+70°С
      </span>
      <span>Кабелийн урт, төв дамжуулагчийн огтлолын хэмжээ:</span>
      <span>
        1.5 -аас 16 мм2
        хүртэл..............................................................................................
        450 м
      </span>
      <span>
        25 -аас 70 мм2
        хүртэл...............................................................................................
        300 м
      </span>
      <span>
        95 мм2 -аас
        дээш.......................................................................................................200
        м
      </span>
      <span className="font-semibold mt-3">Дугуй голчтой АВВГ кабель</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-ull divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судлын тоо, үндсэн <br /> судалын огтлол, мм2
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Кабелийн гадна диаметр, мм
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км кабелийн жин, кг
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                0.66 КВ
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                1 КВ
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                0.66 КВ
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {" "}
                1 КВ
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product1;
