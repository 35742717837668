import React from "react";
import { tableData2_1, tableData2_2 } from "./tableDatas";

function Product2() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/product-image2.gif" className="mt-3" />
      <span className="font-semibold mt-3">
        АВВГ (0.66 кВ, 1.0 кВ, ГОСТ 16442-80)
      </span>
      <span>
        ХЦ дамжуулагчтай цахилгаан хүчний кабель. Поливнилхлоридийн пластикат
        тусгаарлагч болон бүрхэвчтэй.
      </span>
      <span className="font-semibold mt-3">
        АВВГнг (0.66 кВ, 1.0 кВ, ТУ 3500-002-59680332-2004)
      </span>
      <span>
        ХЦ дамжуулагчтай цахилгаан хүчний кабель. Поливнилхлоридийн пластикат
        тусгаарлагч болон поливнилхлоридийн пластикат холимогтой бүрхэвч,
        амархан шатдаггүй
      </span>
      <span className="font-semibold mt-3">
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>
          {" "}
          1. СУДАЛ – хөнгөн цагаан, дан эсвэл олон судалтай, дугуй эсвэл
          дөрвөлжин, ГОСТ 22483 (I,II) стандарт
        </li>
        <li>
          {" "}
          2. ТУСГААРЛАГЧ – поливнилхлоридийн пластикат хольцтой (PVC). Кабелийн
          дотоод судал нь өөр өөр өнгөөр ялгарсан. Тэг судалын бүрхүүл цэнхэр
          өнгөтэй. Газардуулга судалын бүрхүүл ногоон-шар өнгөтэй.
        </li>
        <li>
          {" "}
          3. СУДАЛ ЭРЧЛЭЭ – 2, 3, 4, 5-аар эрчилсэн тусгаарлагдсан
          дамжуулагчтай. -Хоёр дамжуулагчтай кабелийн огтлолын хэмжээ нь адил.
          Гурав, -дөрөв, -таван дамжуулагчтай кабелийн огтлолын хэмжээ адил
          эсвэл нэг дамжуулагч (тэг судал эсвэл газардуулга судал) нь жижиг
          огтлолтой.
        </li>
        <li>
          {" "}
          4. БҮРХЭВЧ – поливнилхлоридийн пластикат хольцтой амархан шатдаггүй
          материалаас бүрдсэн
        </li>
      </ol>
      <span className="font-semibold mt-3">ХЭРЭГЛЭЭ:</span>
      <span>
        {" "}
        Цахилгаан хүчийг дамжуулах, түгээх (AC вольт - 0.66 кВ, 1.0 кВ, 50 Hz)
      </span>
      <span>
        {" "}
        Халуун болон чийгтэй газарт суурилуулахдаа тусгай кабелийн суваг, блокыг
        кабелийн гадуур хэрэглэвэл зохистой. Газар доор суурилуулхад тохиромжгүй
        (суваг шуудуу).{" "}
      </span>
      <span>АВВГ кабель дангаараа шатдаггүй.</span>
      <span>АВВГнг кабель бөөнөөрөө шатдаггүй.</span>

      <span className="font-semibold mt-3">ОКП КОД:</span>

      <ol>
        <li className="">35 2222 11 — АВВГ кабель 0.66 кВ</li>
        <li className="">35 3771 52 — АВВГ кабель 1.0 кВ</li>
        <li className="">35 2222 45 — АВВГнг кабель 0.66 кВ</li>
        <li className="">35 3771 54 — АВВГнг кабель 1.0 кВ</li>
      </ol>
      <span className="font-semibold mt-3">ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        Тэсвэрлэх цаг агаарын нөхцөл
        ..................................................................................УХЛ
        (хүйтэнд тэсвэртэй), Т (халуунд тэсвэртэй), ГОСТ 15150-69 (категор -1,
        -5)
      </span>
      <span>
        {" "}
        Ажиллах
        температур................................................................................................
        -50°С -аас +50°С хүртэл{" "}
      </span>
      <span>
        {" "}
        Агаарын чийглэг тэсвэрлэх чадвар (+35°С-аас ихгүй нөхцөлд)
        ..................................... 98% хүртэл{" "}
      </span>
      <span>
        {" "}
        Кабелийг урьдчилан халаахгүйгээр суурилуулалтын
        температур.................................. -15°С -аас багагүй
      </span>
      <span className="mt-3"> Нугалах хамгийн бага радиус:</span>
      <span className="">
        {" "}
        АВВГ кабель дан судал
        ............................................................................................
        10 гаднах диаметр
      </span>
      <span className="">
        {" "}
        АВВГнг кабель дан судал
        ..........................................................................................15
        гаднах диаметр
      </span>
      <span className="">
        {" "}
        Эрчилсэн кабель
        ......................................................................................................7.5
        гаднах диаметр
      </span>
      <span className="">
        {" "}
        Давтамж........................................................................................................50
        Hz
      </span>
      <span className="mt-3"> 50 Hz (AC вольт) давтамжид шалгагдсан: </span>
      <span className="">
        {" "}
        0.66 кВ
        вольт............................................................................................................3
        кВ
      </span>
      <span className="">
        {" "}
        1.0 кВ
        вольт.............................................................................................................3.5
        кВ
      </span>
      <span>
        {" "}
        1.0 кВ
        вольт.............................................................................................................3.5
        кВ
      </span>
      <span>
        {" "}
        Дамжуулагчийг урьдчилсан халаах зөвшөөрөгдсөн температур
        ....................................+70°С
      </span>
      <span className="mt-3">
        {" "}
        Кабелийн урт, төв дамжуулагчийн огтлолын хэмжээ:
      </span>
      <span>
        {" "}
        1.5 -аас 16 мм2
        хүртэл..............................................................................................
        450 м
      </span>
      <span>
        {" "}
        25 -аас 70 мм2
        хүртэл...............................................................................................
        300 м
      </span>
      <span>
        {" "}
        95 мм2 -аас
        дээш.......................................................................................................200
        м
      </span>
      <span className="font-semibold mt-3"> Дугуй голчтой АВВГ кабель</span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Кабелийн гадна диаметр, мм{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                0.66 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                0.66 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 КВ{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData2_1.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col9}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <span className="mt-3 font-semibold">
          {" "}
          Дөрвөлжин голчтой АВВГ кабель
        </span>
        <table className="mt-3 min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судлын тоо, үндсэн судалын огтлол, мм2
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Кабелийн гадна диаметр, мм{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                0.66 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                0.66 КВ{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                1 КВ{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData2_2.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col9}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product2;
