import React, { Component } from "react";
import { tableData10_14, tableData10_15, tableData10_16 } from "./tableDatas";

export class Product21 extends Component {
  render() {
    return (
      <div className="flex flex-col text-sm">
        <img src="/60.jpg" className="max-w-[200px]" />
        <span className="mt-3 font-semibold">1. Товч танилцуулга</span>
        <ol>
          <li className="flex flex-col">
            <span className="mt-3"> 1.1 Ерөнхий хэрэглээ</span>
            <span>
              {" "}
              DTS27A электрон 3-фазын 4-утастай тоолуур нь 50Hz давтамжтай 230V
              бүхий 3-фазын AC актив хүртэл энергийг хэмжинэ. Тоолуурын бүхий л
              үзүүлэлтүүд нь 3-фазын цахилгааны тоолуурын 1 нарийвчлал бүхий IEC
              62053-21 стандартыг бүрэн хангасан.
            </span>
            <span> Энэ бүтээгдэхүүн нь доорхи үзүүлэлттэй.</span>
            <span> Амбиэнт температур: -40С ~70C</span>
            <span> Дээд харьцангуй чийгшил: 100%</span>
            <span className="mt-3"> 1.2 Норм</span>
            <div className="overflow-x-auto mt-3">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_14[0].col1}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_14[0].col2}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_14[0].col3}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_14[0].col4}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_14[0].col5}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData10_14
                    .slice(1, tableData10_14.length)
                    .map((row, index) => (
                      <tr key={index}>
                        <td
                          rowSpan={2}
                          className=" px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                        >
                          {row.col1}
                        </td>
                        <td
                          rowSpan={2}
                          className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                        >
                          {row.col2}
                        </td>
                        <td
                          rowSpan={2}
                          className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                        >
                          {row.col3}
                        </td>
                        <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                          {row.col4}
                        </td>
                        <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                          {row.col5}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td className=" px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      10(100){" "}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      200{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
        <span className="mt-3 font-semibold">
          2. Үндсэн техникийн үзүүлэлтүүд
        </span>
        <ol className="mt-3">
          <li>2.1 Үндсэн алдаа</li>
          <li>
            <div className="overflow-x-auto mt-3">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      colSpan={2}
                      className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ачаалах гүйдэл{" "}
                    </th>
                    <th
                      rowSpan={2}
                      className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Цахилгааны өгөгдөл{" "}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Инстрисик алдаа{" "}
                    </th>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Шууд холболт{" "}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      СТ холболт{" "}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Нарийвчлал класс 1{" "}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData10_15.map((row, index) => (
                    <tr key={index}>
                      <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                        {row.col1}
                      </td>
                      <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                        {row.col2}
                      </td>
                      <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                        {row.col3}
                      </td>
                      <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                        {row.col4}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </li>
          <li className="mt-3 flex flex-col">
            <span> 2.2 Цахилгааны хэрэглээ</span>
            <span> Вольтын хэлхээ тус бүр ≤ 1.8W 8VA</span>
            <span> Гүйдлийн хэлхээ тус бүр ≤ 1.0 VA</span>
          </li>
          <li> 2.3 Хэмжээ: 285.1мм * 176.3мм * 85.7мм</li>
          <li> 2.4 Жин: ≤ 2.5 кг</li>
          <li className="flex flex-col">
            <span> 2.5 Эхлэх гүйдэл:</span>
            <span>DTS27A (1.5(6)A) 0.2%In</span>
            <span> DTS27A (10(100)A) 0.4%Ib</span>
          </li>
          <li>
            {" "}
            2.6 Шилжүүлэгчийн гүйлт: Гүйдлийн хэлхээ нь 115% вольт тасрах
            тохиолдолд, чадалын гаргалгааны пулс нь 1-ээс бага байна.
          </li>
          <li> 2.7 Ажиллах хугацаа: 10 жил</li>
        </ol>
        <span className="mt-3 font-semibold">3. Ажиллагааны зарчим</span>

        <ol>
          <li>
            <img src="/61.jpg" className="max-w-[362px]" />
          </li>
          <li>
            <span className="mt-3">
              Энэ бүтээгдэхүүн нь 3-фазын метерийн хэмжилтийн тусгай IC-г
              агуулсан. Вольт хуваагч болон гүйдэл хуваагч нь АС вольт болон
              гүйдлийг сигнал болгон хөрвүүлнэ. Тусгай эрчим хүч хэмжигч IC нь
              эдгээр сигналийг эрчим хүчний дохиоруу хөрвүүлдэг. Үүний давтамж
              нь эрчим хүчтэй шууд пропорциональ хамааралтай байна. DTS27A нь
              3-фазын цахилгаан дамжуулах хэлхээг ашигладаг ба энэ нь нэг фаз нь
              алдагдсан үед ч метер хэвийн ажиллана.
            </span>
          </li>
        </ol>
        <span className="mt-3 font-semibold">
          4. Суурилуулалт болон үйл ажиллагаа
        </span>
        <ol className="mt-3">
          <li>
            {" "}
            4.1 Үйлдвэрээс гарахын өмнө тоолуурыг шалгаж лацдана.
            Суурилуулалтаас өмнө хэрэглэгч лацны бүрэн бүтэн байдлыг шалгах
            хэрэгтэй. Лац бүрэн бүтэн биш байвал суурилуулж болохгүй.
          </li>
          <li>
            {" "}
            4.2 Тоолуурыг дотор суурилуулах ба сонгогдсон хананд санал
            болгогдсон 1.8 метр орчим өндөрт суурилуулна. Тоолуурын дээд хэсэгт
            өлгөх нүх байх ба M4.5 загварын шуруп ашиглана. Тоолуурын доор
            байрлах нүх нь М4.5*28 эсвэл М4.5*30 шурупээр самбарт тогтооно
            (суурилуулах диаграм 5-ийг харна уу). Зэврэмтгий болон чийгтэй
            орчинд тоолуурыг хамгаалалтын хайрцагт суурилуулах хэрэгтэй.
          </li>
          <li>
            {" "}
            4.3 Утаснуудыг холбохоос өмнө, диспечир тоолуурын нүүрэнд байрлах
            утас холболтын диаграмтай сайтар танилцаж холболтыг зөв хийх
            шаардлагатай. Зэс судал болон холбоос бүхий кабель нь илүү
            тохиромжтой. Тоолуурыг сайн бэхэлж боосон байх шаардлагатай.
            Холболтыг хийж дуусаад сайн лацдаж байршуулах нь чухал.
          </li>
          <li>
            {" "}
            4.4 Шууд холбогдсон 10(100)A цахилгааны тоолуурын хэрэглээг хянахдаа
            хэрэглэгч тоолуурын дэлгэцийн параметрт анхаарал хандуулах хэрэгтэй.
            Үүнд: Дэлгэцийн улаан хэсэгт байрлах дугаар бол аравтын орон (1
            орон), харин хар хэсэгт байрлах тоонууд нь бүхэл тоо (5 орон). Жишээ
            нь, DTS27A (10(100)A) тоолуурын өмнөх заалт нь 000168, дараах заалт
            нь 000188 байсан ба зөрүү нь 2.0 гэж үзвэл хэрэглэсэн эрчим хүч нь 2
            kWh юм.
          </li>
          <li>
            {" "}
            4.5 СТ холболттой 1.5(6)A цахилгааны тоолуурын хэрэглээг хянахдаа
            хэрэглэгч тоолуурын дэлгэцийн параметрт анхаарал хандуулах хэрэгтэй.
            Үүнд: Дэлгэцийн улаан хэсэгт байрлах дугаар бол аравтын орон (1
            орон), харин хар хэсэгт байрлах тоонууд нь бүхэл тоо (5 орон).
            Хэрэглэгдсэн эрчим хүчийг тооцох аргачлал нь харагдах тоон
            үзүүлэлтийг СТ-ийн харьцаатай үржүүлж олно. Жишээ нь, DTS27A
            (1.5(6)A) тоолуурын өмнөх заалт нь 000168, дараах заалт нь 000188
            байсан ба СТ-ийн харьцаа нь СТ=20(100/5) гэж үзвэл хэрэглэсэн эрчим
            хүч нь 2*20=40 kWh юм.
          </li>
        </ol>
        <span className="font-semibold mt-3">
          5. Гаднах харагдах байдал, Холболтын диаграм болон Пулс шалгах диаграм
        </span>
        <ol className="mt-3">
          <li>5.1 Гаднах хэмжээ болон суурилуулах диаграм</li>
          <li className="mt-3">
            <img src="/62.jpg" />
          </li>
          <li>
            <div className="overflow-x-auto mt-3">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_16[0].col1}
                    </th>
                    <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {tableData10_16[0].col2}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData10_16
                    .slice(1, tableData10_16.length)
                    .map((row, index) => (
                      <tr key={index}>
                        <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                          {row.col1}
                        </td>
                        <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                          {row.col2}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </li>
          <li className="">
            <img src="/63.jpg" className="max-w-[621px]" />
          </li>
          <li className="mt-3"> 5.2 Холболтын диаграм</li>
          <li>
            <img src="/64.jpg" className="max-w-[228px] mt-3" />
          </li>
          <li className="flex flex-col mt-3">
            <span> 5.3 Пулс шалгах</span>
            <span>
              {" "}
              Тоолуур нь потокоплинг тестийн үзүүлэлттэй. 9 & 10 гэсэн пулсийн
              үзүүлэлттэй. "+" болон "-" гэсэн тэмдэглэгээ нь 9 & 10 гэж туслах
              терминалийн үзүүлэлттэй дүйцэнэ.
            </span>
            <img src="/65.jpg" className="max-w-[463px]" />
          </li>
        </ol>
        <span className="mt-3 font-semibold">6. Үндсэн функц</span>
        <ol className="mt-3">
          <li className="flex flex-col">
            <span> 6.1 Эрчим хүч хэмжих</span>
            <span> Нэгж: kWh</span>
            <span> Хэмжих багтаамж: 99999.9 kWh</span>
            <span> Нарийвчлал: 0.1кWh</span>
            <span>
              {" "}
              Хэмжих аргачлал: Эрчим хүчний хэмжилт нь тоолуурт эрчим хүч
              нэмэгдсэнийг болон хэдий хэмжээтэй нэмэгдэж ашиглаж буйг
            </span>
          </li>
          <li className="flex flex-col">
            <span> 6.2 Дэлгэц</span>
            <span> Пулс мэдээлэгч: Улаан LED</span>
            <span> Дэлгэц: Тоологч</span>
            <span>
              {" "}
              Фазын алдагдал мэдээлэгч: Нүүрэн дэх 3 улаан LED гэрэл нь А, В, С
              фаз тус бүрийн алдагдлыг мэдээлнэ
            </span>
          </li>
        </ol>
        <span className="mt-3 font-semibold">7. Хүргэлт болон хадгалалт</span>
        <ol>
          <li className="mt-3">
            {" "}
            7.1 Тоолуурыг тээвэрлэх болон задлах явцад цохилт болон доргилтоос
            сэргийлэх ба хүргэх, хадгалах горимыг заагдсан журмын дагуу хийнэ.
          </li>
          <li>
            {" "}
            7.2 Тоолуурыг хадгалахдаа зориулалтын хайрцагт -40C ~ +70C
            температурт, харьцангуй чийгшилийн дээд хэмжээ нь 100%-аас ихгүй
            нөхцөлд хадгална. Метерийг зэврүүлдэг орчинд хадгалж болохгүй.
          </li>
        </ol>
        <span className="mt-3 font-semibold">8. Баталгаа</span>
        <ol>
          <li className="mt-3">
            {" "}
            Бид үйлдвэрийн лац хөдлөөгүй болон үйл ажиллагааны бүх зааварыг
            мөрдлөг болгосон техникийн үзүүлэлтэд хүрэхгүй эсвэл зөрчигдсөн бүх
            тоолуурыг 18 сарын хугацаанд солих буюу засварлаж өгнө.
          </li>
        </ol>
        <span className="mt-3 font-semibold">8. Тайлбар</span>
        <ol>
          <li className="mt-3">
            9.1 Энэ бүтээгдэхүүн нь бүтэн электрон тоолуур ба ямар нэгэн өөрчлөх
            дотор тоноглол байхгүй.
          </li>
          <li>
            {" "}
            9.2 Суурилуулж буй үед бүх боолтуудыг чангалж зөв холбосон байх
            шаардлагатай.
          </li>
          <li>
            {" "}
            9.3 Аянга цахилгааны хамгаалалттай орчинд тоолуурыг суурилуулах.
          </li>
        </ol>
      </div>
    );
  }
}

export default Product21;
