import React from "react";
import { tableData6 } from "./tableDatas";

function Product7() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/pvs.gif" />
      <span className="mt-3 font-semibold">ПВС (ГОСТ 7399-97) </span>
      <span>
        {" "}
        Зэс судалтай эрчилсэн кабель, поливнилхлоридийн пластикат тусгаарлагч
        болон бүрхэвч, уян хатан, 380/660 В хүртэлх вольт{" "}
      </span>
      <span className="mt-3 font-semibold"> ПВСн </span>
      <span> Адил ба салдаггүй арматур хамгаалалтгүй,</span>
      <span className="mt-3 font-semibold">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ
      </span>
      <ol>
        <li>
          1. СУДАЛ — Зэс дугуй хэлбэртэй эрчилсэн кабель, ГОСТ 22483 (категор 5)
        </li>
        <li>2. ТУСГААРЛАГЧ — поливнилхлоридийн пластикат хольцтой материал </li>
        <li>
          3. СУДАЛ ЭРЧЛЭЭ — тусгаарласан судлууд нь эрчилсэн. 5 судалт кабел нь
          төв судалыг ороож байрласан байдаг.
        </li>
        <li>4. БҮРХЭВЧ — поливнилхлоридийн пластикат хольцтой. </li>
      </ol>
      <span className="mt-3 font-semibold"> ХЭРЭГЛЭЭ</span>
      <span>
        {" "}
        Гэрийн электрон хэрэгсэл, багаж, угаалгын машин, хөргөгч г.м зүйлсийн
        холболт. 380 В (систем 380/660 В) -ийн салаалсан холболт.
      </span>
      <span className="mt-3 font-semibold"> ОКП КОД:</span>
      <span className=""> 35 5513 02 — ПВС, ПВСн кабель</span>
      <span> 35 5513 21 — ПВС-Т кабель</span>
      <span className="mt-3 font-semibold"> ТЕХНИКИЙН ТОДОРХОЙЛОЛТ</span>
      <span>
        {" "}
        Тэсвэрлэх цаг агаарын нөхцөл
        .........................................................................
        ГОСТ 15150:
      </span>
      <span>
        {" "}
        ......................................................................................................................
        “U” – категор 1, 2, 3
      </span>
      <span>
        {" "}
        ......................................................................................................................
        “T” – категор 4
      </span>
      <span>
        {" "}
        .....................................................................................................................
        “УХЛ” – категор 4
      </span>
      <span>
        {" "}
        “U” төрлийн кабелийн тэсвэрлэх цаг агаарын нөхцөл
        ......................................... -400С -аас +400С хүртэл{" "}
      </span>
      <span>
        {" "}
        Бусад төрлийн кабелийн тэсвэрлэх цаг агаарын нөхцөл
        ..................................... -250С -аас +400С хүртэл{" "}
      </span>
      <span>
        {" "}
        Ажиллах үед тэсвэрлэх дээд
        температур...........................................................
        +700С
      </span>
      <span> (20±5)С градустай усанд 1 цаг байлгасны дараа </span>
      <span>
        {" "}
        AC вольт 2000 В давтамж 50 Hz -д тэсвэрлэсэн хугацаа
        ......................................15 минут
      </span>
      <span> Дан кабелийн үед гал дамжуулдаггүй</span>
      <span>
        {" "}
        Ажиллах хугацаа
        ............................................................................................
        5000 цагаас багагүй{" "}
      </span>
      <span>
        {" "}
        Электрон төхөөрөмжид хэрэглэх үед ажиллах хугацаа
        .......................................12000 цагаас багагүй{" "}
      </span>
      <span>
        {" "}
        Кабелийн уртын
        хэмжээ....................................................................................50
        м
      </span>
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Судлын тоо, үндсэн судалын огтлол, мм2{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Зузаан, мм{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ПВСн кондукторын гаднах хэмжээ , мм{" "}
              </th>
              <th
                colSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ПВА кондукторын гаднах хэмжээ , мм{" "}
              </th>
              <th
                rowSpan={2}
                className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                1 км кабелийн жин, кг{" "}
              </th>
            </tr>
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Тусгаарлагч{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Бүрхүүл{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Min.{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Max.{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Min.{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Max{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData6.map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col4}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col5}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col6}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col7}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col8}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Product7;
