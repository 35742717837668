import React from "react";

function Product31() {
  return (
    <div>
      <img src="/Untitled26.jpg" />
    </div>
  );
}

export default Product31;
