import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class HeaderFive extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { color, headerPosition } = this.props;

    return (
      <header
        className={`header-area header-not-transparent header--fixed formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-2 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">
                      <img src="/logo-all-dark.png" className="w-[200px]" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-10 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                      <li>
                        <Link to="/">Нүүр</Link>
                      </li>
                      <li>
                        <Link to="/product/:">Бүтээгдэхүүн</Link>
                      </li>
                      <li>
                        <Link to="/distributor">Дистрибютер</Link>
                      </li>
                      <li>
                        <Link to="/manifactor">Үйлдвэрлэл</Link>
                      </li>
                      <li>
                        <Link to="/construction">Барилга угсралт</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="header-btn d-none d-sm-block">
                    <a
                      className="btn-default btn-border btn-opacity"
                      href="/contact"
                    >
                      <span className="text-xs">холбоо барих</span>
                    </a>
                  </div>
                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span
                      onClick={this.CLoseMenuTrigger}
                      className="closeTrigger"
                    >
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFive;
