import React from "react";

function Product26() {
  return (
    <div>
      <img src="/Untitled34.jpg" />
      <img src="/Untitled35.jpg" />
      <img src="/Untitled36.jpg" />
    </div>
  );
}

export default Product26;
