import React from "react";

function Product14() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/Untitled3.png" className="w-[108px]" />
      <span>
        {" "}
        10 кВ-ын КНТп маркийн үзүүрийн муфтний техникийн тодорхойлолт
      </span>

      <span>
        {" "}
        Хүчний кабелийн КВТпН-10, КНТпН-10 муфтууд нь тостой цаасан болон
        пластмассан тусгаарлагчтай, ган төмөр хамгаалалттай болон хамгаалалтгүй
        10 кВ хүртэлх хүчдэлийн кабелийг холбох зориулалттай.
      </span>
      <span> Хүчний кабелийн муфтууд.</span>
      <ul>
        <li>
          {" "}
          • КВТпН-10 төрлийн хүчний кабелийн муфть <br />
          <span>
            {" "}
            Чийглэгийн ямарч нөхцөлд үйлдвэр аж ахуйн газрын дотор суурилагдана
          </span>
        </li>
        <li>
          {" "}
          • КНТпН-10 төрлийн хүчний кабелийн муфть
          <br />
          <span>
            {" "}
            Ил задгай нөхцөлд, тэр дундаа босоо байрлалтайгаар суурилагдах
            боломжтой. чийглэгийн ямарч нөхцөлд үйлдвэр аж ахуйн газрын дотор
            суурилагдана Орчны температур -50С-с +50С хүртэлх нөхцөлд ашиглах
            боломжтой бөгөөд, харьцангуй чийгшил 98% хүртэлх нөхцөлд 35С
            температурт ашиглана.
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Product14;
