import React from "react";

function Product37() {
  return (
    <div className="flex flex-col">
      <img src="/Untitled12.jpg" />
      <img src="/Untitled13.jpg" />
      <img src="/Untitled14.jpg" />
      <img src="/Untitled15.jpg" />
      <img src="/Untitled16.jpg" />
    </div>
  );
}

export default Product37;
