import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../../component/footer/Footer";
import HeaderFive from "../../component/header/HeaderFive";
import { accordionData } from "./product-data";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Product1 from "../../component/products/Product1";
import Product0 from "../../component/products/Product0";
import Product2 from "../../component/products/Product2";
import Product3 from "../../component/products/Product3";
import Product4 from "../../component/products/Product4";
import Product5 from "../../component/products/Product5";
import Product6 from "../../component/products/Product6";
import Product7 from "../../component/products/Product7";
import Product8 from "../../component/products/Product8";
import Product9 from "../../component/products/Product9";
import Product10 from "../../component/products/Product10";
import Product11 from "../../component/products/Product11";
import Product12 from "../../component/products/Product12";
import Product13 from "../../component/products/Product13";
import Product14 from "../../component/products/Product14";
import Product15 from "../../component/products/Product15";
import Product17 from "../../component/products/Product17";
import Product18 from "../../component/products/Product18";
import Product19 from "../../component/products/Product19";
import Product20 from "../../component/products/Product20";
import Product21 from "../../component/products/Product21";
import Product22 from "../../component/products/Product22";
import Product23 from "../../component/products/Product23";
import Product24 from "../../component/products/Product24";
import Product25 from "../../component/products/Product25";
import Product26 from "../../component/products/Product26";
import Product27 from "../../component/products/Product27";
import Product28 from "../../component/products/Product28";
import Product29 from "../../component/products/Product29";
import Product30 from "../../component/products/Product30";
import Product31 from "../../component/products/Product31";
import Product32 from "../../component/products/Product32";
import Product33 from "../../component/products/Product33";
import Product34 from "../../component/products/Product34";
import Product35 from "../../component/products/Product35";
import Product36 from "../../component/products/Product36";
import Product37 from "../../component/products/Product37";
import Product38 from "../../component/products/Product38";
import Product39 from "../../component/products/Product39";
import Product40 from "../../component/products/Product40";

const Product = () => {
  let { id } = useParams();
  const renderProductDetail = (id) => {
    switch (id) {
      case ":":
        return <Product0 />;
      case "АВБбШв":
        return <Product1 />;
      case "АВВГ":
        return <Product2 />;
      case "ВВГ":
        return <Product3 />;
      case "КГ":
        return <Product4 />;
      case "АПВ, ПВ1":
        return <Product5 />;
      case "ПВ3, ПВ4":
        return <Product6 />;
      case "ПВС, ПВСн":
        return <Product7 />;
      case "NUM-J, NUM-O":
        return <Product8 />;
      case "AC":
        return <Product9 />;
      case "СИП1, СИП2, СИП3, СИП4":
        return <Product10 />;
      case "АКВВГ":
        return <Product11 />;
      case "КВВГ":
        return <Product12 />;
      case "РПШ":
        return <Product13 />;
      case "Төгсгөлийн":
        return <Product14 />;
      case "Холболтын":
        return <Product15 />;

      case "DDSF36A - 1 фаз, олон тарифт":
        return <Product17 />;
      case "DDS26 - 1 фаз, 1 тарифт":
        return <Product18 />;
      case "DTSD27 - 3 фаз, олон тарифт, 100А":
        return <Product19 />;
      case "DTSD27 - 3 фаз, олон тарифт, 5А":
        return <Product20 />;
      case "DTS27A - 3 фаз, тарифгүй 100А":
        return <Product21 />;
      case "DTS27A - 3 фаз, тарифгүй 5A":
        return <Product22 />;
      case "Усны тоолуур":
        return <Product23 />;
      case "Гүйдлийн трансформатор":
        return <Product24 />;
      case "Ачаалал таслагч":
        return <Product25 />;
      case "Хуурай салгуур":
        return <Product26 />;
      case "Гал хамгаалагч":
        return <Product27 />;
      case "Тусгаарлагчид, цэнэг шавхагч":
        return <Product28 />;
      case "Шугамын хавчаарууд":
        return <Product29 />;
      case "Трансформаторууд":
        return <Product30 />;
      case "ЦДАШ-ын тулгуурын төмөр хийцүүд":
        return <Product31 />;
      case "Зөөлөн асаагч, давтамж хувиргуур":
        return <Product32 />;
      case "Кондактор":
        return <Product33 />;
      case "Автомат таслуур":
        return <Product34 />;
      case "Автомат таслуур(КЭАЗ)":
        return <Product35 />;
      case "Кондактор(КЭАЗ)":
        return <Product36 />;
      case "Хүчний автомат таслуур":
        return <Product37 />;
      case "Рубильник":
        return <Product38 />;
      case "Шит угсралт болон үйлчилгээ":
        return <Product39 />;
      case "Автомат, пускатель, реле, контактор":
        return <Product40 />;
      default:
        return <div>Бүтээгдхүүн одсонгүй</div>;
    }
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Бүтээгдхүүн | Таны итгэлт түнш" />

      <HeaderFive
        headerPosition="header--static logoresize"
        logo="all-dark"
        color="color-black"
      />
      {/* Start Breadcrump Area */}
      {/* 
      <div className="breadcrumb-area rn-bg-color ptb--70 bg_image bg_image--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner">
                <h2 className="title">БҮТЭЭГДЭХҮҮНИЙ КАТАЛОГИ</h2>
                <ul className="page-list">
                  <li className="rn-breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                  </li>

                  <li className="rn-breadcrumb-item active">
                    БҮТЭЭГДЭХҮҮНИЙ КАТАЛОГИ
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Breadcrump Area */}

      {/* Start Product Area */}

      <div className="w-full h-full py-20">
        <div className="w-full max-w-7xl mx-auto px-7 flex flex-row gap-10">
          <div className="h-full flex flex-col bg-gray-100 p-3 rounded-lg text-xs lg:text-sm xl:text-base">
            <Accordion
              allowMultipleExpanded={false}
              className="accodion-style--1"
              preExpanded={"0"}
            >
              {accordionData.map((r, i) => (
                <AccordionItem key={i}>
                  {!r.submenus ? (
                    <Link to={`/product/${r.menu}`}>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {r.menu}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </Link>
                  ) : (
                    <AccordionItemHeading key={i}>
                      <AccordionItemButton
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {r.menu}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  )}
                  <AccordionItemPanel>
                    <ul className="list-style--2">
                      {r.submenus && Array.isArray(r.submenus) ? (
                        r.submenus.map((s, i) => (
                          <li key={i}>
                            <Link to={`/product/${s}`}>{s}</Link>
                          </li>
                        ))
                      ) : r.submenus && typeof r.submenus === "object" ? (
                        Object.entries(r.submenus).map(([key, value], i) => (
                          <li key={i}>
                            <Accordion
                              allowMultipleExpanded={true}
                              className="accodion-style--1"
                              preExpanded={"0"}
                            >
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {key}
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul className="list-style--2">
                                    {value.map((s, i) => (
                                      <li key={i}>
                                        <Link to={`/product/${s}`}>{s}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </li>
                        ))
                      ) : (
                        <></>
                      )}
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div className="flex flex-col max-w-4xl">
            <span className="font-semibold text-2xl underline mt-2">{id}</span>
            {renderProductDetail(id)}
          </div>
        </div>
      </div>
      {/* End Product Area */}

      <Footer />
    </React.Fragment>
  );
};
export default Product;
