import React from "react";
import {
  tableData10_2,
  tableData10_3,
  tableData10_4,
  tableData10_5,
} from "./tableDatas";

function Product18() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/49.jpg" className="max-w-[200px]" />
      <span className="mt-3 font-semibold">
        1. Функц, үзүүлэлт, ерөнхий хэрэглээ
      </span>
      <span>
        {" "}
        Өндөр технологи бүхий DDS26 электрон 1-фазын тоолуур нь IC unit, сүүлийн
        үеийн микроэлектроник технологи, SMT процесс зэргийг агуулсан болно.
        Гүйдлийн загвар болон эд ангийн сонголтууд нь орчны нөлөөллөөс үүсэх
        алдаа, гэмтлээс сэргийлнэ. Иймээс уг бүтээгдэхүүн нь урт хугацааны
        найдвартай ажиллагаатай юм. Уг бүтээгдэхүүн нь удаан эдэлгээтэй, өндөр
        нарийвчлалтай, найдвартай, хэт ачаалал даах чадвар өндөр, цахилгаан
        зарцуулалт бага, хэмжээ бага болон жин бага юм.
      </span>
      <span>
        {" "}
        Уг тоолуур нь цахилгаан эрчим хүчний хулгайгаас сэргийлэх функцтай ба
        гадна соронзон талбар бүхий. Энэ тоолуур нь буцах чиглэлээр ашигласан
        хэрэглээг хэмжих ба энэ үед буцах энергийн индикатор асна.
      </span>
      <span>
        {" "}
        DDS26 электрон 1-фазын тоолуур нь 50Hz давтамж бүхий, 230 вольтийн AC
        актив энергийг хэмжихэд зориулагдсан. Тоолуурын бүхий л үзүүлэлтүүд нь
        1-фазын цахилгааны тоолуурын 1 нарийвчлал бүхий IEC 62053-21 стандартыг
        бүрэн хангасан.
      </span>
      <span>
        {" "}
        Энэ бүтээгдэхүүн нь доорхи үзүүлэлттэй. Амбиэнт температур: -40С ~70C.
        Харьцангуй чийгшил: 85%-аас ихгүй байх.
      </span>
      <span className="mt-3 font-semibold">
        2.Техникийн тодорхойлолт болон үзүүлэлтүүд,
      </span>
      <ol className="mt-3">
        <li> 2.1.Үзүүлэлтүүд</li>
        <li>
          <div className="overflow-x-auto mt-3">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {tableData10_2[0].col1}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {tableData10_2[0].col2}{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {tableData10_2[0].col3}{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {tableData10_2[0].col4}{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                    {tableData10_2[1].col1}{" "}
                  </td>
                  <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                    {tableData10_2[1].col2}{" "}
                  </td>
                  <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                    {tableData10_2[1].col3}{" "}
                  </td>
                  <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                    {tableData10_2[1].col4}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>2.2 Ерөнхий техникийн өгөгдлүүд</li>
        <li>
          {" "}
          <div className="overflow-x-auto mt-3">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Давтамж: 50Hz{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Дэлгэцийн тоо утга: 5 бүхэл тоо, 1 аравтын бутархай орон{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData10_3.map((row, index) => (
                  <tr key={index}>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col1}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col2}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={2}
                    className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500"
                  >
                    Ачаалалгүй үеийн гүйлт: Энэ тоолуур нь ачаалалгүй үеийн
                    гүйлтээс сэргийлэх функц бүхий логикийн хэлхээтэй юм.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>2.3 Бодит алдаа</li>
        <li>
          {" "}
          <div className="overflow-x-auto mt-3">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ачаалах гүйдэл{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Цахилгааны өгөгдөл{" "}
                  </th>
                  <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Бодит алдаа{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData10_4.map((row, index) => (
                  <tr key={index}>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col1}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col2}
                    </td>
                    <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                      {row.col3}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <span className="mt-3 font-semibold">3. Ажиллгааны зарчим</span>
      <img src="/50.jpg" className="max-w-[531px]" />
      <span className="mt-3 font-semibold">
        4. Тоолуурын терминалын холболтын схем
      </span>
      <span>
        {" "}
        Доорхи тоолуурын холболтын диарамд, C болон E нь цахилгааны пулсийн
        гаралт (тестийн пулсийн гаралт нь фотоэлектрик холболтын тусгаарлагчаар
        дамжина)
      </span>
      <img src="/51.jpg" className="max-w-[338px]" />
      <span className="mt-3 font-semibold">5. Гаднах харагдах байдал</span>
      <img src="/52.jpg" className="max-w-[606px]" />
      <div className="overflow-x-auto mt-3">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_5[0].col1}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_5[0].col2}{" "}
              </th>
              <th className="px-3 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                {tableData10_5[0].col3}{" "}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {tableData10_5.slice(1, tableData10_5.length).map((row, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col1}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col2}
                </td>
                <td className="px-3 py-1 text-center whitespace-nowrap text-xs text-gray-500">
                  {row.col3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span className="mt-3 font-semibold">6. Тээвэрлэлт болон хадгалалт</span>
      <span>
        {" "}
        Тоолуурыг тээвэрлэх болон задлах явцад цохилт болон доргилтоос сэргийлэх
        ба хүргэх, хадгалах горимыг заагдсан журмын дагуу хийнэ.
      </span>
      <span>
        {" "}
        Тоолуурыг хадгалахдаа зориулалтын хайрцагт -40C ~ +70C температурт,
        харьцангуй чийгшилийн дээд хэмжээ нь 85%-аас ихгүй нөхцөлд хадгална.
        Метерийг зэврүүлдэг орчинд хадгалж болохгүй.
      </span>
      <span>
        {" "}
        Тоолуурыг тавиур дээр хадгалах ба 8-аас дээш хайрцагийг давхарлаж
        тавихыг хориглоно.
      </span>
      <span className="mt-3 font-semibold">7. Баталгаа</span>
      <span>
        {" "}
        Бид үйлдвэрийн лац хөдлөөгүй болон үйл ажиллагааны бүх зааварыг мөрдлөг
        болгосон техникийн үзүүлэлтэд хүрэхгүй эсвэл зөрчигдсөн бүх тоолуурыг
        борлуулалтын гэрээнд дурьдагдсан тээвэр хийгдсэн өдрөөс эхлэн баталгаат
        хугаацаа дуусах хүртэл хугацаанд солих буюу засварлаж өгнө.
      </span>
      <span className="mt-3 font-semibold">8. Анхааруулга</span>
      <ul>
        <li>
          Энэ тоолуур нь үйлдвэрт байхдаа чанарын тестед орж лац наагдан
          суурилуулхад бэлэн болно.
        </li>
        <li>
          {" "}
          Тоолуурыг өрөөнд суурилуулахдаа 1,8-аас дээш өндөрт суурилуулахыг
          зөвлөдөг. Цахилгааны тоолуурыг суурилуулах самбарыг галд тэсвэртэй,
          хатуу гадаргуу дээр байршуулах. Шатамхай хий агаарт байж болохгүй.
        </li>
        <li>
          Тоолуурын холболт нь холболтын терминалын хаалтан дээр байрлах
          холболтын диаграмын дагуу хийгдэх. Зэс утас болон зэс холбогч
          ашигласнаар сайн холболт болно
        </li>
      </ul>
    </div>
  );
}

export default Product18;
