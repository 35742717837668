export const accordionData = [
  {
    menu: "Кабель болон дагалдах хэрэгслүүд",
    submenus: {
      "Хүчний кабель": ["АВБбШв", "АВВГ", "ВВГ", "КГ"],
      "Дотор Монтажны Кабель": [
        "АПВ, ПВ1",
        "ПВ3, ПВ4",
        "ПВС, ПВСн",
        "NUM-J, NUM-O",
      ],
      "Агаарын утаснууд": ["AC", "СИП1, СИП2, СИП3, СИП4 "],
      "Контролны кабель": ["АКВВГ", "КВВГ", "РПШ"],
      Муфт: ["Төгсгөлийн ", "Холболтын"],
    },
  },
  {
    menu: "Хэмжих хэрэгсэл",
    submenus: {
      "Цахилгааны тоолуур": [
        "DDSF36A - 1 фаз, олон тарифт",
        "DDS26 - 1 фаз, 1 тарифт",
        "DTSD27 - 3 фаз, олон тарифт, 100А",
        "DTSD27 - 3 фаз, олон тарифт, 5А",
        "DTS27A - 3 фаз, тарифгүй 100А",
        "DTS27A - 3 фаз, тарифгүй 5A",
      ],
      "Усны тоолуур": ["Усны тоолуур"],
      "Гүйдлийн трансформатор": ["Гүйдлийн трансформатор"],
    },
  },
  {
    menu: "Ачаалал таслагч",
  },
  {
    menu: "Хуурай салгуур",
  },
  {
    menu: "Гал хамгаалагч",
  },
  {
    menu: "Тусгаарлагчид, цэнэг шавхагч",
  },
  {
    menu: "Шугамын хавчаарууд",
  },
  {
    menu: "Трансформаторууд",
  },
  {
    menu: "ЦДАШ-ын тулгуурын төмөр хийцүүд",
  },
  {
    menu: "Schneider Electric",
    submenus: {
      "Розетка утнраалга": ["Зөөлөн асаагч, давтамж хувиргуур", "Кондактор"],
      "Автомат таслуур": ["Автомат таслуур"],
    },
  },
  {
    menu: "КЭАЗ брендийн бүтээгдэхүүнүүд",
    submenus: [
      "Автомат таслуур(КЭАЗ)",
      "Кондактор(КЭАЗ)",
      "Хүчний автомат таслуур",
      "Рубильник",
    ],
  },
  {
    menu: "Шит угсралт болон үйлчилгээ",
  },
  {
    menu: "Автомат, пускатель, реле, контактор",
  },
];
