import React from "react";

function Product13() {
  return (
    <div className="flex flex-col text-sm">
      <img src="/Untitled2.jpeg" />

      <span className="font-semibold mt-3">
        {" "}
        БҮТЭЭГДЭХҮҮНИЙ БҮТЭЦ - МАТЕРИАЛ{" "}
      </span>
      <ol>
        <li>
          1. Дамжуулагч утас зэсээр буюу тугалган зэс утсаар хийгдсэн байдаг
          (анги 4);
        </li>
        <li>
          2. Тусгаарлагч резинэн,
          <span>Судлын тэмдэглэл: Өнгөр</span>
        </li>
        <li>3. Ороодос полиэтилен тигелталатын хальс</li>
        <li>
          4. Полихлипопрен дээр тулгуурласан резинэн бүрхүүл Хэрэглэх хүрээ:
        </li>
      </ol>
      <span className="">
        {" "}
        Утаснууд нь цахилгааны сүлжээнд суурин суурилуулах зориулалттай, 400
        Гц-ийн давтамжтайгаар 380 ба 660 В тоогоор, мөн радио төхөөрөмжийг
        холбох зориулалттай.
      </span>
    </div>
  );
}

export default Product13;
